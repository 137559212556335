import React, { useContext, useState } from 'react';
import { Button, Typography, Box, Paper, List, ListItem } from '@mui/material';
import { LocationContext } from '../../context/LocationProvider';
import { AuthContext } from '../../context/AuthProvider';
import { SelectAreaContext } from '../../context/SelectAreaProvider';
import { firestore } from '../../firebase';
import EnterAreaScreen from '../../screens/EnterAreaScreen';

const MatchingArea = () => {
  const {
    user: { id, accessAreas, huntingTime },
    t
  } = useContext(AuthContext);
  const { status, areas, isProMember } = useContext(LocationContext);
  const { selectedAreaId, setSelectedAreaId } = useContext(SelectAreaContext);
  const [specialZone, setSpecialZone] = useState();
  const [enterZoneModal, setEnterZoneModal] = useState(false);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const handleButton = async (item) => {
    if (item.type === 'polygon' && !isProMember && firestore.Timestamp.now().valueOf() > huntingTime.valueOf()) {
      // Handle navigation as needed in your React.js app
      // navigation.navigate('EnterArea', {specialZone: item})
      setSpecialZone(item);
      setEnterZoneModal(!enterZoneModal);
      return;
    }
    if (item.type === 'polygon' && !accessAreas.includes(item.id)) {
      // Handle Firestore update as needed in your React.js app
      await firestore().doc(`users/${id}`).update({
        accessAreas: firestore.FieldValue.arrayUnion(item.id),
      })
    }
    setSelectedAreaId(item.id);
  };

  return (
    <Box sx={{width: '100%'}}>
      { specialZone ? (
        <EnterAreaScreen 
          specialZone={specialZone}
          visible={enterZoneModal}
          changeVisible={setEnterZoneModal}
        />
      ) : null}
      <Box 
        sx={{ 
          // alignItems: 'center', 
          // padding: 2, 
          backgroundColor: 'white', 
          borderBottomWidth: 0.5, 
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'grey' }}>
          {t('Event-Title') /*'Searching Area'*/}
        </Typography>
      </Box>
      {areas && status ? (
        <Paper elevation={0} square>
          <List>
            {areas.map((item) => (
              <ListItem
                key={item.id}
                sx={{
                  flexDirection: 'row',
                  // padding: 2,
                  borderBottom: '0.5px solid grey',
                  justifyContent: 'space-between',
                  backgroundColor: item.type === 'polygon' ? '#ff9933' : 'white',
                  flex: 1
                }}
              >
                <Box>
                  <Typography variant="h5" sx={{ marginLeft: 2, color: item.type === 'polygon' ? 'white' : 'black' }}>
                    {truncateText(item?.name, 25)}
                  </Typography>
                  <Typography variant="body2" sx={{ marginLeft: 2, color: item.type === 'polygon' ? 'white' : 'black' }}>
                    {t(item?.type) /*item?.type*/}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  disabled={selectedAreaId === item.id}
                  onClick={() => {
                    handleButton(item);
                  }}
                  sx={{
                    backgroundColor: '#3364c8',
                    color: 'white'
                  }}
                >
                  {selectedAreaId === item.id ? t('JoinedButton') /*'Joined'*/ : t('JoinButton') /*'Join'*/}
                </Button>
              </ListItem>
            ))}
          </List>
        </Paper>
      ) : null}
    </Box>
  );
};

export default MatchingArea;
