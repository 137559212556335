// import { collection, doc, onSnapshot, setDoc, addDoc } from "firebase/firestore";
import { firestore } from '../firebase';
// import { RTCPeerConnection, RTCIceCandidate, RTCSessionDescription } from "react-native-webrtc";
  const configuration = {
    iceServers: [
      {
        urls: ["stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19302"],
      },
    ],
    iceCandidatePoolSize: 10,
  };
  /** @param {Firestore} db
   * Get the local peer connection and creates an offer inside the
   * room collection.  Sets up listeners for the callee candidate collection
   * @param {string} roomId
   * @param {string} callerId           // newAdd
   * @param {string} callerName         // newAdd
   * @param {Array} callerPhotoURL      // newAdd
   * @param {MediaStream} localStream
   * @param {MediaStream} remoteStream
   * @param {string} roomCollectionName
   * @param {string} calleeCandidatesCollectionName
   * @param {string} callerCandidatesCollectionName
   * @param {React.Dispatch<React.SetStateAction<undefined>>} setRemoteStream
   * @returns {Promise<RTCPeerConnection>}
   */
  export const getLocalPCAndSetOffer = async (
    roomId,
    callerId,         // newAdd
    callerName,       // newAdd
    callerPhotoURL,   // newAdd
    localStream,
    remoteStream,
    roomCollectionName,
    calleeCandidatesCollectionName,
    callerCandidatesCollectionName,
    setRemoteStream
  ) => {
    // const collectionRef = collection(db, roomCollectionName);
    // const roomRef = doc(collectionRef, roomId);
    // await setDoc(roomRef, { init: true });
    // const collectionRef = firestore().collection(roomCollectionName)
    // const roomRef = collectionRef.doc(roomId)
    const roomRef = firestore().doc(`${roomCollectionName}/${roomId}`)
    await roomRef.set({ init: true })
    const localPC = new RTCPeerConnection(configuration);
    // localPC.addStream(localStream);
    // Replace addStream
    localStream.getTracks().forEach(track => {
      localPC.addTrack(track, localStream);
    });
    
    // const callerCandidatesCollection = collection(
    //   db,
    //   `${roomCollectionName}/${roomId}/${callerCandidatesCollectionName}`
    // );
    const callerCandidatesCollection = firestore().collection(
      `${roomCollectionName}/${roomId}/${callerCandidatesCollectionName}`
    );
    localPC.onicecandidate = async (e) => {
      if (!e.candidate) {
        return;
      }
      try {
        // await addDoc(callerCandidatesCollection, e.candidate.toJSON());
        await callerCandidatesCollection.add(e.candidate.toJSON())
      } catch (error) {
        console.error("error", error);
      }
    };
    // localPC.onaddstream = (e) => {
    //   // console.log(e);
    //   if (e.stream && remoteStream !== e.stream) {
    //     setRemoteStream(e.stream);
    //   }
    // };
    
    // Replace onaddstream
    localPC.ontrack = (e) => {
      if (e.streams && e.streams[0] && remoteStream !== e.streams[0]) {
        setRemoteStream(e.streams[0]);
      }
    };

    // Replace ontrack
    // localPC.addEventListener('track', async (e) => {
    //   if (e.streams && e.streams[0] && remoteStream !== e.streams[0]) {
    //     // setRemoteStream(e.streams[0]);
    //     remoteStream.srcObject = e.streams[0]
    //   }
    //   // const [remoteStream] = event.streams;
    //   // remoteVideo.srcObject = remoteStream;
    // });
  
    
  
    const offer = await localPC.createOffer();
    await localPC.setLocalDescription(offer);
    const roomWithOffer = { offer, callerId, callerName, callerPhotoURL }; // Add callerId, callerName, callerPhotoURL in roomWithOffer
    // await setDoc(roomRef, roomWithOffer);
    // console.log(offer);
    await roomRef.set(roomWithOffer);
  
    // onSnapshot(roomRef, async (snapshot) => {
    //   const data = snapshot.data();
    //   if (!localPC.currentRemoteDescription && data.answer) {
    //     const rtcSessionDescription = new RTCSessionDescription(data.answer);
    //     try {
    //       await localPC.setRemoteDescription(rtcSessionDescription);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    // });

    roomRef.onSnapshot(async (snapshot) => {
      const data = snapshot.data();
      if (!localPC.currentRemoteDescription && data.answer) {
        try {
          const rtcSessionDescription = new RTCSessionDescription(data.answer);
          await localPC.setRemoteDescription(rtcSessionDescription);
        } catch (error) {
          console.error(error);
        }
      }
    })
  
    // const calleeCollection = collection(
    //   db,
    //   `${roomCollectionName}/${roomId}/${calleeCandidatesCollectionName}`
    // );

    const calleeCollection = firestore().collection(
      `${roomCollectionName}/${roomId}/${calleeCandidatesCollectionName}`
    );
    // onSnapshot(calleeCollection, async (snapshot) => {
    //   snapshot.docChanges().forEach(async (change) => {
    //     if (change.type === "added") {
    //       let data = change.doc.data();
    //       try {
    //         await localPC.addIceCandidate(new RTCIceCandidate(data));
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     }
    //   });
    // });

    calleeCollection.onSnapshot(async (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added") {
          let data = change.doc.data();
          try {
            await localPC.addIceCandidate(new RTCIceCandidate(data));
          } catch (error) {
            console.error(error);
          }
        }
      });
    })
    return localPC;
  };