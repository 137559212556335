import { Container, ThemeProvider, createTheme, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthProvider';

const defaultTheme = createTheme();

function TermScreen() {
  const { t } = useContext(AuthContext);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component={'main'}  maxWidth="lg">
        <Typography variant="h2" sx={{fontWeight: '700', lineHeight: 2, borderBottom: 1}}>Hunter</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h1')}</Typography>
        <Typography variant="body1">{t('Terms-b1.1')}</Typography>
        <Typography variant="body1">{t('Terms-b1.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h2.1')}</Typography>
        <Typography variant="body1">{t('Terms-b2.1.1')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h2.2')}</Typography>
        <Typography variant="body1">{t('Terms-b2.2.1')}</Typography>
        <ul>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.1a')}</strong>{` ${t('Terms-l2.2.1b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.2a')}</strong>{` ${t('Terms-l2.2.2b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.3a')}</strong>{` ${t('Terms-l2.2.3b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.4a')}</strong>{` ${t('Terms-l2.2.4b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.5a')}</strong>{` ${t('Terms-l2.2.5b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.6a')}</strong>{` ${t('Terms-l2.2.6b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.7a')}</strong>{` ${t('Terms-l2.2.7b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.8a')}</strong>{` ${t('Terms-l2.2.8b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.9a')}</strong>{` ${t('Terms-l2.2.9b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.10a')}</strong>{` ${t('Terms-l2.2.10b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.11a')}</strong>{` ${t('Terms-l2.2.11b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.12a')}</strong>{` ${t('Terms-l2.2.12b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.13a')}</strong>{` ${t('Terms-l2.2.13b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.14a')}</strong>{` ${t('Terms-l2.2.14b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.15a')}</strong>{` ${t('Terms-l2.2.15b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.16a')}</strong>{` ${t('Terms-l2.2.16b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.17a')}</strong>{` ${t('Terms-l2.2.17b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.18a')}</strong>{` ${t('Terms-l2.2.18b')}`}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Terms-l2.2.19a')}</strong>{` ${t('Terms-l2.2.19b')}`}</Typography>
        </li>
        </ul>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h3')}</Typography>
        <Typography variant="body1">{t('Terms-b3.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h4')}</Typography>
        <Typography variant="body1">{t('Terms-b4.1')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h4.1')}</Typography>
        <Typography variant="body1">{t('Terms-b4.1.1')}</Typography>
        <Typography variant="body1">{t('Terms-b4.1.2')}</Typography>
        <Typography variant="body1">{t('Terms-b4.1.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h4.2')}</Typography>
        <Typography variant="body1">{t('Terms-b4.2.1')}</Typography>
        <ul>
        <li>{t('Terms-l4.2.1')}</li>
        <li>{t('Terms-l4.2.2')}</li>
        <li>{t('Terms-l4.2.3')}</li>
        </ul>
        <Typography variant="body1">{t('Terms-b4.2.2')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h4.2.1')}</Typography>
        <Typography variant="body1">{t('Terms-b4.2.1a')}</Typography>
        <Typography variant="body1">{t('Terms-b4.2.1b')}</Typography>
        <Typography variant="body1">{t('Terms-b4.2.1c')}</Typography>
        <Typography variant="body1">{t('Terms-b4.2.1d')}</Typography>
        <Typography variant="body1">{t('Terms-b4.2.1e')}</Typography>
        <ul>
        <li>{t('Terms-l4.2.1a')}</li>
        <li>{t('Terms-l4.2.1b')}</li>
        <li>{t('Terms-l4.2.1c')}</li>
        <li>{t('Terms-l4.2.1d')}</li>
        <li>{t('Terms-l4.2.1e')}</li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h4.3')}</Typography>
        <Typography variant="body1">{t('Terms-b4.3.1')}</Typography>
        <Typography variant="body1">{t('Terms-b4.3.2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h4.4')}</Typography>
        <Typography variant="body1">{t('Terms-b4.4.1')}</Typography>
        <Typography variant="body1">{t('Terms-b4.4.2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h4.5')}</Typography>
        <Typography variant="body1">{t('Terms-b4.5.1')}</Typography>
        <Typography variant="body1">{t('Terms-b4.5.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h5')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h5.1')}</Typography>
        <Typography variant="body1">{t('Terms-b5.1.1')}</Typography>
        <Typography variant="body1">{t('Terms-b5.1.2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h5.2')}</Typography>
        <Typography variant="body1">{t('Terms-b5.2.1')}</Typography>
        <Typography variant="body1">{t('Terms-b5.2.2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h5.3')}</Typography>
        <Typography variant="body1">{t('Terms-b5.3.1')}</Typography>
        <Typography variant="body1">{t('Terms-b5.3.2')}</Typography>
        <Typography variant="body1">{t('Terms-b5.3.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h5.4')}</Typography>
        <Typography variant="body1">{t('Terms-b5.4.1')}</Typography>
        <Typography variant="body1">{t('Terms-b5.4.2')}</Typography>
        <Typography variant="body1">{t('Terms-b5.4.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h5.5')}</Typography>
        <Typography variant="body1">{t('Terms-b5.5.1')}</Typography>
        <Typography variant="body1">{t('Terms-b5.5.2')}</Typography>
        <Typography variant="body1">{t('Terms-b5.5.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h5.6')}</Typography>
        <Typography variant="body1">{t('Terms-b5.6.1')}</Typography>
        <Typography variant="body1">{t('Terms-b5.6.2')}</Typography>
        <Typography variant="body1">{t('Terms-b5.6.3')}</Typography>
        <Typography variant="body1">{t('Terms-b5.6.4')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h6')}</Typography>
        <Typography variant="body1">{t('Terms-b6.1')}</Typography>
        <Typography variant="body1">{t('Terms-b6.2')}</Typography>
        <Typography variant="body1">{t('Terms-b6.3')}</Typography>
        <Typography variant="body1">{t('Terms-b6.4')}</Typography>
        <Typography variant="body1">{t('Terms-b6.5')}</Typography>
        <Typography variant="body1">{t('Terms-b6.6')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h7')}</Typography>
        <Typography variant="body1">{t('Terms-b7.1')}</Typography>
        <Typography variant="body1">{t('Terms-b7.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h8')}</Typography>
        <Typography variant="body1">{t('Terms-b8.1')}</Typography>
        <Typography variant="body1">{t('Terms-b8.2')}</Typography>
        <Typography variant="body1">{t('Terms-b8.3')}</Typography>
        <Typography variant="body1">{t('Terms-b8.4')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h9')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h9.1')}</Typography>
        <Typography variant="body1">{t('Terms-b9.1.1')}</Typography>
        <Typography variant="body1">{t('Terms-b9.1.2')}</Typography>
        <Typography variant="body1">{t('Terms-b9.1.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h9.2')}</Typography>
        <Typography variant="body1">{t('Terms-b9.2.1')}</Typography>
        <Typography variant="body1">{t('Terms-b9.2.2')}</Typography>
        <ul>
        <li>{t('Terms-l9.2.1')}</li>
        <li>{t('Terms-l9.2.2')}</li>
        <li>{t('Terms-l9.2.3')}</li>
        <li>{t('Terms-l9.2.4')}</li>
        <li>{t('Terms-l9.2.5')}</li>
        <li>{t('Terms-l9.2.6')}</li>
        <li>{t('Terms-l9.2.7')}</li>
        <li>{t('Terms-l9.2.8')}</li>
        </ul>
        <Typography variant="body1">{t('Terms-b9.2.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h9.3')}</Typography>
        <Typography variant="body1">{t('Terms-b9.3.1')}</Typography>
        <Typography variant="body1">{t('Terms-b9.3.2')}</Typography>
        <Typography variant="body1">{t('Terms-b9.3.3')}</Typography>
        <Typography variant="body1">{t('Terms-b9.3.4')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h10')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h10.1')}</Typography>
        <Typography variant="body1">{t('Terms-b10.1.1')}</Typography>
        <Typography variant="body1">{t('Terms-b10.1.2')}</Typography>
        <Typography variant="body1">{t('Terms-b10.1.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h10.2')}</Typography>
        <Typography variant="body1">{t('Terms-b10.2.1')}</Typography>
        <ul>
        <li>{t('Terms-l10.2.1')}</li>
        <li>{t('Terms-l10.2.2')}</li>
        <li>{t('Terms-l10.2.3')}</li>
        <li>{t('Terms-l10.2.4')}</li>
        <li>{t('Terms-l10.2.5')}</li>
        <li>{t('Terms-l10.2.6')}</li>
        </ul>
        <Typography variant="body1">{t('Terms-b10.2.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h11')}</Typography>
        <Typography variant="body1">{t('Terms-b11.1')}</Typography>
        <Typography variant="body1">{t('Terms-b11.2')}</Typography>
        <Typography variant="body1">{t('Terms-b11.3')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h12')}</Typography>
        <Typography variant="body1">{t('Terms-b12.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h13')}</Typography>
        <Typography variant="body1">{t('Terms-b13.1')}</Typography>
        <Typography variant="body1">{t('Terms-b13.2')}</Typography>
        <Typography variant="body1">{t('Terms-b13.3')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h14')}</Typography>
        <Typography variant="body1">{t('Terms-b14.1')}</Typography>
        <Typography variant="body1">{t('Terms-b14.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h15')}</Typography>
        <Typography variant="body1">{t('Terms-b15.1')}</Typography>
        <Typography variant="body1">{t('Terms-b15.2')}</Typography>
        <Typography variant="body1">{t('Terms-b15.3')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h16')}</Typography>
        <Typography variant="body1">{t('Terms-b16.1')}</Typography>
        <Typography variant="body1">{t('Terms-b16.2')}</Typography>
        <Typography variant="body1">{t('Terms-b16.3')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h17')}</Typography>
        <Typography variant="body1">{t('Terms-b17.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h18')}</Typography>
        <Typography variant="body1">{t('Terms-b18.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h19')}</Typography>
        <Typography variant="body1">{t('Terms-b19.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h20')}</Typography>
        <Typography variant="body1">{t('Terms-b20.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h21')}</Typography>
        <Typography variant="body1">{t('Terms-b21.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h22')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h22.1')}</Typography>
        <Typography variant="body1">{t('Terms-b22.1.1')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h22.2')}</Typography>
        <Typography variant="body1">{t('Terms-b22.2.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h23')}</Typography>
        <Typography variant="body1">{t('Terms-b23.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h24')}</Typography>
        <Typography variant="body1">{t('Terms-b24.1')}</Typography>
        <Typography variant="body1">{t('Terms-b24.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Terms-h25')}</Typography>
        <Typography variant="body1">{t('Terms-b25.1')}</Typography>
        <ul>
        <li>{t('Terms-l25.1')}</li>
        </ul>
      </Container>
    </ThemeProvider>
  )
}

export default TermScreen