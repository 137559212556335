import React, { useContext } from 'react';
import {
  Button,
  Container,
  Typography,
  Box,
  IconButton
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AuthContext } from '../context/AuthProvider';
import { auth, firestore } from '../firebase'; // Replace with the actual Firebase library
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'


const defaultTheme = createTheme();


const AddLocationScreen = ({ navigation }) => {
  const { /*info: { id, ...data }*/ info, firstLogin, setFirstLogin, t } =
    useContext(AuthContext);
  const classes = {
    container: {
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: '90vh'
    },
    iconContainer: {
      padding: '32px', //theme.spacing(4),
      alignItems: 'center',
    },
    title: {
      fontSize: 30,
      fontWeight: 'bold',
    },
    locationButton: {
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30,
      marginTop: '16px', //theme.spacing(2),
      width: 300,
      backgroundColor: '#007bff', //theme.palette.primary.main,
    },
    laterButton: {
      backgroundColor: 'grey',
      borderColor: 'transparent',
      borderWidth: 1,
      borderRadius: 30,
      marginTop: '24px', //theme.spacing(3),
      width: 300,
    },
    buttontitle: {
      fontSize: 25,
      color: 'white',
      fontWeight: '500',
    },
    laterButtontitle: {
      fontSize: 25,
      color: 'white',
      fontWeight: '500',
    },
  }

  // const AllowLocation = () => {
  //   geolocation.getCurrentPosition(
  //     async (position) => {
  //       const { latitude, longitude } = position.coords;
  //       // Now you have access to latitude and longitude
  //       console.log('Latitude:', latitude);
  //       console.log('Longitude:', longitude);

  //       // Continue with your logic here
  //       await updateUserProfile();
  //     },
  //     (error) => {
  //       console.error('Error getting location:', error);
  //       // Handle the error here
  //     }
  //   );
  // };

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  const AllowLocation = async () => {
    const granted  = await navigator.permissions.query({ name: 'geolocation' });
    if (granted.state !== 'denied') {
      navigator.geolocation.getCurrentPosition(
        async (location) => {
          await updateUserProfile();
        },
        (error) => console.error(error),
        {
          enableHighAccuracy: true,
        }
      );
      // await updateUserProfile();
    }
  }

  const DenyLocation = async () => {
    await updateUserProfile();
  };

  const updateUserProfile = async () => {
    const { id, ...data} = info;
    await firestore()
      .collection('users')
      .doc(id)
      .set({
        ...data,
        account: "Hunter's",
        ticket: 10,
        huntingTime: firestore.FieldValue.serverTimestamp(),
        limitTime: firestore.Timestamp.fromMillis(
          firestore.Timestamp.now().toMillis() - 24 * 60 * 60 * 1000 - 1
        ),
        accessAreas: [],
        polygons: [],
        elo: 0,
        verifiedStatus: 'not-verified',
        onlySeeVerified: false,
        verifiedSeeOnly: false,
        sortInterests: false,
        sortPersonality: false,
        ageRange: [18, 35],
        timestamp: firestore.FieldValue.serverTimestamp(),
      })
      .then(() => setFirstLogin(!firstLogin))
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div 
      style={{ 
        flex: 1, 
        // padding: 20, https://source.unsplash.com/random?wallpapers
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <ThemeProvider theme={defaultTheme} >
        <Container
          maxWidth="xs"
          // style={classes.container}
          sx={{ height: '100vh', backgroundColor: 'white'}}
        >
          <IconButton sx={{ position: 'relative' }} onClick={navigateToHome}>
            <CloseIcon />
          </IconButton>
          <Box sx={classes.container}>
            <LocationOnIcon sx={{ fontSize: '300px', color: 'lightblue' }} />
            <Box sx={{ justifyContent: 'space-evenly', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4" /*className={classes.title}*/>
                {t('AddLocation-Title') /*'AddLocation-Title'*/}
              </Typography>
              <Typography variant="h6" style={{ marginTop: 10, color: 'black' }}>
                {t('AddLocation-Instruction') /*'AddLocation-Instruction'*/}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                // className={classes.locationButton}
                sx={{marginTop: 1, marginBottom: 1}}
                onClick={AllowLocation}
              >
                {t('AllowLocationButton') /*'AllowLocationButton'*/}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                // className={classes.laterButton}
                sx={{marginTop: 1, marginBottom: 1}}
                onClick={DenyLocation}
              >
                {t('MaybeLaterButton') /*'MaybeLaterButton'*/}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default AddLocationScreen;
