import React, { useContext, useCallback, useState } from 'react';
// import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, IconButton, useMediaQuery, CardMedia, Modal } from '@mui/material';
// import { useNavigate } from 'react-router-dom'; // Use React Router for navigation
import TinderCard from 'react-tinder-card';
import cryimage from '../../assets/loudly-crying-face-joypixels.gif';
import { AuthContext } from '../../context/AuthProvider';
import { SelectAreaContext } from '../../context/SelectAreaProvider';
import { firestore } from '../../firebase'; // Replace with your Firebase import
// import { Icon } from 'react-native-elements';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ReplayCircleFilledOutlinedIcon from '@mui/icons-material/ReplayCircleFilledOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SwipeInfoScreen from '../../screens/SwipeInfoScreen';
import ImageList from './ImageList';
import { getCompatibility } from '../../lib/sort';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ExtensionIcon from '@mui/icons-material/Extension';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import "./DeckSwiper.css";
import { LocationContext } from '../../context/LocationProvider';

const DeckSwiper = ({ style, name, onSwipeLeft, onSwipeRight, data, t, currentIndex, setCurrentIndex, childRefs, currentIndexRef, swipeUsers, setSwipeUsers }) => {
  const classes = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
      maxWidth: '100vw'
    },
    cardContainer: {
      flex: 1, 
      alignItems: 'center', 
      height: /*height*/ '85vh', 
      padding: 10,
      display: 'flex',
      // width: '60vh'
    },
    card: {
      position: 'relative',
      height: '100%', // You may need to adjust this value
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 10,
      borderWidth: 0.5,
      borderColor: '#d3d3d3',
      alignItems: 'center',
      width: '100%',
      zIndex: 1,
      // boxShadow: '0px 5px 6.68px rgba(0, 0, 0, 0.36)',
      background: 'linear-gradient(transparent, transparent, black)', 
    },
    // cardNameContainer: {
    //   flexDirection: 'row', 
    //   alignItems: 'center',
    //   display: 'flex'
    // },
    // cardName: {
    //   fontSize: 25, 
    //   fontWeight: 'bold', 
    //   color: 'white', 
    //   paddingLeft: 10
    // },
    noprofile: {
      position: 'relative',
      backgroundColor: 'white',
      height: '80vh', // You may need to adjust this value
      display: 'flex',
      flexDirection: 'column',
      // borderRadius: 10,
      borderWidth: 0.5,
      borderColor: '#d3d3d3',
      justifyContent: 'center',
      alignItems: 'center',
      // width: '80',
      alignItems: 'center',
      zIndex: 1
      // boxShadow: '0px 5px 6.68px rgba(0, 0, 0, 0.36)',
    },
    imageList: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
      // resize: 'cover',
      // background: 'linear-gradient(transparent, transparent, black)', 
    },
    infoContainer: {
      flexDirection: 'row', 
      // justifyContent: 'space-between', 
      alignItems: 'center',
      display: 'flex'
      // position: 'absolute',
      // bottom: 0,
      // zIndex: 2,
      // backgroundColor: 'black'
    },
    name: {
      fontSize: 20, 
      fontWeight: 'bold', 
      color: 'white', 
      paddingLeft: 10
    },
    age: {
      fontSize: 20, 
      fontWeight: 'bold', 
      color: 'white', 
      paddingLeft: 10
    },
    verifyStatus: {
      paddingLeft: 2,
    },
    introContainer: {
      flexWrap: 'wrap', 
      alignItems: 'flex-start'
    },
    introText: {
      fontSize: 15, 
      color: 'white', 
      paddingLeft: 10
    },
    cardInfoWrapper: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      // paddingHorizontal: 10, 
      // paddingVertical: 5,
      padding: 5,
      display: 'flex'
    },
    cardInfoText: {
      fontSize: 15, 
      color: 'white', 
      paddingLeft: 5
    },
    cardTagContainer: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      // paddingHorizontal: 10, 
      // paddingVertical: 5, 
      padding: 5,
      justifyContent: 'flex-start',
      display: 'flex'
    },
    cardTag: {
      flexDirection: 'row', 
      padding: 5, 
      // paddingRight: 5,
      borderRadius: 20, 
      // borderColor: 'white', 
      margin: 5, 
      alignItems: 'center',
      display: 'flex',
      border: '0.5px solid white',
    },
    cardTagIcon: {
      backgroundColor: 'transparent', 
      color: 'white'
    },
    schoolContainer: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      paddingLeft: 10, 
      padding: 2
    },
    schoolIcon: {
      backgroundColor: 'transparent', 
      color: 'white'
    },
    schoolText: {
      fontSize: 15, 
      color: 'white', 
      paddingLeft: 5
    },
    jobContainer: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      paddingLeft: 10, 
      padding: 2
    },
    jobIcon: {
      backgroundColor: 'transparent', 
      color: 'white'
    },
    jobText: {
      fontSize: 15, 
      color: 'white', 
      paddingLeft: 5
    },
    companyContainer: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      paddingLeft: 10, 
      padding: 2
    },
    companyIcon: {
      backgroundColor: 'transparent', 
      color: 'white'
    },
    companyText: {
      fontSize: 15, 
      color: 'white', 
      paddingLeft: 5
    },
    interestsContainer: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      padding: 2, 
      justifyContent: 'flex-start'
    },
    zodiacContainer: {
      flexDirection: 'row', 
      padding: 5, 
      borderRadius: 15, 
      borderWidth: 2, 
      borderColor: 'white', 
      margin: 5, 
      alignItems: 'center'
    },
    zodiacIcon: {
      backgroundColor: 'transparent', 
      color: 'white'
    },
    zodiacText: {
      fontSize: 15, 
      color: 'white', 
      paddingLeft: 5
    },
    background: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      width: '100%',
      // backgroundColor: 'transparent',
      borderRadius: 10,
      borderColor: '#d3d3d3',
      zIndex: 3,
      flexDirection: 'column',
      background: 'linear-gradient(transparent, black, black)', 
    },
    buttons: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      padding: 10,
      flex: 1,
      display: 'flex'
    },
    button: {
      width: 50,
      height: 50,
      borderRadius: 25,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white'
    },
    // Add your other styles here
  };
  // const navigate = useNavigate();
  const {
    user: { limitTime, interests, sortInterests, personality, sortPersonality },
  } = useContext(AuthContext);
  const { isProMember } = useContext(LocationContext);
  const { selectedArea, openSubscription, setOpenSubscription } = useContext(SelectAreaContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const [profiles, setProfiles] = useState([...data]);
  // const [currentIndex, setCurrentIndex] = useState(profiles.length - 1);
  const [infoVisible, setInfoVisible] = useState(false);
  // const [swipeUsers, setSwipeUsers] = useState([]);
  const [renderContent, setRenderContent] = useState(true);
  // used for outOfFrame closure
  const personalityColor = (userPersonality) => {
    // console.log(isProMember, personality, selectedArea?.type, userPersonality, sortPersonality)
    if (
      (!isProMember && selectedArea?.type !== 'polygon') ||
      !personality ||
      !userPersonality ||
      !sortPersonality
    ) {
      return 'white';
    }
    const result = getCompatibility(personality, userPersonality);
    if (result === 5) {
      return '#3399ff';
    }
    if (result === 4) {
      return '#00cc66';
    }
    if (result === 3) {
      return '#4dff4d';
    }
    if (result === 2) {
      return '#ffff66';
    }
    if (result === 1) {
      return '#ff0000';
    }
    // return 'white'
  };
  // const updateCurrentIndex = (val) => {
  //   setCurrentIndex(val)
  //   currentIndexRef.current = val
  // }

  const canGoBack = swipeUsers.length > 0 //currentIndex < data.length - 1
  // const canGoBack = profilesIndex < profiles?.length - 1

  const canSwipe = currentIndex >= 0
  // const canSwipe = profilesIndex >= 0

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    if (direction === 'left') {
      // SwipeLeft(index);
      console.log(`Swipe Left to ${nameToDelete}`)
    }
    if (direction === 'right') {
      // SwipeRight(index);
      console.log(`Swipe Right to ${nameToDelete}`)
    }
    console.log(direction, nameToDelete, index);
  }

  const outOfFrame = (card, idx) => {
    // if ( card.id ) {
      console.log(`${card.displayName} (${idx}) left the screen!`, currentIndexRef.current)
      // setSwipeUsers([...swipeUsers, card])
      setSwipeUsers(prevState => [...prevState, card])
      // setProfiles(profiles.filter(profile => profile.id !== card.id))
    // } else {
    //   console.log(`(${idx}) left the screen!`, currentIndexRef.current)
    // }
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
    // console.log(`${name} (${idx}) left the screen!`, profilesIndexRef.current)
    // profilesIndexRef.current >= idx && profilesChildRefs[idx].current.restoreCard()
  }

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < data.length) {
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
    // if (canSwipe && profilesIndex < profiles?.length) {
    //   await profilesChildRefs[profilesIndex].current.swipe(dir) // Swipe the card!
    // }
  }

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    // const newIndex = profilesIndex + 1
    const removedProfile = swipeUsers[swipeUsers.length - 1]
    setSwipeUsers(swipeUsers.filter(user => user?.id !== removedProfile?.id))
    // setProfiles([...profiles, removedProfile])
    // updateCurrentIndex(newIndex)
    // await childRefs[newIndex].current.restoreCard()
    // await profilesChildRefs[newIndex].current.restoreCard()
  }

  // const swipeRef = useRef(null);

  const infoSwipeLeft = () => {
    // navigate(-1);
    setInfoVisible(!infoVisible);
    swipe('left');
  };

  const infoSwipeRight = () => {
    // navigate(-1);
    setInfoVisible(!infoVisible);
    swipe('right');
  };

  const SwipeLeft = useCallback(
    async (cardIndex) => {
      if (
        name === 'Home' &&
        !isProMember &&
        selectedArea.type !== 'polygon' &&
        limitTime &&
        firestore.Timestamp.now().toMillis() - limitTime.toMillis() < 24 * 60 * 60 * 1000
      ) {
        // swipeRef.current.swipe('back');
        // await goBack()
        setOpenSubscription(!openSubscription)
        return;
      }
      onSwipeLeft(cardIndex);
    },
    [onSwipeLeft, isProMember, selectedArea, limitTime, name]
  );

  const SwipeRight = useCallback(
    async (cardIndex) => {
      if (
        name === 'Home' &&
        !isProMember &&
        selectedArea.type !== 'polygon' &&
        limitTime &&
        firestore.Timestamp.now().toMillis() - limitTime.toMillis() < 24 * 60 * 60 * 1000
      ) {
        // swipeRef.current.swipe('back');
        // await goBack()
        setOpenSubscription(!openSubscription)
        return;
      }
      onSwipeRight(cardIndex);
    },
    [onSwipeRight, isProMember, selectedArea, limitTime, name]
  );
  // console.log(data.length);
  // console.log(data, swipeUsers);

  const renderMobileProfile = (card) => (
    <Modal
      sx={{
        // backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      // maxWidth={'md'}
      open={infoVisible}
      onClose={() => {
        setInfoVisible(!infoVisible);
      }}
      backdropClickThrough={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <SwipeInfoScreen 
        info={card}
        visible={infoVisible}
        changeVisible={setInfoVisible}
        swipeLeft={infoSwipeLeft}
        swipeRight={infoSwipeRight}
      />
    </Modal>
  )

  return (
      <div /*className={'TinderCards__cardcontainer'}*/ style={classes.container}>
        {data/*.filter(card => !swipeUsers.includes(card.id))*/.map((card, index) => {
          // console.log(card.id, index)
          return (
            <TinderCard
              ref={childRefs[index]}
              // ref={profilesChildRefs[index]}
              key={card.id}
              className="swipe"
              onSwipe={(dir) => swiped(dir, card.displayName, index)}
              onCardLeftScreen={() => outOfFrame(card, index)}
              preventSwipe={['up', 'down']}
            >
              {card.id ? (
                <div style={{...classes.cardContainer, width: isMobile ? '90vw' : '70vh'}}>
                  { !infoVisible ? (
                  <div style={classes.card}>
                    <ImageList
                      photolist={card?.photoURL}
                      style={classes.imageList}
                      mode='swiper'
                      content={renderContent}
                      changeContent={setRenderContent}
                    />
                    <div
                      // Background Linear Gradient
                      // colors={['transparent','black', 'black']}
                      style={classes.background}
                    >
                      <div style={{...classes.infoContainer, justifyContent: 'space-between', width: '100%'}}>
                        <div style={classes.infoContainer}>
                          <p style={classes.name}>{card?.displayName}</p>
                          <p style={classes.age}>{card?.age}</p>
                          <CheckCircleIcon sx={{ fontSize: '22px', marginLeft: 2, color: 'lightgray' }} />
                        </div>
                        <div style={{ paddingRight: 20 }}>
                          <ArrowCircleUpIcon
                            style={{
                              fontSize: 40,
                              color: "white",
                            }}
                            onClick={() => {
                              // setSelectedUsers(card);
                              // setInfoMode('swipe');
                              // navigate('/swipeInfo'/*,{ info: card, swipeLeft: infoSwipeLeft, swipeRight: infoSwipeRight}*/);
                              setInfoVisible(!infoVisible);
                            }}
                          />
                        </div>
                      </div>
                      {/* <ContentList card={card} /> */}
                      { renderContent ? (
                        <>
                          { card.introduction ? 
                            <div style={{...classes.cardInfoWrapper, alignItems: 'flex-start'}}>
                              <Typography 
                                style={classes.cardInfoText}
                                >{`${card.introduction}`}</Typography>
                            </div>
                          : null }
                          { card.school ? 
                            <div style={classes.cardInfoWrapper}>
                              <SchoolIcon sx={{ fontSize: '20px', color: 'white' }} />
                              <Typography 
                                style={classes.cardInfoText}
                                >{`${card.school}`}</Typography>
                            </div>
                          : null }
                          { card.job ? 
                            <div style={classes.cardInfoWrapper}>
                              <WorkIcon sx={{ fontSize: '20px', color: 'white' }} />
                              <Typography 
                                style={classes.cardInfoText}
                                >{`${card.job}`}</Typography>
                            </div>
                          : null }
                          { card.company ? 
                            <div style={classes.cardInfoWrapper}>
                              <LocationCityIcon sx={{ fontSize: '20px', color: 'white' }} />
                              <Typography 
                                style={classes.cardInfoText}
                                >{`${card.company}`}</Typography>
                            </div>
                          : null }
                        </>
                      ) : null }
                      { !renderContent ? (
                        <div style={classes.cardTagContainer}>
                          { card.interests ? card.interests.map((interest, index) => (
                            <div key={interest} style={{
                              ...classes.cardTag,
                              borderColor: interests?.length && sortInterests && interests.includes(interest) && (isProMember || selectedArea.type === 'polygon') ? '#ff9933' : 'white',
                            }}>
                              <Typography 
                                style={{
                                  fontSize: 15, 
                                  color: interests?.length && sortInterests && interests.includes(interest) && (isProMember || selectedArea.type === 'polygon') ? '#ff9933' : 'white',
                                }}
                                >{`${t(interest) /*interest*/}`}</Typography>
                            </div>
                          )) : null }
                          { card.zodiac ? 
                            <div style={classes.cardTag}>
                              <NightsStayIcon sx={{ fontSize: '20px', color: 'white' }} />
                              <Typography 
                                style={classes.cardInfoText}
                                >{`${t(card.zodiac) /*card.zodiac*/}`}</Typography>
                            </div>
                          : null }
                          { card.personality ? 
                            <div style={{...classes.cardTag, borderColor: personalityColor(card?.personality)}}>
                              <ExtensionIcon sx={{ fontSize: '20px', color: personalityColor(card?.personality)}} />
                              <Typography 
                                style={{...classes.cardInfoText, color: personalityColor(card?.personality)}}
                                >{`${t(card.personality) /*card.personality*/}`}</Typography>
                            </div>
                          : null }
                        </div>
                      ) : null}
                      <div style={classes.buttons}>
                        <IconButton 
                          color="primary"
                          onClick={() => swipe('left')}
                        >
                          <HighlightOffOutlinedIcon sx={{ fontSize: '40px', color: 'red' }} />
                        </IconButton>
                        <IconButton 
                          color="primary"
                          onClick={() => goBack()}
                        >
                          <ReplayCircleFilledOutlinedIcon sx={{ fontSize: '40px', color: 'orange' }} />
                        </IconButton>
                        <IconButton 
                          color="primary"
                          onClick={() => swipe('right')}
                        >
                          <FavoriteIcon sx={{ fontSize: '40px', color: 'yellowgreen' }} />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                    ) : (
                      isMobile ? (renderMobileProfile(card)) : (
                        <SwipeInfoScreen 
                          info={card}
                          visible={infoVisible}
                          changeVisible={setInfoVisible}
                          swipeLeft={infoSwipeLeft}
                          swipeRight={infoSwipeRight}
                        />
                      )
                    )}
                </div>
                ) : (
                <Card sx={{...classes.noprofile, width: isMobile ? '85vw' : null}}>
                  <CardMedia
                    component="img"
                    height='82%'
                    width='82%'
                    image={cryimage}
                    alt="Crying"
                    // sx={{m: 5}}
                  />
                  <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', padding: 5, fontSize: 20 }}>
                      {t('Home-NoProfile') /*'NoProfile'*/}
                    </Typography>
                  </CardContent>
                  <div style={{...classes.buttons, width: '100%'}}>
                    <IconButton 
                      color="primary"
                      onClick={() => swipe('left')}
                    >
                      <HighlightOffOutlinedIcon sx={{ fontSize: '40px', color: 'red' }} />
                    </IconButton>
                    <IconButton 
                      color="primary"
                      onClick={() => goBack()}
                    >
                      <ReplayCircleFilledOutlinedIcon sx={{ fontSize: '40px', color: 'orange' }} />
                    </IconButton>
                    <IconButton 
                      color="primary"
                      onClick={() => swipe('right')}
                    >
                      <FavoriteIcon sx={{ fontSize: '40px', color: 'yellowgreen' }} />
                    </IconButton>
                  </div>
                </Card>
              )} 
            </TinderCard>
          )}
        )}
      </div>
  );
};

export default DeckSwiper;
