import React, { useEffect, useContext } from 'react'
// import { styled, useTheme } from '@mui/material/styles';
// import { useMediaQuery } from '@mui/material';
import LoginScreen from '../screens/LoginScreen';
import PhoneNumberScreen from '../screens/PhoneNumberScreen';
import AddEmailScreen from '../screens/AddEmailScreen';
import AddNameScreen from '../screens/AddNameScreen';
import AddBirthdayScreen from '../screens/AddBirthdayScreen';
import AddSexualOrientationScreen from '../screens/AddSexualOrientationScreen';
import AddPhotoScreen from '../screens/AddPhotoScreen';
import AddLocationScreen from '../screens/AddLocationScreen';
import VerifyEmailScreen from '../screens/VerifyEmailScreen';
import useEmailLinkEffect from '../hooks/useEmailLinkEffect';
import FrontPageScreen from '../screens/FrontPageScreen';
import { AuthContext } from '../context/AuthProvider';

function SignUpRoute() {
  // const [screen, setScreen] = useState('frontpage')
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  // const { isLogin, error } = useEmailLinkEffect();
  const { screen, setScreen, mailRef /*isLogin*/ } = useContext(AuthContext);

  useEffect(() => {
    if (/*isLogin*/ mailRef.current) {
      setScreen('verifymail')
    }
    // console.log(isLogin);
  }, [/*isLogin*/ mailRef.current])

  // Prepare to change the login and create account to be differrent by modifing the screen state

  const renderScreen = () => {
    switch (screen) {
      case 'frontpage':
        return <FrontPageScreen navigation={setScreen} />;
      case 'login':
        return <LoginScreen navigation={setScreen} />;
      case 'verifymail':
        return <VerifyEmailScreen navigation={setScreen} isLogin={/*isLogin*/ mailRef.current} />;
      case 'phone':
        return <PhoneNumberScreen navigation={setScreen} />;
      case 'email':
        return <AddEmailScreen navigation={setScreen} />;
      case 'name':
        return <AddNameScreen navigation={setScreen} />;
      case 'birthday':
        return <AddBirthdayScreen navigation={setScreen} />;
      case 'sexual':
        return <AddSexualOrientationScreen navigation={setScreen} />;
      case 'photo':
        return <AddPhotoScreen navigation={setScreen} />;
      case 'location':
        return <AddLocationScreen navigation={setScreen} />;
      default:
        return <FrontPageScreen />;
    }
  }
  return (
    renderScreen()
  )
}

export default SignUpRoute