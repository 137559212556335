import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { storage } from '../../firebase';

const DeleteImageModal = ({ visible, changeVisible, data, updateData, index, userId, t }) => {
  const deleteImage = async () => {
    const removeImage = data[index];
    const temp = [...data.filter((image) => image !== removeImage)];

    if (removeImage?.uri?.includes('firebasestorage.googleapis.com')) {
      try {
        const imagePath = getPathStorageFromUrl(removeImage.uri);
        const fileRef = storage().ref(`${userId}/${imagePath}`);
        await fileRef.delete();
      } catch (error) {
        console.error('Error deleting image:', error);
        // Handle the error as needed
      }
    }

    updateData(temp);
    index = null;
    changeVisible(!visible);
  };

  const getPathStorageFromUrl = (url) => {
    // const app = storage().app;
    // const storageBucket = app.options.storageBucket;
    const storageBucket = storage().ref().bucket
    const baseUrlWithPort = `https://firebasestorage.googleapis.com:443/v0/b/${storageBucket}/o/${userId}`;
    const baseUrlWithoutPort = `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/${userId}`;

    if (url.includes(baseUrlWithPort)) {
      let imagePath = url.replace(baseUrlWithPort, '');
      const indexOfEndPath = imagePath.indexOf('?');
      imagePath = imagePath.substring(0, indexOfEndPath);
      imagePath = imagePath.replace('%2F', '');
      return imagePath;
    }

    if (url.includes(baseUrlWithoutPort)) {
      let imagePath = url.replace(baseUrlWithoutPort, '');
      const indexOfEndPath = imagePath.indexOf('?');
      imagePath = imagePath.substring(0, indexOfEndPath);
      imagePath = imagePath.replace('%2F', '');
      return imagePath;
    }

    console.error('Unknown URL format:', url);
    return null;
  };

  const handleClose = () => {
    index = null;
    changeVisible(false);
  };

  // console.log(storage.ref().bucket);
  return (
    <Dialog open={visible} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          {t('DeletePhotoButton') /*'Delete Photo'*/}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {/* Add any content you want to show here */}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteImage} variant="contained" color="error">
          {t('DeletePhotoButton') /*'Delete Photo'*/}
        </Button>
        <Button onClick={handleClose} variant="outlined">
          {t('CancelButton') /*'Cancel'*/}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteImageModal;
