import React, { useContext } from 'react';
import { IconButton, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import DeckSwiper from '../components/HomeComponents/DeckSwiper';
import { LocationContext } from '../context/LocationProvider';
import { SelectAreaContext } from '../context/SelectAreaProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';

const BeLikedScreen = () => {
  const { user, t } = useContext(AuthContext);
  const { status, setStatus } = useContext(LocationContext);
  const { isLikedProfiles, likedIndex, setLikedIndex, likedChildRefs, likedIndexRef ,likeSwipe, setLikeSwipe } = useContext(SelectAreaContext);
  const navigate = useNavigate();

  const onSwipeLeft = async (cardIndex) => {
    if (!isLikedProfiles[cardIndex].id) return;
    const userSwiped = isLikedProfiles[cardIndex];
    console.log(`You swipe PASS on ${userSwiped.displayName}`);
    // Use your preferred state management or API calls here.
  };

  const onSwipeRight = async (cardIndex) => {
    if (!isLikedProfiles[cardIndex].id) return;
    const userSwiped = isLikedProfiles[cardIndex];
    console.log(`You swipe LIKE on ${userSwiped.displayName}`);
    // Use your preferred state management or API calls here.
  };

  const handleAllowLocation = async () => {
    try {
      // Request geolocation permission
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setStatus(!status);
            resolve(position)
          },
          (error) => reject(error)
        );
      });
    
      // If the promise resolves, the user granted permission
      console.log('Geolocation permission granted:', position);
    } catch (error) {
      // If the promise rejects, the user denied permission or an error occurred
      console.error('Error getting geolocation:', error.message);
    }
    // setStatus(!status);
    // window.location.reload();
  }

  return (
    <div style={{ backgroundColor: 'white', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Header */}
      <Container maxWidth="md">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon fontSize="large" style={{ color: '#FF5864' }} />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FavoriteIcon fontSize="large" style={{ color: '#cc66ff' }} />
            <Typography variant="subtitle2" style={{ fontWeight: '500', fontSize: '12px' }}>
              {t('Chat-IsLikedList') /*'Chat-IsLikedList'*/}
            </Typography>
          </div>
          <div style={{ width: '50px', height: '50px' }} />
        </div>
      </Container>
      {/* End of Header */}

      {/* Card */}
      {status ? (
        <DeckSwiper
          style={{ flex: 1, zIndex: -2 }}
          name={'IsLiked'}
          onSwipeLeft={onSwipeLeft}
          onSwipeRight={onSwipeRight}
          data={isLikedProfiles}
          t={t}
          currentIndex={likedIndex} 
          setCurrentIndex={setLikedIndex}
          childRefs={likedChildRefs}
          currentIndexRef={likedIndexRef}
          swipeUsers={likeSwipe}
          setSwipeUsers={setLikeSwipe}
        />
      ) : (
        <div
          style={{
            // flex: 1,
            justifyContent: 'center',
            borderColor: 'grey',
            minHeight: '80vh'
          }}
        >
          <div
            style={{
              padding: 10,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 20,
              height: '100%',
              backgroundColor: '#ccccff',
              minHeight: '80vh',
              display: 'flex',
              flexDirection: 'column'
              // flex: 1,
            }}
          >
            <Typography variant="h6">{t('Home-Instruction') /*'Please enable location to find your ideal partner.'*/}</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleAllowLocation}
            >
              {t('AllowLocationButton') /*'Enable Location'*/}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeLikedScreen;
