import React, { useState, useEffect, useRef } from 'react';
import { auth } from '../firebase'; // Assuming you have Firebase set up for web
// import { useNavigate } from 'react-router-dom';

const useEmailLinkEffect = () => {
  // const [mailLogin, setMailLogin] = useState(false);
  const [error, setError] = useState(null);
  const mailRef = useRef(false);
  // const navigate = useNavigate();

  useEffect(() => {
    const handleDynamicLink = async () => {
      // Check if the current URL contains a dynamic link
      // const urlParams = new URLSearchParams(window.location.search);
      // const link = urlParams.get('link'); // Assuming the dynamic link parameter is named 'link'
      // console.log('start handling dynamic link:', window.location.href);
      // console.log('is Sign In With Email Link:', auth().isSignInWithEmailLink(window.location.href));
      if (auth().isSignInWithEmailLink(window.location.href) /*link && */) {
        // Handle the email login link
        // console.log('continue handling dynamic link 1');
        try {
          // Use the email we saved earlier
          const email = window.localStorage.getItem('email'); // Use localStorage directly
          // if (!email) {
          //   email = window.prompt('Please provide your email');
          // }
          await auth()
            .signInWithEmailLink(email, window.location.href)
            // .then((result) => result.user.updatePhoneNumber(null))
            // .then(() => setMailLogin(!mailLogin))
            .then(() => mailRef.current = true)
            .catch((error) => console.log(error));
          console.log('Log in with email successfully');
          /* You can now navigate to your initial authenticated screen
            You can also parse the `link` and use the `continueurl` param to go to another screen
            The `continueurl` would be part of the dynamic link URL */
        } catch (e) {
          setError(e);
        }
      }
    };

    // Call the handleDynamicLink function when the component mounts
    handleDynamicLink();

    // Listen for changes in the URL (e.g., when the user clicks a dynamic link)
    window.addEventListener('popstate', handleDynamicLink);

    // When the component is unmounted, remove the event listener
    return () => {
      window.removeEventListener('popstate', handleDynamicLink);
    };
  }, []);

  return { error, mailRef };
};

export default useEmailLinkEffect;
