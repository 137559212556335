// import React, { useState } from 'react';
// import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// import Dropzone from 'react-dropzone';
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
// import { storage } from '../../firebase' // Assuming you have Firebase storage set up

// const AddImageModal = ({ visible, changeVisible, data, updateData, userId, updateProgress, i18n }) => {
//   const [loading, setLoading] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [crop, setCrop] = useState({ unit: 'px', width: 200, height: 200 });
//   const [croppedImage, setCroppedImage] = useState(null);

//   const onDrop = (acceptedFiles) => {
//     setSelectedFile(acceptedFiles[0]);
//   };

//   const handleCropChange = (newCrop) => {
//     setCrop(newCrop);
//   };

//   const handleCropComplete = (cropResult) => {
//     if (selectedFile && cropResult.width && cropResult.height) {
//       makeClientCrop(cropResult);
//     }
//   };

//   const makeClientCrop = async (cropResult) => {
//     if (selectedFile) {
//       try {
//         const croppedImageUrl = await getCroppedImg(selectedFile, cropResult);
//         setCroppedImage(croppedImageUrl);
//       } catch (error) {
//         console.error('Error cropping image:', error);
//       }
//     }
//   };

//   const getCroppedImg = (file, cropResult) => {
//     return new Promise((resolve) => {
//       const image = new Image();
//       image.src = URL.createObjectURL(file);
//       image.onload = () => {
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');

//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         const x = cropResult.x * scaleX;
//         const y = cropResult.y * scaleY;
//         const width = cropResult.width * scaleX;
//         const height = cropResult.height * scaleY;

//         canvas.width = width;
//         canvas.height = height;

//         ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

//         canvas.toBlob((blob) => {
//           resolve(URL.createObjectURL(blob));
//         }, 'image/jpeg');
//       };
//     });
//   };

//   const handleSaveImage = async () => {
//     if (croppedImage) {
//       try {
//         updateProgress(true);

//         // Upload the cropped image to Firebase Storage
//         const storageRef = storage().ref(`${userId}/${selectedFile.name}`);
//         await storageRef.put(croppedImage);

//         // Get the download URL of the uploaded image
//         const downloadURL = await storageRef.getDownloadURL();

//         // Update the data with the download URL
//         updateData([...data, { uri: downloadURL }]);
//       } catch (error) {
//         console.error('Error uploading image:', error);
//       } finally {
//         updateProgress(false);
//         changeVisible(false);
//       }
//     }
//   };

//   const handleClose = () => {
//     setSelectedFile(null);
//     setCroppedImage(null);
//     changeVisible(false);
//   };

//   return (
//     <Dialog open={visible} onClose={handleClose}>
//       <DialogTitle>{/*i18n.t('AddPhotos-Title')*/ 'AddPhotos-Title'}</DialogTitle>
//       <DialogContent>
//         {selectedFile && (
//           <div>
//             <ReactCrop
//               src={URL.createObjectURL(selectedFile)}
//               crop={crop}
//               onChange={handleCropChange}
//               onComplete={handleCropComplete}
//             />
//             {croppedImage && <img src={croppedImage} alt="Cropped" />}
//           </div>
//         )}
//         {!selectedFile && (
//           <Dropzone onDrop={onDrop} accept="image/*">
//             {({ getRootProps, getInputProps }) => (
//               <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
//                 <input {...getInputProps()} />
//                 <p>Drag 'n' drop an image here, or click to select one</p>
//               </div>
//             )}
//           </Dropzone>
//         )}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>Cancel</Button>
//         <Button onClick={handleSaveImage} variant="contained" color="primary">
//           Save
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default AddImageModal;


import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { storage } from '../../firebase' // Assuming you have Firebase storage set up
import { v4 as uuidv4 } from 'uuid';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import TakePictureModal from './TakePictureModal';

const AddImageModal = ({ visible, changeVisible, data, updateData, userId, updateProgress, t }) => {
  const [takePictureVisible, setTakePictureVisible] = useState(false);

  const pickImage = async () => {
    // Implement your image picker logic here
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        handleImagePicked(selectedFile);
        console.log(selectedFile);
      }
    };
    input.click();
  };

  // const takePhoto = async () => {
  //   try {
  //     const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
  
  //     // Create a <video> element to display the camera feed
  //     const videoElement = document.createElement('video');
  //     videoElement.srcObject = mediaStream;
  //     videoElement.play();
  
  //     // Append the <video> element to the document to display the camera feed
  //     document.body.appendChild(videoElement);
  
  //     // Wait for a moment to allow the user to position the camera as needed
  //     await new Promise(resolve => setTimeout(resolve, 1000));
  
  //     // Create a canvas element to capture a frame from the video feed
  //     const canvas = document.createElement('canvas');
  //     canvas.width = videoElement.videoWidth;
  //     canvas.height = videoElement.videoHeight;
  //     const context = canvas.getContext('2d');
  //     context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  
  //     // Convert the canvas content to a Blob (image)
  //     canvas.toBlob(async (blob) => {
  //       // Now, you have a Blob representing the captured photo
  //       handleImagePicked(blob);
  
  //       // Stop the camera feed and remove the <video> element
  //       mediaStream.getTracks().forEach(track => track.stop());
  //       videoElement.remove();
  //     }, 'image/jpeg');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  
  

  const handleImagePicked = async (pickerResult) => {
    try {
      updateProgress(true);
      changeVisible(!visible);
      if (pickerResult) {
        // Implement image manipulation and upload logic here
        const uploadUrl = await uploadImageAsync(pickerResult);
        updateData([...data, { uri: uploadUrl }]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      updateProgress(false);
    }
  };

  const uploadImageAsync = async (image) => {
    const fileRef = storage().ref(`${userId}/${uuidv4()}`)
    const result = await fileRef.put(image)
    return await fileRef.getDownloadURL();
  }

  // const uploadImageAsync = async (uri) => {
  //   // Why are we using XMLHttpRequest? See:
  //   // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  //   // chooseImage(uri);
  //   const blob = await new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.onload = () => {
  //       resolve(xhr.response);
  //     };
  //     xhr.onerror = (e) => {
  //       console.log(e);
  //       reject(new TypeError("Network request failed"));
  //     };
  //     xhr.responseType = "blob";
  //     xhr.open("GET", uri, true);
  //     xhr.send(null);
  //   });
  
  //   // Old method
  //   // const fileRef = ref(storage, `${userId}/${uuid.v4()}`);
  //   // const result = await uploadBytes(fileRef, blob); 

  //   // New React Native Firebase method
  //   const fileRef = storage().ref(`${userId}/${uuidv4()}`)
  //   await fileRef.put(blob)
  //   blob.close();
  
  //   // return await getDownloadURL(fileRef);
  //   return await fileRef.getDownloadURL();
  // } 

  return (
    <Dialog open={visible} onClose={() => {changeVisible(!visible)}}>
      <DialogTitle>{t('AddPhotos-Title') /*'AddPhotos-Title'*/}</DialogTitle>
      <DialogContent>
        <TakePictureModal 
          visible={takePictureVisible}
          changeVisible={setTakePictureVisible}
          handleImage={handleImagePicked}
        />
        <Button
          variant="contained"
          startIcon={<CameraAltIcon />}
          style={{ backgroundColor: 'rgb(255, 51, 51)', color: 'white' }}
          // onClick={takePhoto}
          onClick={() => {setTakePictureVisible(!takePictureVisible)}}
        >
          {t('TakePhotoButton') /*'Take Photo'*/}
        </Button>
        <Button
          variant="contained"
          startIcon={<AddAPhotoIcon />}
          onClick={pickImage}
        >
          {t('AddFromLibraryButton') /*'Add From Library'*/}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          onClick={() => {changeVisible(!visible)}}
        >
          {t('CancelButton') /*'Cancel'*/}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddImageModal;
