import { Container, ThemeProvider, createTheme, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthProvider';

const defaultTheme = createTheme();

function PrivacyScreen() {
  const { t } = useContext(AuthContext);
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  function separateString(inputString) {
    const segments = typeof inputString === 'string' ? inputString.split(urlRegex) : [];
    return segments
  
    // Map over the segments and generate HTML tags
    // const htmlTags = segments.map((segment, index) => {
    //   if (index % 2 === 0) {
    //     // Even index: Text segment
    //     return `<Typography variant="body1">${segment}</Typography>`;
    //   } else {
    //     // Odd index: URL segment
    //     return `<a href="${segment}" target="_blank">${segment}</a>`;
    //   }
    // });
  
    // return htmlTags; // Combine HTML tags into a single string
  }
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component={'main'}  maxWidth="lg">
        <Typography variant="h2" sx={{fontWeight: '700', lineHeight: 2, borderBottom: 1}}>Hunter</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h1')}</Typography>
        <Typography variant="body1">{t('Privacy-b1.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b1.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b1.3')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h2.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b2.1.1')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h2.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b2.2.1')}</Typography>
        <ul>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.1a')}</strong>{` ${t('Privacy-l2.2.1b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.2a')}</strong>{` ${t('Privacy-l2.2.2b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.3a')}</strong>{` ${t('Privacy-l2.2.3b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.4a')}</strong>{`${t('Privacy-l2.2.4b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.5a')}</strong>{` ${t('Privacy-l2.2.5b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.6a')}</strong>{` ${t('Privacy-l2.2.6b')}`}</Typography>
            <Typography variant="body1">{t('Privacy-l2.2.6c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.7a')}</strong>{`${t('Privacy-l2.2.7b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.8a')}</strong>{` ${t('Privacy-l2.2.8b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.9a')}</strong>{`${t('Privacy-l2.2.9b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.10a')}</strong>{` ${t('Privacy-l2.2.10b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.11a')}</strong>{` ${t('Privacy-l2.2.11b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.12a')}</strong>{` ${t('Privacy-l2.2.12b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.13a')}</strong>{` ${t('Privacy-l2.2.13b')}`}</Typography>
            <Typography variant="body1">{t('Privacy-l2.2.13c')}</Typography>
            <Typography variant="body1">{t('Privacy-l2.2.13d')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.14a')}</strong>{` ${t('Privacy-l2.2.14b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.15a')}</strong>{` ${t('Privacy-l2.2.15b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.16a')}</strong>{` ${t('Privacy-l2.2.16b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.17a')}</strong>{` ${t('Privacy-l2.2.17b')}`}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l2.2.18a')}</strong>{` ${t('Privacy-l2.2.18b')}`}</Typography>
            <Typography variant="body1">{t('Privacy-l2.2.18c')}</Typography>
          </li>
        </ul>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.1')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.1.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.1a')}</Typography>
        <ul>
          <li>
            <Typography variant="body1">{t('Privacy-l3.1.1a')}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t('Privacy-l3.1.1b')}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t('Privacy-l3.1.1c')}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t('Privacy-l3.1.1d')}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t('Privacy-l3.1.1e')}</Typography>
          </li>
        </ul>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.1.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.2a')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.2b')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.2c')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.2d')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.1.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.3a')}</Typography>
        <ul>
          <li>{t('Privacy-l3.1.3a')}</li>
          <li>{t('Privacy-l3.1.3b')}</li>
          <li>{t('Privacy-l3.1.3c')}</li>
          <li>{t('Privacy-l3.1.3d')}</li>
          <li>{t('Privacy-l3.1.3e')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b3.1.3b')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.3c')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.1.4')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.4a')}</Typography>
        <ul>
          <li>{t('Privacy-l3.1.4a')}</li>
          <li>{t('Privacy-l3.1.4b')}</li>
          <li>{t('Privacy-l3.1.4c')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b3.1.4b')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.1.4c')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.2.1')}</Typography>
        <ul>
          <li><strong>{t('Privacy-l3.2.1a')}</strong>{`${t('Privacy-l3.2.1b')}`}</li>
          <li><strong>{t('Privacy-l3.2.2a')}</strong>{` ${t('Privacy-l3.2.2b')}`}</li>
          <li><strong>{t('Privacy-l3.2.3a')}</strong>{` ${t('Privacy-l3.2.3b')}`}</li>
          <li><strong>{t('Privacy-l3.2.4a')}</strong>{` ${t('Privacy-l3.2.4b')}`}</li>
          <li><strong>{t('Privacy-l3.2.5a')}</strong>{` ${t('Privacy-l3.2.5b')}`}</li>
          <li><strong>{t('Privacy-l3.2.6a')}</strong>{` ${t('Privacy-l3.2.6b')}`}</li>
          <li><strong>{t('Privacy-l3.2.7a')}</strong>{` ${t('Privacy-l3.2.7b')}`}</li>
          <li><strong>{t('Privacy-l3.2.8a')}</strong>{` ${t('Privacy-l3.2.8b')}`}</li>
          <li><strong>{t('Privacy-l3.2.9a')}</strong>{` ${t('Privacy-l3.2.9b')}`}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b3.2.2')}</Typography>
        <ul>
          <li><strong>{t('Privacy-l3.2.10a')}</strong>{` ${t('Privacy-l3.2.10b')}`}</li>
          <li><strong>{t('Privacy-l3.2.11a')}</strong>{` ${t('Privacy-l3.2.11b')}`}</li>
          <li><strong>{t('Privacy-l3.2.12a')}</strong>{` ${t('Privacy-l3.2.12b')}`}</li>
          <li><strong>{t('Privacy-l3.2.13a')}</strong>{` ${t('Privacy-l3.2.13b')}`}</li>
          <li><strong>{t('Privacy-l3.2.14a')}</strong>{` ${t('Privacy-l3.2.14b')}`}</li>
          <li><strong>{t('Privacy-l3.2.15a')}</strong>{` ${t('Privacy-l3.2.15b')}`}</li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.3.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.3.2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.4')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.4.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.4.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.4.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.5')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.5.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.5.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.5.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.5.4')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.6')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.6.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.6.1a')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.6.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.6.2a')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.6.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.6.3a')}</Typography>
        <ul>
          <li>{t('Privacy-l3.6.3a')}</li>
          <li>{t('Privacy-l3.6.3b')}</li>
          <li>{t('Privacy-l3.6.3c')}</li>
          <li>{t('Privacy-l3.6.3d')}</li>
          <li>{t('Privacy-l3.6.3e')}</li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h3.7')}</Typography>
        <Typography variant="body1">{t('Privacy-b3.7.1')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h4')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.1')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h4.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.1.1')}</Typography>
        <ul>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l4.1.1a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l4.1.1b')}</Typography>
            <Typography variant="body1">{separateString(t('Privacy-l4.1.1c')).map((segment, index) => {
              if (index % 2 === 0) {
                // Even index: Text segment
                return <Typography variant="body1" key={index} >{segment}</Typography>;
              } else {
                // Odd index: URL segment
                return <a key={index} href={segment} target="_blank">{segment}</a>;
              }
            })}</Typography>
            <Typography variant="body1">{separateString(t('Privacy-l4.1.1d')).map((segment, index) => {
              if (index % 2 === 0) {
                // Even index: Text segment
                return <Typography variant="body1" key={index} >{segment}</Typography>;
              } else {
                // Odd index: URL segment
                return <a key={index} href={segment} target="_blank">{segment}</a>;
              }
            })}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l4.1.2a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l4.1.2b')}</Typography>
            <Typography variant="body1">{separateString(t('Privacy-l4.1.2c')).map((segment, index) => {
              if (index % 2 === 0) {
                // Even index: Text segment
                return <Typography variant="body1" key={index} >{segment}</Typography>;
              } else {
                // Odd index: URL segment
                return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
              }
            })}</Typography>
            <Typography variant="body1">{separateString(t('Privacy-l4.1.2d')).map((segment, index) => {
              if (index % 2 === 0) {
                // Even index: Text segment
                return <Typography variant="body1" key={index} >{segment}</Typography>;
              } else {
                // Odd index: URL segment
                return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
              }
            })}</Typography>
            <Typography variant="body1">{separateString(t('Privacy-l4.1.2e')).map((segment, index) => {
              if (index % 2 === 0) {
                // Even index: Text segment
                return <Typography variant="body1" key={index} >{segment}</Typography>;
              } else {
                // Odd index: URL segment
                return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
              }
            })}</Typography>
          </li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h4.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.2.1')}</Typography>
        <ul>
        <li>
        <Typography variant="body1"><strong>{t('Privacy-l4.2.1a')}</strong></Typography>
        <Typography variant="body1">{t('Privacy-l4.2.1b')}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.2.1c')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Privacy-l4.2.2a')}</strong></Typography>
        <Typography variant="body1">{t('Privacy-l4.2.2b')}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.2.2c')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.2.2d')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</Typography>
        </li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h4.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.3.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.3.2')}</Typography>
        <ul>
        <li>
        <Typography variant="body1"><strong>{t('Privacy-l4.3.1a')}</strong></Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.3.1b')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Privacy-l4.3.2a')}</strong></Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.3.2b')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</Typography>
        </li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h4.4')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.4.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.4.2')}</Typography>
        <ul>
          <li>{t('Privacy-l4.4.1')}</li>
          <li>{t('Privacy-l4.4.2')}</li>
          <li>{t('Privacy-l4.4.3')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b4.4.3')}</Typography>
        <ul>
          <li>{separateString(t('Privacy-l4.4.4')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</li>
          <li>{separateString(t('Privacy-l4.4.5')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</li>
          <li>{separateString(t('Privacy-l4.4.6')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b4.4.4')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.4.5')}</Typography>
        <Typography variant="body1">{t('Privacy-b4.4.7')}</Typography>
        <ul>
        <li>
        <Typography variant="body1"><strong>{t('Privacy-l4.4.7a')}</strong></Typography>
        <Typography variant="body1">{t('Privacy-l4.4.7b')}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.4.7c')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.4.7d')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</Typography>
        </li>
        <li>
        <Typography variant="body1"><strong>{t('Privacy-l4.4.8a')}</strong></Typography>
        <Typography variant="body1">{t('Privacy-l4.4.8b')}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.4.8c')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.4.8d')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.4.8e')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</Typography>
        <Typography variant="body1">{separateString(t('Privacy-l4.4.8f')).map((segment, index) => {
            if (index % 2 === 0) {
              // Even index: Text segment
              return <Typography variant="body1" key={index} >{segment}</Typography>;
            } else {
              // Odd index: URL segment
              return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
            }
          })}</Typography>
        </li>
        </ul>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h5')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h5.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b5.1.1')}</Typography>
        <ul>
          <li><strong>{t('Privacy-l5.1.1a')}</strong>{` ${t('Privacy-l5.1.1b')}`}</li>
          <li><strong>{t('Privacy-l5.1.2a')}</strong>{` ${t('Privacy-l5.1.2b')}`}</li>
          <li><strong>{t('Privacy-l5.1.3a')}</strong>{` ${t('Privacy-l5.1.3b')}`}</li>
          <li><strong>{t('Privacy-l5.1.4a')}</strong>{` ${t('Privacy-l5.1.4b')}`}</li>
          <li><strong>{t('Privacy-l5.1.5a')}</strong>{` ${t('Privacy-l5.1.5b')}`}</li>
          <li><strong>{t('Privacy-l5.1.6a')}</strong>{` ${t('Privacy-l5.1.6b')}`}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b5.1.2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h5.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b5.2.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b5.2.2')}</Typography>
        <ul>
          <li><strong>{t('Privacy-l5.2.1a')}</strong>{` ${t('Privacy-l5.2.1b')}`}</li>
          <li><strong>{t('Privacy-l5.2.2a')}</strong>{` ${t('Privacy-l5.2.2b')}`}</li>
          <li><strong>{t('Privacy-l5.2.3a')}</strong>{` ${t('Privacy-l5.2.3b')}`}</li>
          <li><strong>{t('Privacy-l5.2.4a')}</strong>{` ${t('Privacy-l5.2.4b')}`}</li>
          <li><strong>{t('Privacy-l5.2.5a')}</strong>{` ${t('Privacy-l5.2.5b')}`}</li>
          <li><strong>{t('Privacy-l5.2.6a')}</strong>{` ${t('Privacy-l5.2.6b')}`}</li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h5.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b5.3.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b5.3.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.1')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.1.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.1.2')}</Typography>
        <ul>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.1a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.1b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.1c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.2a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.2b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.2c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.3a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.3b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.3c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.4a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.4b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.4c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.5a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.5b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.5c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.6a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.6b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.6c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.7a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.7b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.7c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.8a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.8b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.8c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.9a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.9b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.9c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.10a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.10b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.10c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.11a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.11b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.11c')}</Typography>
          </li>
          <li>
            <Typography variant="body1"><strong>{t('Privacy-l6.1.12a')}</strong></Typography>
            <Typography variant="body1">{t('Privacy-l6.1.12b')}</Typography>
            <Typography variant="body1">{t('Privacy-l6.1.12c')}</Typography>
          </li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.1.3')}</Typography>
        <ul>
          <li>{t('Privacy-l6.1.13')}</li>
          <li>{t('Privacy-l6.1.14')}</li>
          <li>{t('Privacy-l6.1.15')}
        <ul>
          <li>{t('Privacy-l6.1.15a')}</li>
          <li>{t('Privacy-l6.1.15b')}</li>
        </ul>
        </li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.2.1')}</Typography>
        <ul>
          <li><strong>{t('Privacy-l6.2.1a')}</strong>{` ${t('Privacy-l6.2.1b')}`}</li>
          <li><strong>{t('Privacy-l6.2.2a')}</strong>{` ${t('Privacy-l6.2.2b')}`}</li>
          <li><strong>{t('Privacy-l6.2.3a')}</strong>{` ${t('Privacy-l6.2.3b')}`}</li>
          <li><strong>{t('Privacy-l6.2.4a')}</strong>{` ${t('Privacy-l6.2.4b')}`}</li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.3.1')}</Typography>
        <ul>
          <li>{t('Privacy-l6.3.1')}</li>
          <li>{t('Privacy-l6.3.2')}</li>
          <li>{t('Privacy-l6.3.3')}</li>
          <li>{t('Privacy-l6.3.4')}</li>
          <li>{t('Privacy-l6.3.5')}</li>
          <li>{t('Privacy-l6.3.6')}</li>
          <li>{t('Privacy-l6.3.7')}</li>
          <li>{t('Privacy-l6.3.8')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.3.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.3.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.4')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.4.1')}</Typography>
        <ul>
          <li>{t('Privacy-l6.4.1')}</li>
          <li>{t('Privacy-l6.4.2')}</li>
          <li>{t('Privacy-l6.4.3')}</li>
          <li>{t('Privacy-l6.4.4')}</li>
          <li>{t('Privacy-l6.4.5')}</li>
          <li>{t('Privacy-l6.4.6')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.4.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.4.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.5')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.5.1')}</Typography>
        <ul>
          <li>{t('Privacy-l6.5.1')}</li>
          <li>{t('Privacy-l6.5.2')}</li>
          <li>{t('Privacy-l6.5.3')}</li>
          <li>{t('Privacy-l6.5.4')}</li>
          <li>{t('Privacy-l6.5.5')}</li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.6')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.6.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.6.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.6.3')}</Typography>
        <ul>
          <li>{t('Privacy-l6.6.1')}</li>
          <li>{t('Privacy-l6.6.2')}</li>
          <li>{t('Privacy-l6.6.3')}</li>
          <li>{t('Privacy-l6.6.4')}</li>
          <li>{t('Privacy-l6.6.5')}</li>
          <li>{t('Privacy-l6.6.6')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.6.4')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.7')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.7.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.7.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.7.3')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.8')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.8.1')}</Typography>
        <ul>
          <li><strong>{t('Privacy-l6.8.1a')}</strong>{` ${t('Privacy-l6.8.1b')}`}</li>
          <li><strong>{t('Privacy-l6.8.2a')}</strong>{` ${t('Privacy-l6.8.2b')}`}
            <ul>
              <li>{t('Privacy-l6.8.2b.1')}</li>
              <li>{t('Privacy-l6.8.2b.2')}</li>
              <li>{t('Privacy-l6.8.2b.3')}</li>
              <li>{t('Privacy-l6.8.2b.4')}</li>
              <li>{t('Privacy-l6.8.2b.5')}</li>
              <li>{t('Privacy-l6.8.2b.6')}
                <ul>
                  <li>{t('Privacy-l6.8.2b.6a')}</li>
                  <li>{t('Privacy-l6.8.2b.6b')}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>{t('Privacy-l6.8.3a')}</strong>{` ${t('Privacy-l6.8.3b')}`}</li>
          <li><strong>{t('Privacy-l6.8.4a')}</strong>{` ${t('Privacy-l6.8.4b')}`}</li>
          <li><strong>{t('Privacy-l6.8.5a')}</strong>{` ${t('Privacy-l6.8.5b')}`}</li>
          <li><strong>{t('Privacy-l6.8.6a')}</strong>{` ${t('Privacy-l6.8.6b')}`}
            <ul>
              <li>{t('Privacy-l6.8.6b.1')}</li>
              <li>{t('Privacy-l6.8.6b.2')}</li>
              <li>{t('Privacy-l6.8.6b.3')}</li>
              <li>{t('Privacy-l6.8.6b.4')}</li>
              <li>{t('Privacy-l6.8.6b.5')}</li>
              <li>{t('Privacy-l6.8.6b.6')}</li>
              <li>{t('Privacy-l6.8.6b.7')}</li>
              <li>{t('Privacy-l6.8.6b.8')}</li>
              <li>{t('Privacy-l6.8.6b.9')}</li>
            </ul>
          </li>
          <li><strong>{t('Privacy-l6.8.7a')}</strong>{` ${t('Privacy-l6.8.7b')}`}
            <ul>
              <li>{t('Privacy-l6.8.7b.1')}</li>
              <li>{t('Privacy-l6.8.7b.2')}</li>
              <li>{t('Privacy-l6.8.7b.3')}</li>
              <li>{t('Privacy-l6.8.7b.4')}</li>
            </ul>
          </li>
        </ul>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.9')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.9.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.9.2')}</Typography>
        <ul>
          <li>{t('Privacy-l6.9.2a')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.9.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.9.4')}</Typography>
        <ul>
          <li>{t('Privacy-l6.9.4a')}</li>
          <li>{t('Privacy-l6.9.4b')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.9.5')}</Typography>
        <ul>
          <li>{t('Privacy-l6.9.5a')}</li>
          <li>{t('Privacy-l6.9.5b')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.9.6')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.9.7')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.9.8')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.10')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.3')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.4')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.5')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.10.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.1.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.1.2')}</Typography>
        <ul>
        <li>{separateString(t('Privacy-b6.10.1.2a')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</li>
        <li>{separateString(t('Privacy-b6.10.1.2b')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</li>
        <li>{separateString(t('Privacy-b6.10.1.2c')).map((segment, index) => {
          if (index % 2 === 0) {
            // Even index: Text segment
            return <Typography variant="body1" key={index} >{segment}</Typography>;
          } else {
            // Odd index: URL segment
            return <a key={index} href={segment} rel="external nofollow noopener" target="_blank">{segment}</a>;
          }
        })}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.10.1.3')}</Typography>
        <Typography variant="h5" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.10.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.10.2.1')}</Typography>
        <ul>
          <li>{t('Privacy-b6.10.2.1a')}</li>
          <li>{t('Privacy-b6.10.2.1b')}</li>
        </ul>
        <Typography variant="body1">{t('Privacy-b6.10.2.2')}</Typography>
        <Typography variant="h4" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h6.11')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.11.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b6.11.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h7')}</Typography>
        <Typography variant="body1">{t('Privacy-b7.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b7.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h8')}</Typography>
        <Typography variant="body1">{t('Privacy-b8.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b8.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h9')}</Typography>
        <Typography variant="body1">{t('Privacy-b9.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b9.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b9.3')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h10')}</Typography>
        <Typography variant="body1">{t('Privacy-b10.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b10.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h11')}</Typography>
        <Typography variant="body1">{t('Privacy-b11.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b11.2')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h12')}</Typography>
        <Typography variant="body1">{t('Privacy-b12.1')}</Typography>
        <Typography variant="body1">{t('Privacy-b12.2')}</Typography>
        <Typography variant="body1">{t('Privacy-b12.3')}</Typography>
        <Typography variant="h3" sx={{fontWeight: '700', lineHeight: 2}}>{t('Privacy-h13')}</Typography>
        <Typography variant="body1">{t('Privacy-b13.1')}</Typography>
        <ul>
          <li>{t('Privacy-l13.1.1')}</li>
        </ul>
      </Container>
    </ThemeProvider>
  )
}

export default PrivacyScreen