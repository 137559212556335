import React, { useState } from 'react';
import { Box, Typography, Button, Paper, Avatar, Container, CssBaseline } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

const GettingCall = ({ displayName, photoURL, joinCall, cancel, gettingCallStyles }) => {
  // const [callDisabled, setCallDisabled] = useState(false);

  return (
    <ThemeProvider theme={defaultTheme} >
    {/* <Container component={'main'}  maxWidth="xs"  sx={{backgroundColor: 'white', minHeight: '100vh'}}> */}
      <Container maxWidth="xs" component={Paper} sx={gettingCallStyles.container}>
        <CssBaseline />
        <Box sx={gettingCallStyles.callerContainer}>
          <Avatar 
            alt={displayName?.charAt(0).toUpperCase()} 
            rounded={true} 
            src={photoURL[0]?.uri}
            sx={{
              width: '30vh', // Set the width to 50% of the container's width
              height: '30vh',  // Set the height to 50% of the container's height
              backgroundColor: 'white'
            }}
          />
          <Typography variant="h5" sx={gettingCallStyles.callerName}>
            {displayName}
          </Typography>
        </Box>
        <Box sx={gettingCallStyles.buttonsContainer}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: /*callDisabled ? '#4a4a4a' :*/ '#008000',
            }}
            startIcon={<CallIcon />}
            size="large"
            // disabled={callDisabled}
            onClick={async () => {
              await joinCall();
              // setCallDisabled(!callDisabled);
            }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ff0000',
            }}
            startIcon={<CallEndIcon />}
            size="large"
            onClick={async() => await cancel()}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GettingCall;
