import * as React from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Header from '../components/FrontPageComponents/Header';
import MainFeaturedPost from '../components/FrontPageComponents/MainFeaturedPost';
import Footer from '../components/FrontPageComponents/Footer';
import { AuthContext } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import pricingImage from '../assets/pricing.jpg'


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function PricingScreen() {
  const { t } = React.useContext(AuthContext);
  const sections = [
    // 'home', 'products', 'pricing', 'policy'
    // 'Frontpage-TabTitle', 'Products-TabTitle', 'Pricing-TabTitle', 'Policy-TabTitle'
    { title: 'Frontpage-TabTitle', url: ''},
    { title: 'Products-TabTitle', url: 'products'},
    { title: 'Pricing-TabTitle', url: 'pricing'},
    { title: 'Policy-TabTitle', url: 'policy'},
  ];
  
  const mainFeaturedPost = {
    title: t('Pricing-TabTitle').charAt(0).toUpperCase() + t('Pricing-TabTitle').slice(1),
    // description:
    //   "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
    image: /*'https://source.unsplash.com/random?wallpapers'*/ pricingImage,
    imageText: 'main image description',
    // linkText: 'Continue reading…',
  };
  
  const tickets = [
    {
      title: t('1TicketTitle'),
      subheader: t('1TicketSubheader'),
      price: t('1TicketPrice'),
      description: [
        t('1TicketPriceDescription'),
        t('TicketDescription1'),
        t('TicketDescription2'),
        t('TicketDescription3'),
        t('TicketDescription4'),
      ],
      description2: t('1TicketPriceDescription')
      // buttonText: 'Sign up for free',
      // buttonVariant: 'outlined',
    },
    {
      title: t('5TicketsTitle'),
      subheader: t('5TicketsSubheader'),
      price: t('5TicketsPrice'),
      description: [
        t('5TicketsPriceDescription'),
        t('TicketDescription1'),
        t('TicketDescription2'),
        t('TicketDescription3'),
        t('TicketDescription4'),
      ],
      // buttonText: 'Get started',
      // buttonVariant: 'contained',
    },
    {
      title: t('10TicketsTitle'),
      subheader: t('10TicketsSubheader'),
      price: t('10TicketsPrice'),
      description: [
        t('10TicketsPriceDescription'),
        t('TicketDescription1'),
        t('TicketDescription2'),
        t('TicketDescription3'),
        t('TicketDescription4'),
      ],
      // buttonText: 'Contact us',
      // buttonVariant: 'outlined',
    },
  ];
  
  const subscription = [
    {
      title: t('1MonthTitle'),
      subheader: t('1MonthSubheader'),
      price: t('1MonthPrice'),
      description: [
        t('1MonthPriceDescription'),
        t('SubscriptionDescription1'),
        t('SubscriptionDescription2'),
        t('SubscriptionDescription3'),
        t('SubscriptionDescription4'),
        t('SubscriptionDescription5'),
        t('SubscriptionDescription6'),
      ],
      // buttonText: 'Sign up for free',
      // buttonVariant: 'outlined',
    },
    {
      title: t('6MonthsTitle'),
      subheader: t('6MonthsSubheader'),
      price: t('6MonthsPrice'),
      description: [
        t('6MonthsPriceDescription'),
        t('SubscriptionDescription1'),
        t('SubscriptionDescription2'),
        t('SubscriptionDescription3'),
        t('SubscriptionDescription4'),
        t('SubscriptionDescription5'),
        t('SubscriptionDescription6'),
      ],
      // buttonText: 'Get started',
      // buttonVariant: 'contained',
    },
    {
      title: t('12MonthsTitle'),
      subheader: t('12MonthsSubheader'),
      price: t('12MonthsPrice'),
      description: [
        t('12MonthsPriceDescription'),
        t('SubscriptionDescription1'),
        t('SubscriptionDescription2'),
        t('SubscriptionDescription3'),
        t('SubscriptionDescription4'),
        t('SubscriptionDescription5'),
        t('SubscriptionDescription6'),
      ],
      // buttonText: 'Contact us',
      // buttonVariant: 'outlined',
    },
  ];
  
  const footers = [
    {
      title: t('Frontpage-TabTitle'),
      description: [
        //'Team', 'History', 'Contact us', 'Locations'
        { name: t('Frontpage-Intro2Title'), page: '/'}, 
        { name: t('Frontpage-Intro3Title'), page: '/'},
        { name: t('Frontpage-Intro4Title'), page: '/'},
      ],
    },
    {
      title: t('Products-TabTitle'),
      description: [
        // 'Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one',
        { name: t('Products-Intro1Title'), page: '/products'}, 
        { name: t('Products-Intro2Title'), page: '/products'},
        { name: t('Products-Intro3Title'), page: '/products'},
      ],
    },
    {
      title: t('Pricing-TabTitle'),
      description: [
        // 'Resource', 'Resource name', 'Another resource', 'Final resource'
        { name: t('Pricing-Intro1Title'), page: '/pricing'}, 
        { name: t('Pricing-Intro2Title'), page: '/pricing'},
      ],
    },
    {
      title: t('Policy-TabTitle'),
      description: [
        // 'Privacy policy', 'Terms of use'
        { name: t('Policy-Intro1Title'), page: '/termandconditions'}, 
        { name: t('Policy-Intro2Title'), page: '/privacypolicy'},
      ],
    },
    {
      title: t('Language'),
      description: [
        // 'English', 'Spain'
        { name: 'English', code: 'en'}, 
        { name: 'Spainish', code: 'es'},
        { name: 'French', code: 'fr'},
        { name: 'German', code: 'de'},
        { name: 'Chinese', code: 'zh'},
        { name: 'Vietnamese', code: 'vi'},
        { name: 'Italian', code: 'it'},
        { name: 'Japanese', code: 'ja'},
        { name: 'Korean', code: 'ko'},
        // { name: 'Arabic', code: 'ar'},
        // { name: 'Bengali', code: 'bn'},
        { name: 'Czech', code: 'cs'},
        // { name: 'Greek', code: 'el'},
        { name: 'Finnish', code: 'fi'},
        // { name: 'Hindi', code: 'hi'},
        { name: 'Hungarian', code: 'hu'},
        { name: 'Dutch', code: 'nl'},
        { name: 'Norwegian', code: 'no'},
        { name: 'Polish', code: 'pl'},
        { name: 'Portuguese', code: 'pt'},
        // { name: 'Roumanian', code: 'ro'},
        // { name: 'Russian', code: 'ru'},
        { name: 'Swedish', code: 'sv'},
        // { name: 'Turkish', code: 'tr'},
        // { name: 'Urdu', code: 'ur'},
      ],
    },
    {
      title: t('Country'),
      description: ['US', 'GB', 'JP', 'KR', 'AU', 'DE', 'FR', 'ES', 'VN', 'IT', 'CA', 'CN'],
    },
  ];

  const { screen, setScreen } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogin = () => {
    setScreen('login');
    navigate('/')
  };
  const [isHovered, setIsHovered] = React.useState('');

  const handleMouseEnter = (title) => {
    setIsHovered(title);
  };

  const handleMouseLeave = () => {
    setIsHovered('');
  };

  const Divider = styled("div")(({ theme }) => ({
    width: "13%",
    height: "5px",
    backgroundColor: "#000339",
    marginTop: 10,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: "auto",
    //   marginRight: "auto",
    // },
  }));

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Header
        title="Hunter" 
        sections={sections}
        currentSection={'Pricing-TabTitle'}
        onButtonClick={handleLogin}
      />
      <MainFeaturedPost post={mainFeaturedPost} />
      {/* Instant Date Zone Ticket unit */}
      <Container 
        disableGutters 
        maxWidth="lg" 
        component="main" 
        sx={{ pt: 8, pb: 6/*, borderTop: (theme) => `1px solid ${theme.palette.divider}` */}}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{ color: "#000339", fontWeight: "700" }}
        >
          {t('Pricing-Intro1Title')}
        </Typography>
        <Divider />
        <Typography variant="h6" align="justify" color="text.secondary" component="p" sx={{padding: 5}}>
          {t('Pricing-Intro1')}
        </Typography>
      </Container>
      {/* End Instant Date Zone Ticket unit */}
      <Container maxWidth="lg" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tickets.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === '10 Tickets' ? 12 : 6}
              md={4}
            >
              <Card
                onMouseEnter={() => handleMouseEnter(tier.title)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  // Add styles based on the isHovered state
                  transform: isHovered === tier.title ? 'scale(1.1)' : 'scale(1)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200] /*'#33cc33'*/
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}
                    </Typography>
                    {/* <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography> */}
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Subscription */}

      {/* Subscription unit */}
      <Container 
        disableGutters 
        maxWidth="lg" 
        component="main" 
        sx={{ pt: 8, pb: 6, borderTop: (theme) => `1px solid ${theme.palette.divider}`}}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{ color: "#000339", fontWeight: "700" }}
        >
          {t('Pricing-Intro2Title')} 
        </Typography>
        <Divider />
        <Typography variant="h6" align="justify" color="text.secondary" component="p" sx={{padding: 5}}>
          {t('Pricing-Intro2')}
        </Typography>
      </Container>
      {/* End Subscription unit */}
      <Container maxWidth="lg" component="main" sx={{mb: 10}}>
        <Grid container spacing={5} alignItems="flex-end">
          {subscription.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === '12 months' ? 12 : 6}
              md={4}
            >
              <Card
                onMouseEnter={() => handleMouseEnter(tier.title)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  // Add styles based on the isHovered state
                  transform: isHovered === tier.title ? 'scale(1.1)' : 'scale(1)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200] /*'#cc33ff'*/
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}
                    </Typography>
                    {/* <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography> */}
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Footer */}
      <Typography
        component="footer"
        sx={{ display: 'flex', bgcolor: 'whitesmoke' }}
      >
        <Container
          maxWidth="lg"
          component="footer"
          sx={{
            // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
          }}
        >
          <Grid container spacing={5}>
            {footers.map((footer) => (
              <Footer
                title={footer.title}
                description={footer.description}
              />
            ))}
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Typography>
      {/* End footer */}
    </ThemeProvider>
  );
}