import { Box, Typography } from '@mui/material';
import React from 'react';

const ReceiverMessage = ({ message }) => {

  // Calculate the maxWidth based on the length of the message
  let maxWidth = 'auto'; // Default to 'auto'

  // If the message length is greater than 250 characters, set maxWidth to 250
  if (message.message.length > 250) {
    maxWidth = '250px';
  } else {
    // Adjust the factor as needed to control the width for shorter messages
    maxWidth = `${message.message.length * 12}px`;
  }

  const styles = {
    container: {
      flexDirection: 'row',
      display: 'flex'
    },
    receiverImage: {
      height: '30px',
      width: '30px',
      borderRadius: '15px',
    },
    messageContainer: {
      // backgroundColor: '#d3d3d3',
      // borderRadius: '8px',
      // padding: '12px 20px',
      // margin: '8px 12px',
      // alignSelf: 'flex-start',
      // maxWidth: '250px',
      backgroundColor: '#d3d3d3',
      borderRadius: 8,
      padding: '12px 20px',
      margin: '8px 12px',
      alignSelf: 'flex-start',
      maxWidth: maxWidth, // Set the maxWidth dynamically
    },
    messageText: {
      color: 'black',
    },
  };

  return (
    <Box sx={styles.container} className="receiver-message-container">
      <img
        style={styles.receiverImage}
        src={message.photoURL[0].uri}
        alt="Receiver"
        className="receiver-image"
      />
      <Box sx={styles.messageContainer} className="receiver-message-content">
        <Typography style={styles.messageText} className="receiver-message-text">
          {message.message}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReceiverMessage;
