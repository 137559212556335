import React, { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Container,
  IconButton,
  Box,
  Grid,
  Paper,
  Avatar,
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { LocationContext } from '../context/LocationProvider';
import { SelectAreaContext } from '../context/SelectAreaProvider';
import { getCompatibility } from '../lib/sort';
import ImageList from '../components/HomeComponents/ImageList';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WcIcon from '@mui/icons-material/Wc';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ExtensionIcon from '@mui/icons-material/Extension';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import getMatchedUserInfo from '../lib/getMatchedUserInfo';

const MatchInfoScreen = ({ navigation, swipeLeft, swipeRight}) => {
  // const info = route.params?.info;
  // const navigate = useNavigate();
  // const location = useLocation();
  // const { info, swipeLeft, swipeRight } = location.state;
  const {
    user: { id, interests, sortInterests, personality, sortPersonality }, t
  } = useContext(AuthContext);
  const { isProMember } = useContext(LocationContext);
  const { selectedMatch, selectedArea, selectedUsers, infoMode } = useContext(SelectAreaContext);
  // const info = selectMatch;
  const [info, setInfo] = useState();

  useEffect(() => {
    if (!selectedMatch.id) return;
    setInfo(getMatchedUserInfo(selectedMatch?.users, id));
  }, [selectedMatch])
  const handleSwipeLeft = () => {
    // route.params?.swipeLeft ? route.params?.swipeLeft() : void 0;
    swipeLeft ? swipeLeft() : void 0;
  };

  const handleSwipeRight = () => {
    // route.params?.swipeRight ? route.params?.swipeRight() : void 0;
    swipeRight ? swipeRight() : void 0;
  };

  const personalityColor = (userPersonality) => {
    if (
      (!isProMember && selectedArea?.type !== 'polygon') ||
      !personality ||
      !userPersonality ||
      !sortPersonality
    ) {
      return 'black';
    }
    const result = getCompatibility(personality, userPersonality);
    switch (result) {
      case 5:
        return '#3399ff';
      case 4:
        return '#00cc66';
      case 3:
        return '#4dff4d';
      case 2:
        return '#ffff66';
      case 1:
        return '#ff0000';
      default:
        return 'black';
    }
  };

  return (
    <Container 
      component={Paper}
      elevation={3}
      maxWidth='md' 
      style={{ height: '100vh', overflowY: 'scroll', backgroundColor: 'whitesmoke' }}
    >
      { info ? (
      <Grid container>
        <Grid item xs={12}>
          <ImageList
            photolist={info?.photoURL}
            style={{
              flex: 1,
              justifyContent: 'center',
              width: '100%',
              height: '80vh',
              display: 'flex',
            }}
            mode='swiper'
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5' component='div'>
                  {info?.displayName}
                </Typography>
                <Typography variant='h5' component='div' sx={{ ml: 1 }}>
                  {info?.age}
                </Typography>
                {info?.verifiedStatus === 'verified' && (
                  <VerifiedUserIcon sx={{ ml: 1, color: '#3399ff' }} />
                )}
              </Box>
              <IconButton
                onClick={() => navigation('message')}
                sx={{
                  display: { sm: 'flex', md: 'none' },
                  p: 2, 
                  color: '#ff751a',  
                  border: 'inset'
                }}
              >
                <ArrowUpwardIcon />
              </IconButton>
            </Box>
            {info?.gender && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <WcIcon sx={{ color: '#808080', mr: 1 }} />
                <Typography variant='body1'>
                  {/*i18n.t(info?.gender) Gender: */ `${t(info?.gender)}`}
                </Typography>
              </Box>
            )}
            {info?.job && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <WorkIcon sx={{ color: '#808080', mr: 1 }} />
                <Typography variant='body1'>{info?.job}</Typography>
              </Box>
            )}
            {/* info?.coordinate && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <LocationOnIcon sx={{ color: '#808080', mr: 1 }} />
                <Typography variant='body1'>
                  {`${(getPreciseDistance(coordinate, info?.coordinate) / 1000).toPrecision(
                    2
                  )} km`}
                </Typography>
              </Box>
            ) */}
            {info?.datePurpose && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <PersonSearchIcon sx={{ color: '#808080', mr: 1 }} />
                <Typography variant='body1'>
                  {`${t('Profile-DatingPurpose') /*'Dating Purpose'*/ }: ${t(info?.datePurpose)}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '0.5px solid #808080',
                py: 2,
              }}
            >
              <StarBorderIcon sx={{ color: '#808080', mr: 1 }} />
              <Typography variant='body1'>
                {t('Profile-AboutYou') /*'About You'*/}
              </Typography>
            </Box>
            {info?.introduction && (
              <Box sx={{ mt: 1 }}>
                <Typography variant='body2' color='textSecondary'>
                  {info?.introduction}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '0.5px solid #808080',
                py: 2,
              }}
            >
              <SchoolIcon sx={{ color: '#808080', mr: 1 }} />
              <Typography variant='body1'>
                {t('Profile-School') /*'School'*/}
              </Typography>
            </Box>
            {info?.school && (
              <Box sx={{ mt: 1 }}>
                <Typography variant='body2' color='textSecondary'>
                  {info?.school}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '0.5px solid #808080',
                py: 2,
              }}
            >
              <SportsBasketballIcon sx={{ color: '#808080', mr: 1 }} />
              <Typography variant='body1'>
                {t('Profile-Interests') /*'Interests'*/}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
              {info?.interests &&
                info?.interests.map((interest, index) => (
                  <Paper
                    key={interest}
                    sx={{
                      p: 1,
                      mr: 1,
                      mb: 1,
                      borderRadius: '15px',
                      borderWidth: '2px',
                      borderColor:
                        interests?.length &&
                        sortInterests &&
                        interests.includes(interest) &&
                        (isProMember || selectedArea.type === 'polygon')
                          ? '#ff9933'
                          : 'black',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color:
                          interests?.length &&
                          sortInterests &&
                          interests.includes(interest) &&
                          (isProMember || selectedArea.type === 'polygon')
                            ? '#ff9933'
                            : 'black',
                      }}
                    >
                      {t(`${interest}`)}
                    </Typography>
                  </Paper>
                ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '0.5px solid #808080',
                py: 2,
              }}
            >
              <NightsStayIcon sx={{ color: '#808080', mr: 1 }} />
              <Typography variant='body1'>
                {t('Profile-Zodiac') /*'Zodiac'*/}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
              {info?.zodiac && (
                <Paper
                  sx={{
                    p: 1,
                    mr: 1,
                    mb: 1,
                    borderRadius: '15px',
                    borderWidth: '2px',
                    borderColor: 'black',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography
                    variant='body2'
                    sx={{ color: 'black' }}
                  >{t(info?.zodiac)}</Typography>
                </Paper>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '0.5px solid #808080',
                py: 2,
              }}
            >
              <ExtensionIcon sx={{ color: '#808080', mr: 1 }} />
              <Typography variant='body1'>
                {t('Profile-Personality') /*'Personality'*/}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
              {info?.personality && (
                <Paper
                  sx={{
                    p: 1,
                    mr: 1,
                    mb: 1,
                    borderRadius: '15px',
                    borderWidth: '2px',
                    borderColor: personalityColor(info?.personality),
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography
                    variant='body2'
                    sx={{
                      color: personalityColor(info?.personality),
                    }}
                  >{t(info?.personality)}</Typography>
                </Paper>
              )}
            </Box>
          </Box>
        </Grid>
        {swipeLeft && swipeRight && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                borderTop: '0.5px solid #808080',
                pt: 2,
                pb: 1,
              }}
            >
              <IconButton
                sx={{ color: '#F76C6B' }}
                onClick={handleSwipeLeft}
              >
                <HighlightOffOutlinedIcon sx={{ fontSize: '60px' }} />
              </IconButton>
              <IconButton
                sx={{ color: '#4FCC94' }}
                onClick={handleSwipeRight}
              >
                <FavoriteIcon sx={{ fontSize: '60px' }} />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
      ) : (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <Avatar
            size="large"
            src={'N'}
            // onClick={() => {
            //   // navigate('/info', { info: matchedUserInfo });
            //   navigate('/info', {state: { info: matchedUserInfo }});
            // }}
          />
          <p>Select your match to view info</p>
        </div>
      )}
    </Container>
  );
};

export default MatchInfoScreen;
