import React, { useContext } from 'react';
import {
  CssBaseline,
  Typography,
  Box,
  Button,
  // Link,
  Grid,
  Avatar,
  Paper,
  IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import useAuthentication from '../hooks/useAuthentication';
import GoogleIcon from '@mui/icons-material/Google';
import PhoneIcon from '@mui/icons-material/Phone';
// New import
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'
import { auth } from '../firebase';

const defaultTheme = createTheme();

const LoginScreen = ({ navigation }) => {
  const { t, info, setInfo, loginRef } =
    useContext(AuthContext);
  const { handleGoogleSignIn, handleFacebookSignIn,} = useAuthentication(info, setInfo, navigation, loginRef);
  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ minHeight: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          // sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid 
          item 
          xs={12} 
          // sm={8} 
          md={5} 
          component={Paper} 
          elevation={6}
          square
        >
          <IconButton sx={{ position: 'relative', flexDirection: 'row-reverse' }} onClick={navigateToHome}>
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mt: 12 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('GetStartedButton')}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center" mt={2}>
              
              <Link
                style={{ textDecoration: 'none', color: 'grey' }} 
                to="/termandconditions" //"https://www.privacypolicies.com/live/261c199d-9dfd-4571-ae9c-937fa63a297e"
              >{t('Login-Agreement')}</Link>. 
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center" mt={2}>
            
              <Link
                style={{ textDecoration: 'none', color: 'grey' }} 
                to="/privacypolicy" //"https://www.privacypolicies.com/live/fb5f5fef-c879-46a2-943a-e722e59be5ab"
              >{t('Login-Policy')}</Link>
            </Typography>
            <Box /*component="form" noValidate onSubmit={handleSubmit}*/ sx={{ mt: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 10, 
                  mb: 1, 
                  // borderRadius: 20, 
                  fontSize: 17, 
                  backgroundColor: 'red',
                  '&:hover': {
                    backgroundColor: 'grey', // Set the hover background color to be the same as normal
                    // color: 'black',           // Set the hover text color to be the same as normal
                  },
                }}
                onClick={handleGoogleSignIn}
                startIcon={<GoogleIcon />}
              >
                {t('Login-GoogleSignIn')}
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ 
                  mt: 3, 
                  mb: 2, 
                  // borderRadius: 20, 
                  fontSize: 17,
                  '&:hover': {
                    backgroundColor: 'grey', // Set the hover background color to be the same as normal
                    // color: 'black',           // Set the hover text color to be the same as normal
                    // borderColor: 'black'
                  },
                }}
                startIcon={<PhoneIcon />}
                onClick={() => /*navigate('/phone_number')*/ navigation('phone')}
              >
                {t('Login-PhoneSignIn')}
              </Button>
              <Grid container justifyContent='center'>
                <Grid item xs display={'flex'} justifyContent='center'>
                  {/* <Link href="/verify_mail" variant="body2" style={{display: 'block', textAlign: 'center'}}>
                    Forgot password?
                  </Link> */}
                  <Button 
                    variant="text"
                    // onClick={() => navigate('verify_mail')}
                    // sx={{display: 'block', textAlign: 'center'}}
                    onClick={() => {
                      // setTroubleLogin(true);
                      navigation('verifymail')
                    }}
                  >
                    {t('Login-TroubleLogin')}
                  </Button>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginScreen;
