import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { 
  AppBar, Box, Container, Menu, MenuItem, IconButton, Button, Toolbar, Typography, styled
} from '@mui/material';
// import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvider';
import { GiLightningBow } from "react-icons/gi";


function Header(props) {
  const { sections, title, currentSection, onButtonClick } = props;
  const navigate = useNavigate();
  const { t } = useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#0F1B4C",
    color: "#fff",
    fontWeight: "700",
    fontSize: "14px",
    cursor: "pointer",
    padding: "0.5rem 1.25rem",
    borderRadius: "7px",
    // textTransform: "none",
    display: "block",
    border: "2px solid transparent",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#0F1B4C",
      borderColor: "#0F1B4C",
    },
    [theme.breakpoints.down("md")]: {
      margin: /*(heroBtn || getStartedBtn) &&*/ theme.spacing(0, "auto", 3, "auto"),
      width: /*(heroBtn || getStartedBtn) &&*/ "90%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: /*guideBtn &&*/ theme.spacing(3),
      width: /*guideBtn &&*/ "90%",
    },
  }));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavBarChange = (newValue) => {
    setAnchorElNav(null);
    // setSelectedTab(newValue);
    // setScreen(newValue);
    navigate(`/${newValue.url}`)
    // if (newValue === 'Frontpage-TabTitle') {
    //   setScreen('frontpage')
    //   navigate('/')
    //   return;
    // } else if (newValue === 'Products-TabTitle') {
    //   navigate('/products')
    //   return;
    // } else if (newValue === 'Pricing-TabTitle') {
    //   navigate('/pricing')
    //   return;
    // } else if (newValue === 'Policy-TabTitle') {
    //   navigate('/policy')
    //   return;
    // }
    // navigate(`/${newValue}`);
  }


  return (
    <React.Fragment>
      <AppBar position="static" color=''>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }} /> */}
            <GiLightningBow style={{ fontSize: '30px', marginRight: 10, transform: 'rotate(270deg)' }} />
            <Typography
              variant="h4"
              noWrap
              component="a"
              // href="/"
              onClick={() => navigate('/')}
              sx={{
                mr: 2,
                display: 'flex',
                flexGrow: { xs: 1, md: 0 },
                cursor: 'pointer',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {title}
            </Typography>

            <Box sx={{ display: { xs: 'flex', md: 'none' }}}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {sections.map((section) => (
                  <MenuItem 
                    sx={{color: currentSection === section.title ? 'orange' : 'grey'}}
                    key={section.title} 
                    onClick={() => handleNavBarChange(section)}
                  >
                    <Typography 
                      // fontWeight={'700'} 
                      textAlign="center" 
                      fontSize={15} 
                    >
                      {t(section.title).toUpperCase()}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              // href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {title}
            </Typography> */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {sections.map((section) => (
                <Button
                  key={section.title}
                  onClick={() => handleNavBarChange(section)}
                  sx={{ 
                    my: 2, 
                    color: currentSection === section.title ? 'orange' : 'grey', 
                    display: 'block',
                    borderRadius: 0,
                    fontSize: 15,
                    // fontWeight: '600'
                  }}
                >
                  {t(section.title)}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <CustomButton 
                variant="contained" 
                onClick={onButtonClick} 
                sx={{ 
                  display: { xs: 'none', md: 'flex'}, 
                  // fontSize: 15, 
                  // backgroundColor: 'black', 
                  // borderRadius: 20,
                  // '&:hover': {
                  //   backgroundColor: 'grey', // Change this to your desired hover color
                  // },
                }}
              >
                {t('LogInButton')}
              </CustomButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;