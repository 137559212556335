import React, { useContext } from 'react';
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  Box,
  IconButton
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { auth } from '../firebase'; // Replace with the actual Firebase library
import GoogleIcon from '@mui/icons-material/Google';
import useAuthentication from '../hooks/useAuthentication';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'


const defaultTheme = createTheme();


const AddEmailScreen = ({ navigation }) => {
  const { t, info, setInfo } = useContext(AuthContext);
  const mailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // const navigate = useNavigate();
  const classes = {
    container: {
      // padding: '16px', //theme.spacing(2),
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '85vh',
      backgroundColor: 'white'
    },
    title: {
      fontSize: 30,
      textAlign: 'justify',
    },
    input: {
      padding: '16px', //theme.spacing(2),
      borderRadius: '16px', //theme.shape.borderRadius,
      borderColor: '#6666ff',
      backgroundColor: 'white',
    },
    intro: {
      marginTop: '16px', //theme.spacing(2),
      color: 'grey',
      textAlign: 'justify',
    },
    sendemailbutton: {
      backgroundColor: 'rgba(90, 154, 230, 1)',
      borderColor: 'transparent',
      borderRadius: '16px', //theme.shape.borderRadius,
      marginTop: '16px', //theme.spacing(2),
    },
    buttontitle: {
      fontSize: 22,
      color: 'white',
    },
    buttonsContainer: {
      width: '100%',
      padding: '16px', //theme.spacing(2),
    },
  }
  const {
    handleGoogleSignIn,
    handleFacebookSignIn,
  } = useAuthentication(info, setInfo);

  const getNavigation = (data) => {
    if (!data.email) {
      // navigate('add_email');
      navigation('email');
      return;
    } else if (!data.phoneNumber) {
      // navigate('phone_number');
      navigation('phone');
      return;
    } else if (!data.displayName) {
      // navigate('add_name');
      navigation('name');
      return;
    } else if (!data.birthday) {
      // navigate('add_birthday');
      navigation('birthday');
      return;
    } else if (!data?.gender) {
      // navigate('add_sexual');
      navigation('sexual');
      return;
    } else if (!data.photoURL || data?.photoURL?.length === 0) {
      // navigate('add_photo');
      navigation('photo');
      return;
    } else {
      // navigate('add_location');
      navigation('location');
      return;
    }
  };

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  const handleButton = async () => {
    await auth().currentUser.updateEmail(info?.email);
    getNavigation(info);
  };

  return (
    <div 
      style={{ 
        flex: 1, 
        // padding: 20, https://source.unsplash.com/random?wallpapers
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <ThemeProvider theme={defaultTheme} >
        <Container
          component="main"
          maxWidth="xs"
          // sx={classes.container}
          sx={{backgroundColor: 'white', minHeight: '100vh'}}
        >
          <CssBaseline />
          <IconButton sx={{ position: 'relative', flexDirection: 'row-reverse' }} onClick={navigateToHome}>
            <CloseIcon />
          </IconButton>
          <Box sx={classes.container}>
            <Typography component="h1" variant="h4">
              {t('AddEmail-Title') /*'AddEmail-Title'*/}
            </Typography>
            <Box xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Email"
                autoFocus
                autoComplete="email"
                type="email"
                onChange={(e) =>
                  setInfo({ ...info, email: e.target.value })
                }
                placeholder="Email: yourmail@example.com"
                InputProps={{
                  style: { fontSize: 22 },
                  placeholderTextColor: '#b3b3ff',
                }}
              />
              <Typography style={classes.intro}>
                {t('AddEmail-Instruction') /*'AddEmail-Instruction'*/}
              </Typography>
            </Box>
            <Box xs={12} width={'100%'}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                // className={classes.sendemailbutton}
                onClick={handleButton}
                disabled={!mailreg.test(info?.email)}
              >
                {t('ContinueButton') /*'ContinueButton'*/}
              </Button>
              <Typography align="center" mt={2} mb={2}>
                {t('SpecialZoneIntro3') /*'SpecialZoneIntro3'*/}
              </Typography>
              <Button
                fullWidth
                startIcon={<GoogleIcon />}
                variant="contained"
                color="error"
                onClick={handleGoogleSignIn}
              >
                {t('Login-GoogleSignIn') /*'Login-GoogleSignIn'*/}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default AddEmailScreen;
