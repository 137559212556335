import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { styled, Typography, Box, Grid, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvider';
import i18next from 'i18next'
import { Us, Jp, Kr, Au, De, Fr, Es, Gb, Vn, It, Ca, Cn } from "react-flags-select";


function Footer(props) {
  const navigate = useNavigate();
  const { currentLanguage, setCurrentLanguage, t } = useContext(AuthContext);
  const { description, title } = props;
  const renderFlag = (code) => {
    switch (code) {
      case 'US':
        return <Us key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'GB':
        return <Gb key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'JP':
        return <Jp key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'KR':
        return <Kr key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'AU':
        return <Au key={code} style={{width: '40px', height: 'auto', margin: 10}} />; 
      case 'DE':
        return <De key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'FR':
        return <Fr key={code} style={{width: '40px', height: 'auto', margin: 10}} />; 
      case 'ES':
        return <Es key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'VN':
        return <Vn key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'IT':
        return <It key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'CA':
        return <Ca key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      case 'CN':
        return <Cn key={code} style={{width: '40px', height: 'auto', margin: 10}} />;
      // Add more cases as needed for other country codes
      default:
        return null;
    }
  };

  const Divider = styled("div")(() => ({
    width: "13%",
    height: "5px",
    backgroundColor: "#000339",
    marginBottom: "10px",
  }));

  return (
    <Grid item xs={6} sm={title === t('Country') ? 9 : 3} key={title}>
      <Typography 
        variant="h6" 
        color="text.primary" 
        gutterBottom 
        sx={{ fontWeight: '700', textTransform: 'uppercase', fontSize: 18}}
      > 
        {title}
      </Typography>
      <Divider />
      { title !== t('Language') && title !== t('Country') ? (
        <Box 
          component="ul" 
          sx={{
            listStyle: 'none', 
            pl: 0
          }}
        >
          {description.map((item) => (
            <Box component="li" sx={{ py: 0.5 }} key={item}>
              <Typography
                sx={{
                  cursor: 'pointer', 
                  padding: '2px', 
                  color: 'gray',
                  "&:hover": {
                    color: 'orange',
                  },
                }}
                onClick={() => navigate(item?.page)}
              >
                {item?.name}
              </Typography>
            </Box>
          ))}
        </Box>
        ) : null}
        
      { title === t('Language') ? (
        <TextField
          select
          size="medium"
          SelectProps={{
            native: true,
            onChange: (e) => {
              i18next.changeLanguage(e.target.value);
              setCurrentLanguage(e.target.value);
            },
            value: currentLanguage,
          }}
          sx={{ mt: 1, width: 150 }}
        >
          {description.map((language) => (
            <option value={language.code} key={language.code}>
              {t(language.name)}
            </option>
          ))}
        </TextField>
      ) : null}

      { title === t('Country') ? (
        <Box>
          {description.map((country) => (
            renderFlag(country)
          ))}
        </Box>
      ) : null}
    </Grid>
  );
}

Footer.propTypes = {
  description: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;