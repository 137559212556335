import React, { useMemo, useState, useEffect, useRef } from 'react'
import { filterAgeRange, filterGender, filterId, filterOnlySeeVerified, filterVerifiedSeeOnly } from '../lib/filters';
import { sortByAttractiveness, sortByInterests, sortByPersonality } from '../lib/sort';

function useHomeFilter(members, selectedUsersId, ageRange, showGender, verifiedStatus, onlySeeVerified, selectedArea, sortInterests, sortPersonality, interests, personality, isProMember, swipeUsers ) {
    const [profiles, setProfiles] = useState([]);
    const [profilesIndex, setProfilesIndex] = useState();
    // used for outOfFrame closure
    const profilesIndexRef = useRef(profilesIndex)

    useEffect(() => {
        const handleFilter = () => {
          let swiped = Array.isArray(swipeUsers) ? swipeUsers.map(user => user.id) : []
          let filterMembers;
          filterMembers = filterId(members, [...selectedUsersId, ...swiped]);
          filterMembers = filterGender(filterMembers, showGender);
          filterMembers = filterAgeRange(filterMembers, ageRange);
          if (selectedArea?.type === 'polygon' || !isProMember) {
            filterMembers = filterOnlySeeVerified(filterMembers, onlySeeVerified);
            filterMembers = filterVerifiedSeeOnly(filterMembers, verifiedStatus);
            if (sortInterests) {
              filterMembers = sortByInterests(filterMembers, interests)
            }
            if (sortPersonality) {
              filterMembers = sortByPersonality(filterMembers, personality)
            }
          }
          filterMembers = sortByAttractiveness(filterMembers);
          // console.log(filterMembers);
          if (Array.isArray(filterMembers) && filterMembers.length < 10) {
            filterMembers = [...filterMembers, ...Array(10 - filterMembers.length).fill({})]
          }
          filterMembers = filterMembers.slice(0, 10).reverse();
          // console.log(filterMembers);
          setProfiles(filterMembers);
          setProfilesIndex(filterMembers.length - 1)
          // console.log(filterMembers);
        }
    
        handleFilter();
    
      }, [
        members, ageRange, showGender, verifiedStatus, onlySeeVerified, selectedArea, sortInterests, sortPersonality, interests, personality, swipeUsers
      ])

      const profilesChildRefs = useMemo(
        () =>
          Array(profiles.length)
            .fill(0)
            .map((i) => React.createRef()),
        [profiles]
      )
  return { profiles, profilesIndex, setProfilesIndex, profilesChildRefs, profilesIndexRef }
}

export default useHomeFilter