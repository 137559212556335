import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function MainFeaturedPost(props) {
  const { post, titleStyle, descriptionStyle, onContainedButtonClick, onOutlinedButtonClick } = props;

  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${post.image})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.3)',
        }}
      />
      <Grid 
        container 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Grid item md={6}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 12 ,sm: 15 },
              pr: { md: 0 },

              // alignItems: 'center'
            }}
          >
            {post.title ? (
              <Typography component="h1" variant="h3" color="inherit" gutterBottom sx={{...titleStyle}}>
                {post.title}
              </Typography>
            ) : null}
            {post.description ? (
              <Typography variant="h5" color="inherit" paragraph sx={{...descriptionStyle}}>
                {post.description}
              </Typography>
            ) : null}
            {post.linkText ? (
              <Link variant="subtitle1" href="#">
                {post.linkText}
              </Link>
            ) : null}
            { post.containedButton ? (
              <Button 
                variant="contained" 
                sx={{ 
                  display: { xs: 'flex', md: 'flex'}, 
                  width: { xs: '100%', md: '40%'}, 
                  my: 2,
                  backgroundColor: "#0F1B4C",
                  color: "#fff",
                  fontWeight: "700",
                  fontSize: "14px",
                  cursor: "pointer",
                  padding: "0.5rem 1.25rem",
                  borderRadius: "7px",
                  // textTransform: "none",
                  // display: "block",
                  border: "2px solid transparent",
                  // "&:hover": {
                  //   backgroundColor: "#fff",
                  //   color: "#0F1B4C",
                  //   borderColor: "#0F1B4C",
                  // },
                }}
                onClick={onContainedButtonClick}
              >
                {post.containedButton}
              </Button>
            ) : null}
            { post.outlinedButton ? (
              <Button 
                variant="outlined" 
                sx={{ 
                  display: { xs: 'flex', md: 'none'}, 
                  width: '100%', 
                  my: 2,
                  color: "#FFF",
                  borderColor: "#FFF",
                  "&:hover": {
                    color: "#2196f3",
                    borderColor: "#2196f3",
                  },
                }}
                onClick={onOutlinedButtonClick}
              >
                {post.outlinedButton}
              </Button>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;