import React, { useState } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import { useTheme } from '@mui/material/styles';

const ToggleButtons = ({
  remoteStream,
  localStream,
  cancel,
  toggleButtonStyles,
}) => {
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoDisabled, setIsVideoDisabled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const switchCamera = () => {
    if (!isMobile) {
      alert("Can not find back camera")
      return;
    }
    localStream.getVideoTracks().forEach((track) => track._switchCamera());
  };

  const toggleMute = () => {
    if (!remoteStream) {
      return;
    }
    localStream.getAudioTracks().forEach((track) => {
      track.enabled = !track.enabled;
      setIsMuted(!track.enabled);
    });
  };

  const toggleVideo = () => {
    if (!remoteStream) {
      return;
    }
    localStream.getVideoTracks().forEach((track) => {
      track.enabled = !track.enabled;
      setIsVideoDisabled(!track.enabled);
    });
  };

  return (
    <Box sx={toggleButtonStyles.container}>
      <>
        <IconButton
          style={toggleButtonStyles.buttons}
          title="Switch camera"
          onClick={switchCamera}
          variant="contained"
        >
          <SwitchCameraIcon /*fontSize="large"*/ />
        </IconButton>
        <IconButton
          style={toggleButtonStyles.buttons}
          onClick={toggleMute}
          disabled={!remoteStream}
          variant="contained"
        >
          {isMuted ? (
            <MicIcon /*fontSize="large"*/ />
          ) : (
            <MicOffIcon /*fontSize="large"*/ />
          )}
        </IconButton>
        <IconButton
          style={toggleButtonStyles.buttons}
          onClick={toggleVideo}
          disabled={!remoteStream}
          variant="contained"
        >
          {isVideoDisabled ? (
            <CameraAltOutlinedIcon /*fontSize="large"*/ />
          ) : (
            <NoPhotographyOutlinedIcon /*fontSize="large"*/ />
          )}
        </IconButton>
        <IconButton
          onClick={() => {
            cancel();
          }}
          style={
            {
              ...toggleButtonStyles.buttons,
              backgroundColor: 'red',
            }
          }
          variant="contained"
        >
          <CallEndIcon /*fontSize="large"*/ />
        </IconButton>
      </>
    </Box>
  );
};

export default ToggleButtons;
