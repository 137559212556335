import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Badge } from '@mui/material'; // Replace with appropriate Material-UI components
import { firestore } from '../../firebase';
import { AuthContext } from '../../context/AuthProvider';
import getMatchedUserInfo from '../../lib/getMatchedUserInfo';
import { SelectAreaContext } from '../../context/SelectAreaProvider';
// import { useNavigate } from 'react-router-dom';

const ChatRow = ({ matchDetails, barStyle, navigation }) => {
  const { user } = useContext(AuthContext);
  const { setSelectedMatchId } = useContext(SelectAreaContext);
  // const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [matchedUserInfo, setMatchedUserInfo] = useState(null);
  const [lastmessage, setLastMessages] = useState('');
  // const navigate = useNavigate();

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  // const truncateText = (text, maxLength) => {
  //   if (text.length > maxLength) {
  //     return '...' + text.slice(text.length - maxLength);
  //   }
  //   return text;
  // };

  useEffect(() => {
    setMatchedUserInfo(getMatchedUserInfo(matchDetails.users, user.id));
  }, [matchDetails, user]);

  useEffect(() => {
    const unsubscribe = firestore()
      .collection(`matches/${matchDetails.id}/messages`)
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        setLastMessages(snapshot.docs[0]?.data()?.message);
      });

    return () => unsubscribe();
  }, [matchDetails]);

  return (
    <div>
      {barStyle ? (
        <div
          style={{
            ...styles.chatbox,
            borderBottomWidth: 0.5 ,
          }}
          onClick={() => {
            setSelectedMatchId(matchDetails?.id);
            navigation('message');
            // if (isMobile) {
            //   navigate('/message', {state: { matchDetails }});
            // }
            // setSelectMatch(matchDetails)
          }}
        >
          <div>
            <Avatar 
              // sizes={80}
              sx={{width: 40, height: 40}} 
              rounded 
              src={matchedUserInfo?.photoURL[0]?.uri} 
            />
            {matchedUserInfo?.isOnline ? (
              <Badge
                status="success"
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  zIndex: 1,
                }}
                badgeContent={<span style={{ width: 14, height: 14, borderRadius: 7 }} />}
              />
            ) : null}
          </div>
          <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '17px', fontWeight: 'bold' }}>{matchedUserInfo?.displayName}</span>
            <span
              style={{
                paddingTop: 10,
                color: '#808080',
                marginRight: 10,
                flexWrap: 'wrap',
                flexShrink: 1,
              }}
            >
              {truncateText(lastmessage, 25) || 'Say Hi!'}
            </span>
          </div>
        </div>
      ) : (
        <div style={styles.chatAvatar}>
          <div style={{ borderRadius: 40, borderWidth: 0.5}}>
            <Avatar
              // sizes={80}
              sx={{width: 50, height: 50}} 
              rounded={true}
              src={matchedUserInfo?.photoURL[0].uri }
              onClick={() => {
                setSelectedMatchId(matchDetails.id);
                navigation('message')
                // navigate('/message', {state: { matchDetails }});
              }}
            />
            {matchedUserInfo?.isOnline ? (
              <Badge
                status="success"
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                }}
                badgeContent={<span style={{ width: 14, height: 14, borderRadius: 7 }} />}
              />
            ) : null}
          </div>
          <span style={{ paddingTop: 5, fontSize: 12, fontWeight: '500' }}>{matchedUserInfo?.displayName}</span>
        </div>
      )}
    </div>
  );
};

export default ChatRow;

const styles = {
  chatbox: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '10px',
    margin: '5px',
    borderBottomWidth: '0.5px',
    borderColor: 'black',
  },
  chatAvatar: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    flexDirection: 'column',
    width: 100
    // backgroundColor: 'white'
  },
};
