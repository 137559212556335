import React, { useContext } from 'react';
import {
  Button,
  Container,
  Box,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
// import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'
import { auth } from '../firebase';


const defaultTheme = createTheme();


const AddNameScreen = ({ navigation }) => {
  const { t, info, setInfo } = useContext(AuthContext);
  // const navigate = useNavigate();
  const classes = {
    container: {
      // padding: '16px', //theme.spacing(2),
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '90vh',
      backgroundColor: 'white'
    },
    title: {
      fontSize: 32,
      textAlign: 'justify',
    },
    input: {
      padding: 15,
      borderWidth: 0.5,
      borderRadius: 10,
      borderColor: '#6666ff',
      backgroundColor: 'white',
    },
    sendnamebutton: {
      backgroundColor: 'rgba(90, 154, 230, 1)',
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30,
      marginTop: 10,
      width: '100%',
    },
    buttontitle: {
      fontSize: 22,
      color: 'white',
    },
  }

  const getNavigation = (data) => {
    if (!data.email) {
      // navigate('add_email');
      navigation('email');
      return;
    } else if (!data.phoneNumber) {
      // navigate('phone_number');
      navigation('phone');
      return;
    } else if (!data.displayName) {
      // navigate('add_name');
      navigation('name');
      return;
    } else if (!data.birthday) {
      // navigate('add_birthday');
      navigation('birthday');
      return;
    } else if (!data?.gender) {
      // navigate('add_sexual');
      navigation('sexual');
      return;
    } else if (!data.photoURL || data?.photoURL?.length === 0) {
      // navigate('add_photo');
      navigation('photo');
      return;
    } else {
      // navigate('add_location');
      navigation('location');
      return;
    }
  };

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  return (
    <div 
      style={{ 
        flex: 1, 
        // padding: 20, https://source.unsplash.com/random?wallpapers
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <ThemeProvider theme={defaultTheme} >
        <Container
          maxWidth="xs"
          // style={classes.container}
          sx={{height: '100vh', backgroundColor: 'white'}}
        >
          <IconButton sx={{ position: 'relative', flexDirection: 'row-reverse' }} onClick={navigateToHome}>
            <CloseIcon />
          </IconButton>
          <Box sx={classes.container}>
            <Typography component="h1" variant="h4" sx={classes.title}>
              {t('AddName-Title') /*'AddName-Title'*/}
            </Typography>
            <Box>
              <TextField
                placeholder={t('AddName-Placeholder') /*'AddName-Placeholder'*/}
                autoFocus
                variant="outlined"
                label={t('AddName-Placeholder') /*'AddName-Placeholder'*/}
                // autoComplete="email"
                fullWidth
                inputProps={{ 
                  maxLength: 20,
                  style: { fontSize: 22 },
                  placeholderTextColor: '#b3b3ff',
                }}
                onChange={(event) => {
                  setInfo({ ...info, displayName: event.target.value });
                }}
                placeholderTextColor={'#b3b3ff'}
                type="text"
              />
              <Typography
                variant="body1"
                mt={2}
                style={{ color: 'grey'}}
              >
                {t('AddName-Instruction') /*'AddName-Instruction'*/}
              </Typography>
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              // className={classes.sendnamebutton}
              onClick={() => {
                getNavigation(info);
              }}
              disabled={!info?.displayName}
            >
              {t('ContinueButton') /*'ContinueButton'*/}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default AddNameScreen;
