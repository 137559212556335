import React, { useState, useEffect } from 'react'
import { firestore } from '../firebase';
// import { collection, limit, onSnapshot, query, where } from 'firebase/firestore';

const useCollection = (collection, condition) => {
    const [documents, setDocuments] = useState([]);
    
    useEffect(() => {
      // let collectionRef = collection(db, collections);
      // let queryRef = query(collectionRef);
      let collectionRef = firestore().collection(collection)/*.orderBy('Timestamp')*/
      if (condition) {
        if (!condition.compareValue || !condition.compareValue.length || !condition.limit || !condition.fieldName || !condition.fieldName.length) {
          // reset documents data
          setDocuments([]);
          return;
        }

        // queryRef = query(collectionRef, where(
        //   condition.fieldName,
        //   condition.operator,
        //   condition.compareValue
        // ), limit(condition.limit));

        collectionRef = collectionRef.where(
          condition?.fieldName,
          condition?.operator,
          condition?.compareValue
        ).limit(condition?.limit);
      }

      // const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      //   const documents = snapshot.docs.map((doc) => ({
      //     ...doc.data(),
      //     id: doc.id,
      //   }));
  
      //   setDocuments(documents);
      // }, (error) => {
      //   console.error(error);
      // });

      

      const unsubscribe = collectionRef.onSnapshot((snapshot) => {
        const documents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log(documents)
  
        setDocuments(documents);
      }, (error) => {
        console.error(error);
      });
  
      return unsubscribe;
    }, [collection, condition]);
  
    return documents;
}

export default useCollection