import React, { useContext } from 'react';
import { Button, Typography, Container, Paper, Modal } from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { SelectAreaContext } from '../context/SelectAreaProvider';
import { firestore } from '../firebase'; // You need to replace this with the appropriate Firebase library for React.js

const TimeExtendScreen = ({ visible, changeVisible }) => {
  const {
    user: { id, ticket, huntingTime },
    t,
  } = useContext(AuthContext);
  const { seenMessage, setSeenMessage, openSubscription, setOpenSubscription, openTicket, setOpenTicket } = useContext(SelectAreaContext);
  // const navigate = useNavigate();

  const handleUseTicket = async () => {
    if (firestore.Timestamp.now().valueOf() > huntingTime.valueOf()) {
      await firestore().doc(`users/${id}`).update({
        ticket: ticket - 1,
        huntingTime: firestore.Timestamp.fromMillis(firestore.Timestamp.now().toMillis() + 24 * 60 * 60 * 1000),
      });
    } else {
      await firestore().doc(`users/${id}`).update({
        ticket: ticket - 1,
        huntingTime: firestore.Timestamp.fromMillis(huntingTime.toMillis() + 24 * 60 * 60 * 1000),
      });
    }
    setSeenMessage(false);
    changeVisible(!visible);
  };

  return (
    <Modal
      sx={{
        backgroundColor: 'rgba(0.5, 0.25, 0, 0.5)', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      // maxWidth={'md'}
      open={visible}
      onClose={() => {
        changeVisible(!visible);
      }}
      backdropClickThrough={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: '20px', background: 'rgba(0, 0, 0, 0.5)' }}>
          <Paper
            elevation={3}
            style={{ width: '90%', display: 'flex', borderRadius: '20px', background: 'white', padding: '10px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4" style={{ fontWeight: 'bold', padding: '10px' }}>
                {t('TimeExtendTitle')}
              </Typography>
              <i className="fa fa-clock-o" style={{ fontSize: '35px', color: '#ff0000' }}></i>
              <Typography variant="body1" style={{ fontSize: '15px', padding: '8px', textAlign: 'justify', lineHeight: '20px' }}>
                {t('TimeExtendIntro')}
              </Typography>
              <div style={{ width: '250px' }}>
                <div>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#33cc33', borderRadius: '30px', marginTop: '10px', width: '100%' }}
                    onClick={() => {
                      // switchContent(true);
                      setSeenMessage(!seenMessage);
                      changeVisible(!visible);
                      setOpenTicket(!openTicket);
                    }}
                  >
                    {t('GetTicketButton')}
                  </Button>
                  <Typography variant="body2" style={{ textAlign: 'center' }}>
                    {t('SpecialZoneIntro3')}
                  </Typography>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#cc33ff', borderRadius: '30px', marginTop: '10px', width: '100%' }}
                    onClick={() => {
                      // switchContent(true);
                      setSeenMessage(!seenMessage);
                      changeVisible(!visible);
                      setOpenSubscription(!openSubscription);
                    }}
                  >
                    {t('GetSubsciptionButton')}
                  </Button>
                  <Typography variant="body2" style={{ textAlign: 'center', padding: '5px' }}>
                    {t('SpecialZoneIntro4')}
                  </Typography>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#33cc33', borderRadius: '30px', marginTop: '10px', width: '100%' }}
                    onClick={handleUseTicket}
                    disabled={ticket === 0}
                  >
                    {`${t('UseTicketButton')} (${ticket})`}
                  </Button>
                  <Typography variant="body2" style={{ textAlign: 'center' }}>
                    {t('SpecialZoneIntro3')}
                  </Typography>
                  <Button
                    variant="outlined"
                    style={{ backgroundColor: 'white', borderColor: 'transparent', borderWidth: '1px', borderRadius: '30px', marginTop: '10px', width: '100%' }}
                    onClick={() => {
                      setSeenMessage(!seenMessage);
                      changeVisible(!visible);
                    }}
                  >
                    {t('MaybeLaterButton')}
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </Paper>
      </Container>
    </Modal>
  );
};

export default TimeExtendScreen;
