import React, { useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../context/AuthProvider';
import { auth, firestore, database } from '../../firebase';

const DeleteAccountModal = ({ visible, changeVisible, t }) => {
  const { user: { id, ...data }, snapShotUnsubscribeRef, setScreen } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const [textInputVisible, setTextInputVisible] = useState(false);
  const [content, changeContent] = useState(0);

  const behaviors = [
    {
      title: t('DeleteAccount-A1'),
      iconName: 'coffee',
      iconType: 'font-awesome',
      iconColor: '#3399ff',
    },
    {
      title: t('DeleteAccount-A2'),
      iconName: 'shower-head',
      iconType: 'material-community',
      iconColor: '#cc66ff',
    },
    {
      title: t('DeleteAccount-A3'),
      iconName: 'heart',
      iconType: 'ionicon',
      iconColor: '#ff6600',
    },
    {
      title: t('DeleteAccount-A4'),
      iconName: 'wrench',
      iconType: 'font-awesome',
      iconColor: '#ffcc00',
    },
    {
      title: t('DeleteAccount-A5'),
      iconName: 'dislike',
      iconType: 'fontisto',
      iconColor: '#ff0066',
    },
    {
      title: t('Other'),
      iconName: 'pen',
      iconType: 'font-awesome-5',
      iconColor: '#33cc33',
    },
  ];

  const deleteAccount = async () => {
    try {
      await database().ref(`/status/${id}`).remove();
      await firestore().collection('reports').doc(id).set({
        ...data,
        id,
        message: message,
        type: 'Deleted Account',
      });
      snapShotUnsubscribeRef.current?.();
      setScreen('frontpage')
      await auth().signOut();
      console.log('User Deleted');
      changeContent(0);
      setTextInputVisible(false);
      changeVisible(!visible);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={visible} onClose={() => changeVisible(!visible)}>
      {content === 0 ? (
        <>
          <DialogTitle>
            <Typography variant="h5" fontWeight="bold">
              {t('DeleteAccount-Title') /*'DeleteAccount-Title'*/}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">{t('DeleteAccount-Intro1') /*'DeleteAccount-Intro1'*/}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeContent(content + 1)}
            >
              {t('YesButton') /*'Yes'*/}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                changeContent(0);
                changeVisible(!visible);
              }}
            >
              {t('NoButton') /*i18n.'No'*/}
            </Button>
          </DialogActions>
        </>
      ) : null}

      {content === 1 ? (
        <>
          <DialogTitle>
            <Typography variant="h5" fontWeight="bold">
              {t('DeleteAccount-Title') /*'DeleteAccount-Title'*/}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">{t('DeleteAccount-Intro2') /*'DeleteAccount-Intro2'*/}</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {behaviors.map((behavior) => (
                <IconButton
                  key={behavior.title}
                  style={{
                    borderColor: message && message?.includes(behavior.title) ? '#ff9933' : 'transparent',
                    borderWidth: message && message?.includes(behavior.title) ? 2 : 0,
                    borderRadius: 10,
                    margin: '5px',
                    padding: '10px',
                    backgroundColor:
                      message && message?.includes(behavior.title) ? '#F5F5F5' : 'transparent',
                  }}
                  onClick={() => {
                    if (behavior.title === 'Other') {
                      setTextInputVisible(true);
                      setMessage(behavior.title);
                    } else {
                      setTextInputVisible(false);
                      setMessage(behavior.title);
                    }
                  }}
                  disabled={message && message?.includes(behavior.title) ? true : false}
                >
                  <DeleteIcon
                    color={
                      message && message?.includes(behavior.title) ? '#ff9933' : behavior.iconColor
                    }
                  />
                  <Typography
                    variant="caption"
                    style={{
                      color: message && message?.includes(behavior.title) ? '#ff9933' : '#808080',
                      textAlign: 'center',
                      padding: '5px',
                    }}
                  >
                    {behavior.title}
                  </Typography>
                </IconButton>
              ))}
            </div>
          </DialogContent>
          {textInputVisible ? (
            <DialogContent>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                placeholder={t('Report-PlaceHolder') /*'Report-PlaceHolder'*/}
                autoFocus
                onChange={(e) => setMessage(`Other, ${e.target.value}`)}
              />
            </DialogContent>
          ) : null}
          <DialogActions>
            {message ? (
              <Button
                variant="contained"
                color="error"
                onClick={deleteAccount}
              >
                {t('DeleteAccountButton') /*'DeleteAccountButton'*/}
              </Button>
            ) : null}
            <Button
              variant="contained"
              onClick={() => {
                changeContent(0);
                setTextInputVisible(false);
                setMessage('');
                changeVisible(!visible);
              }}
            >
              {t('CancelButton') /*'Cancel'*/}
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default DeleteAccountModal;
