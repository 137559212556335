import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import mbti from '../../assets/mbti.png';

const MBTIModal = ({ visible, changeVisible, t }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const content =  t('Filter-MBTIIntro'); // 'Filter-MBTIIntro'

  const handleClose = () => {
    changeVisible(false);
  };

  return (
    <Dialog
      open={visible}
      fullScreen={fullScreen}
      maxWidth="sm"
      // fullWidth
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography variant="h4">{t('Profile-Personality') /*'Personality'*/}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" component="div">
            {content}
          </Typography>
        </DialogContentText>
        <ImageList variant="standard" cols={1}>
          <ImageListItem key={1}>
            <img
              src={mbti}
              alt="MBTI compartible chart"
              style={{ width: '100%', height: '100%' }}
            />
          </ImageListItem>
        </ImageList>
        <Paper elevation={3} style={{ marginTop: theme.spacing(2) }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: theme.spacing(2),
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 46,
                  height: 23,
                  backgroundColor: '#3399ff',
                  marginRight: theme.spacing(1),
                }}
              />
              <Typography variant="body1">
                {t('mbti-ideal') /*"ideal"*/}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 46,
                  height: 23,
                  backgroundColor: '#00cc66',
                  marginRight: theme.spacing(1),
                }}
              />
              <Typography variant="body1">
                {t('mbti-good') /*"good"*/}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 46,
                  height: 23,
                  backgroundColor: '#4dff4d',
                  marginRight: theme.spacing(1),
                }}
              />
              <Typography variant="body1">
                {t('mbti-oneside') /*"oneside"*/}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 46,
                  height: 23,
                  backgroundColor: '#ffff66',
                  marginRight: theme.spacing(1),
                }}
              />
              <Typography variant="body1">
                {t('mbti-notIdeal') /*"notIdeal"*/}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 46,
                  height: 23,
                  backgroundColor: '#ff0000',
                  marginRight: theme.spacing(1),
                }}
              />
              <Typography variant="body1">
                {t('mbti-bad') /*"bad"*/}
              </Typography>
            </div>
          </div>
        </Paper>
        <Typography
          variant="body1"
          component="div"
          style={{
            textAlign: 'center',
            lineHeight: '25px',
            textDecoration: 'underline',
            color: '#3399ff',
            fontSize: '18px',
            cursor: 'pointer',
          }}
          onClick={() => {
            // WebBrowser.openBrowserAsync(`${i18n.t('mbti-link')}`);
            // Handle opening the link in a new window or tab here
            window.open(`${t('mbti-link')}`, '_blank');
          }}
        >
          {t('LearnMoreButton') /*"Learn More"*/}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          sx={{
            borderColor: 'transparent',
            borderWidth: 1,
            borderRadius: '30px',
            marginTop: '10px',
          }}
        >
          {t('DoneButton') /*'Done'*/}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MBTIModal;
