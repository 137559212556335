// import { mediaDevices } from "react-native-webrtc";
/**
 * Gets the local stream from the user's device
 * @returns {Promise<MediaStream>}
 */
export const getLocalStream = async () => {
  const isFront = true;
  const devices = await navigator.mediaDevices.enumerateDevices();

  const facing = isFront ? "front" : "environment";
  const videoSourceId = devices.find(
    (device) => device.kind === "videoinput" // && device.facing === facing
  );
  const facingMode = isFront ? "user" : "environment";
  const constraints = {
    audio: true,
    video: true
    // video: {
    //   width: { min: 500 },
    //   height: { min: 300 },
    //   frameRate: { min: 30 },
    //   facingMode: facing,
    //   deviceId: videoSourceId ? videoSourceId.deviceId /*{ exact: videoSourceId.deviceId }*/ : undefined,
    // },
    // video: {
    //   mandatory: {
    //     minWidth: 500,
    //     minHeight: 300,
    //     minFrameRate: 30,
    //   },
    //   facingMode,
    //   optional: videoSourceId ? [{ sourceId: videoSourceId }] : [],
    // },
  };
  try {
    const newStream = await navigator.mediaDevices.getUserMedia(constraints);
    return newStream;
  } catch (error) {
    console.error("error", error);
  }
};