import React, { useContext, useState } from 'react';
import {
  Button,
  Container,
  Typography,
  Checkbox,
  Paper,
  FormControlLabel,
  LinearProgress,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'
import { auth } from '../firebase';

const AddSexualOrientationScreen = ({ navigation }) => {
  const { t, info, setInfo } = useContext(AuthContext);
  // const navigate = useNavigate();
  const quizzes = [
    {
      ids: 0,
      question: t('AddSexual-Gender') /*'AddSexual-Gender'*/,
      answers: [
        { id: 0, text: t('male') /*'male'*/, summary: 'male', value: false },
        { id: 1, text: t('female') /*'female'*/, summary: 'female', value: false },
        { id: 2, text: t('other') /*'other'*/, summary: 'other', value: false },
      ],
      subcription: 'gender',
    },
    {
      ids: 1,
      question: t('AddSexual-ShowGender') /*'AddSexual-ShowGender'*/,
      answers: [
        { id: 0, text: t('man') /*'man'*/, summary: 'male', value: false },
        { id: 1, text: t('woman') /*'woman'*/ , summary: 'female', value: false },
        { id: 2, text: t('everyone') /*'everyone'*/, summary: 'everyone', value: false },
      ],
      subcription: 'showGender',
    },
    {
      ids: 2,
      question: t('AddSexual-DatePurpose') /*'AddSexual-DatePurpose'*/,
      answers: [
        { id: 0, text: t('short-term-date') /*'short-term-date'*/, summary: 'short-term-date', value: false },
        { id: 1, text: t('make-friends') /*'make-friends'*/, summary: 'make-friends', value: false },
        { id: 2, text: t('long-term-date') /*'long-term-date'*/, summary: 'long-term-date', value: false },
        { id: 3, text: t('FWB-ONS') /*'FWB-ONS'*/, summary: 'FWB-ONS', value: false },
      ],
      subcription: 'datePurpose',
    },
    {
      ids: 3,
      question: t('AddSexual-SexualOrientation') /*'AddSexual-SexualOrientation'*/,
      answers: [
        { id: 0, text: t('straight') /*'straight'*/ , summary: 'straight', value: false },
        { id: 1, text: t('gay') /*'gay'*/, summary: 'gay', value: false },
        { id: 2, text: t('lesbian') /*'lesbian'*/, summary: 'lesbian', value: false },
        { id: 3, text: t('bisexual') /*'bisexual'*/, summary: 'bisexual', value: false },
        { id: 4, text: t('asexual') /*'asexual'*/, summary: 'asexual', value: false },
        { id: 5, text: t('demisexual') /*'demisexual'*/, summary: 'demisexual', value: false },
      ],
      subcription: 'sexualOrientation',
    },
  ];

  const [datas, setDatas] = useState(quizzes);
  const [result, setResult] = useState({});

  const getNavigation = (data) => {
    if (!data.email) {
      // navigate('add_email');
      navigation('email');
      return;
    } else if (!data.phoneNumber) {
      // navigate('phone_number');
      navigation('phone');
      return;
    } else if (!data.displayName) {
      // navigate('add_name');
      navigation('name');
      return;
    } else if (!data.birthday) {
      // navigate('add_birthday');
      navigation('birthday');
      return;
    } else if (!data?.gender) {
      // navigate('add_sexual');
      navigation('sexual');
      return;
    } else if (!data.photoURL || data?.photoURL?.length === 0) {
      // navigate('add_photo');
      navigation('photo');
      return;
    } else {
      // navigate('add_location');
      navigation('location');
      return;
    }
  };

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  const handlePress = (questionid, answerid) => {
    let tempdata = datas.map((data) => {
      if (questionid !== data.ids) {
        return data;
      }
      let answerlist = data.answers.map((answer) => {
        if (answerid !== answer.id) {
          return { ...answer, value: false };
        }
        let property = data.subcription;
        setResult({ ...result, [property]: answer.summary });
        return { ...answer, value: true };
      });
      return { ...data, answers: answerlist };
    });
    setDatas(tempdata);
  };

  const Handlebutton = () => {
    console.log(result);
    setInfo({ ...info, ...result });
    getNavigation(info);
  };

  return (
    <div style={{ 
      flex: 1, 
      // padding: 20, https://source.unsplash.com/random?wallpapers
      backgroundImage: `url(${loginImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: (t) =>
        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <Container 
        maxWidth="sm"
        sx={{ background: 'linear-gradient(to bottom, #ccccff, #33ccff)', height: '100vh', overflowY: 'scroll' }}
      >
        <IconButton sx={{ position: 'relative', flexDirection: 'row-reverse' }} onClick={navigateToHome}>
          <CloseIcon />
        </IconButton>
        <Box p={2}>
          <Typography variant="h4" style={{ marginTop: 40, padding: 20, fontSize: 35, color: 'black', textAlign: 'center' }}>
            {t('AddSexual-AnswerQuestion') /*'AddSexual-AnswerQuestion'*/}
          </Typography>
        </Box>
        <div >
          {datas &&
            datas.map((data, ids) => (
              <Box key={ids} style={{ marginTop: 10, padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
                <Typography variant="h6" style={{ marginLeft: 10, fontSize: 22, padding: 10 }}>
                  {data?.question}
                </Typography>
                {data?.answers.map((answer, id) => (
                  <Box key={id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={answer.value}
                          onChange={() => {
                            handlePress(ids, id);
                          }}
                        />
                      }
                      label={answer?.text}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          {/* <Box p={3} alignItems="center"> */}
            <Button
              variant="outlined"
              // color="inherit"
              style={{ marginTop: 30, marginBottom: 30, width: '100%', backgroundColor: 'white', borderRadius: 10 }}
              disabled={Object.keys(result).length !== 4}
              onClick={Handlebutton}

            >
              {t('ContinueButton') /*'ContinueButton'*/}
            </Button>
          {/* </Box> */}
        </div>
      </Container>
    </div>
  );
};

export default AddSexualOrientationScreen;
