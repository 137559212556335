import { useEffect, useState } from 'react';
import { firestore } from '../firebase';

const useAllAreas = (accessAreas, polygons) => {
  const [allAreas, setAllAreas] = useState([]);

  useEffect(() => {
    if (!Array.isArray(accessAreas) || !Array.isArray(polygons) || !accessAreas || !polygons) {
      setAllAreas([]);
      return;
    }

    const values = [...accessAreas, ...polygons];
    const chunkSize = 10;
    const queries = [];

    for (let i = 0; i < values.length; i += chunkSize) {
      const chunk = values.slice(i, i + chunkSize);
      const query = firestore()
        .collection('areas')
        .where(firestore.FieldPath.documentId(), 'in', chunk);
      queries.push(query);
    }

    Promise.all(queries.map(query => query.get()))
      .then(querySnapshots => {
        const documents = querySnapshots.flatMap(querySnapshot =>
          querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
        setAllAreas(documents);
      })
      .catch(error => {
        console.log(error);
        setAllAreas([]);
      });
  }, [accessAreas, polygons]);

  return allAreas;
};

export default useAllAreas;
