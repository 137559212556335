import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  CssBaseline,
  Avatar,
  Grid, 
  Paper,
  TextField,
  IconButton,
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { width } from '../config';
import { auth } from '../firebase';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import OTPInput, { ResendOTP } from "otp-input-react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'

const defaultTheme = createTheme();

const PhoneNumberScreen = ({ navigation }) => {
  const { info, setInfo, t, /*troubleLogin, setTroubleLogin*/ mailRef, loginRef } = useContext(AuthContext);
  const [phonenumber, setPhoneNumber] = useState('');
  // const [countryCode, setCountryCode] = useState();
  const [verificationId, setVerificationId] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  // const [loading, setLoading] = useState(false);
  const [showcomponent, setShowcomponent] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const phonereg = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/;
  // const lengthInput = 6;
  // let codeInput = useRef(null);
  

  const getNavigation = (data) => {
    if (!data.email) {
      // navigate('add_email');
      navigation('email');
      return;
    } else if (!data.phoneNumber) {
      // navigate('phone_number');
      navigation('phone');
      return;
    } else if (!data.displayName) {
      // navigate('add_name');
      navigation('name');
      return;
    } else if (!data.birthday) {
      // navigate('add_birthday');
      navigation('birthday');
      return;
    } else if (!data?.gender) {
      // navigate('add_sexual');
      navigation('sexual');
      return;
    } else if (!data.photoURL || data?.photoURL?.length === 0) {
      // navigate('add_photo');
      navigation('photo');
      return;
    } else {
      // navigate('add_location');
      navigation('location');
      return;
    }
  };

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  // const appVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
  //   size: 'invisible',
  //   callback: function (response) {
  //     console.log('It works!');
  //   },
  // });
  // appVerifier.render();

  // const onCaptchaVerify = () => {
  //   if (!window.recaptchaVerifier) {
  //     window.recaptchaVerifier = new auth.RecaptchaVerifier(
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //         callback: (response) => {
  //           // onSignup();
  //           SendVerificationCode()
  //         },
  //         "expired-callback": () => {},
  //       },
  //       app
  //     );
  //   }
  // }

  // function onSignup() {
  //   setLoading(true);
  //   onCaptchaVerify();

  //   const appVerifier = window.recaptchaVerifier;

  //   // const formatPh = "+" + ph;

  //   auth().signInWithPhoneNumber(phonenumber, appVerifier)
  //     .then((confirmationResult) => {
  //       window.confirmationResult = confirmationResult;
  //       setLoading(false);
  //       showcomponent(true);
  //       // toast.success("OTP sended successfully!");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // }

  // function onOTPVerify() {
  //   setLoading(true);
  //   window.confirmationResult
  //     .confirm(verificationId)
  //     .then(async (res) => {
  //       console.log(res);
  //       // setUser(res.user);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // }

  const SendVerificationCode = async () => {
    try {
      // setLoading(true);
      // onCaptchaVerify();
      const appVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible', // You can change this to 'normal' if you want to display a visible reCAPTCHA
      });
      // const appVerifier = window.recaptchaVerifier;
      await appVerifier.verify();
      const confirmation = await auth().signInWithPhoneNumber(phonenumber, appVerifier);
      // console.log(confirmation)
      // setLoading(false);
      setShowcomponent(true);
      const verificationId = confirmation.verificationId;
      setVerificationId(verificationId);
    } catch (err) {
      console.error(err);
    }
  };

  

  const ConfirmVerificationCode = async () => {
    try {
      const credential = auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      let user = auth().currentUser;
      if (user && mailRef.current) {
        await user.updatePhoneNumber(phonenumber);
      } else if(user && !mailRef.current) {
        await user.linkWithCredential(credential);
      } else {
        await auth().signInWithCredential(credential);
      }
      user = auth().currentUser;
      const collectedData = {
        ...info,
        id: user?.uid,
        phoneNumber: user?.phoneNumber,
      };
      setInfo(collectedData);
      // if (troubleLogin) {
      //   setTroubleLogin(!troubleLogin);
      // }
      mailRef.current = false;
      !loginRef.current ? getNavigation(collectedData) : void 0;
    } catch (err) {
      console.error(err);
      let user = auth().currentUser;
      if (user) {
        await auth().signOut();
      }
    }
  };

  const handleResendClick = async () => {
    // Handle the resend logic here
    // For example, you can trigger the verification code sending process
    // and then disable the button until the countdown completes
    await SendVerificationCode();
    // await onSignup()
    setIsButtonDisabled(true);
  }

  useEffect(() => {
    if (!showcomponent) {
      return;
    }
    setIsButtonDisabled(true);
    
  }, [showcomponent]);


  useEffect(() => {
    let countdownInterval;
  
    if (isButtonDisabled) {
      // Enable the button after 60 seconds
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            // If countdown is greater than 0, decrement it
            return prevCountdown - 1;
          } else {
            // If countdown reaches 0, clear interval and perform necessary actions
            clearInterval(countdownInterval);
            setIsButtonDisabled(false);
            // setCountdown(60); // Reset countdown to 60
            return 60; // or any other value as needed
          }
        });
      }, 1000);
    } /*else {
      // Disable the button again
      setCountdown(60);
      clearInterval(countdownInterval);
    }*/
  
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(countdownInterval);
    };
  }, [isButtonDisabled]);
  

  // useEffect(() => {
  //   let countdownInterval;

  //   if (isButtonDisabled) {
  //     // Enable the button after 60 seconds
  //     countdownInterval = setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);
  //   } else {
  //     // Disable the button again
  //     setCountdown(60);
  //     clearInterval(countdownInterval);
  //   }

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(countdownInterval);
  //   };
  // }, [isButtonDisabled]);

  return (
    <div 
      style={{ 
        flex: 1, 
        // padding: 20, https://source.unsplash.com/random?wallpapers
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }}>
          
      <ThemeProvider theme={defaultTheme} >
        <Container 
          component={'main'}  
          maxWidth="xs"  
          sx={{backgroundColor: 'white', minHeight: '100vh'}}
        >
          <CssBaseline />
          <IconButton sx={{ position: 'relative', flexDirection: 'row-reverse'}} onClick={navigateToHome}>
            <CloseIcon />
          </IconButton>
          <div id="recaptcha-container"></div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5" sx={{mt: 8}}>
              { showcomponent ? t('AddPhone-Title') /*'Enter Verification code'*/ : t('AddPhone-Title') /*'Enter your phone number'*/ }
            </Typography>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mt: 5}}>
              <PhoneIcon />
            </Avatar>
            { showcomponent ? (
            <Box sx={{ mt: 5 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2">
                      {phonenumber}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button 
                      type="submit"
                      fullWidth
                      variant="text"
                      // sx={{ mt: 5, mb: 2 }}
                      disabled={isButtonDisabled}
                      onClick={handleResendClick}
                    >
                      {`Resend ${countdown !== 60 ? countdown: ''}`}
                    </Button>
                    {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                  </Grid>
                </Grid>
                <OTPInput
                  // style={{marginTop: 10}} 
                  value={verificationCode} 
                  onChange={setVerificationCode} 
                  autoFocus 
                  OTPLength={6} 
                  otpType="number" 
                  disabled={false} 
                  secure
                />
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 5, mb: 2 }}
                onClick={ConfirmVerificationCode}
                // disabled={!phonereg.test(phonenumber)}
              >
                {t('ConfirmButton') /*'Confirm'*/}
              </Button>
            </Box>
            ) : (
              <Box sx={{ mt: 5 }}>
              <PhoneInput
                country={"us"}
                value={phonenumber} 
                onChange={(text) => setPhoneNumber('+' + text)} 
              />
              <Button
                // id='sign-in-button'
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 5, mb: 2 }}
                onClick={SendVerificationCode}
                disabled={!phonereg.test(phonenumber)}
              >
                {t('VerifyButton') /*'Verify'*/}
              </Button>
            </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default PhoneNumberScreen;
