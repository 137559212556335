// import { StyleSheet, Text, View, /*Image,*/ Platform, SafeAreaView, ScrollView, TouchableOpacity, TextInput, ActivityIndicator, StatusBar } from 'react-native'
import React, { useContext, useState, useRef, memo } from 'react'
// import { Avatar, ListItem, Icon, Button } from 'react-native-elements';
import { Avatar, Box, Button, Container, IconButton, LinearProgress, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhotoIcon from '@mui/icons-material/Photo';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LinkIcon from '@mui/icons-material/Link';
import WcIcon from '@mui/icons-material/Wc';
import ExtensionIcon from '@mui/icons-material/Extension';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import TranslateIcon from '@mui/icons-material/Translate';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ReplayCircleFilledOutlinedIcon from '@mui/icons-material/ReplayCircleFilledOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
// import { Image } from 'expo-image';
import { auth, firestore, database, storage } from '../firebase';
import { AuthContext } from '../context/AuthProvider';
import AddImageModal from '../components/PhotoComponents/AddImageModal';
import DeleteImageModal from '../components/PhotoComponents/DeleteImageModal'
import InfoModal from '../components/ProfileComponents/InfoModal';
import InterestsModal from '../components/ProfileComponents/InterestsModal';
import ImageList from '../components/HomeComponents/ImageList';
import { useTheme } from '@mui/material/styles';
import { height, width } from '../config';
import compareObjects from '../lib/compareObjects';
import VerifyModal from '../components/ProfileComponents/VerifyModal';
// import Purchases from 'react-native-purchases';
import ExtendTimeModal from '../components/ProfileComponents/ExtendTimeModal';
import DeleteAccountModal from '../components/ProfileComponents/DeleteAccountModal';
import { SelectAreaContext } from '../context/SelectAreaProvider';
import i18next from 'i18next';

const ProfileScreen = ({navigation}) => {
  const styles = {
    container: {
      flex: 1,
      // display: 'flex',
      // flexDirection: 'column',
      height: '100vh',
      // backgroundColor: 'white'
      // paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0
    },
    profileContainer: {
      justifyContent: 'center', 
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    buttonContainer: { 
      padding: 10, 
      width: '100%', 
      display: 'flex',
      justifyContent: 'space-between',
      // justifyContent: 'flex-end', 
      flexDirection: 'row'
    },
    avatar: {
      backgroundColor: 'white',
      borderWidth: 0.5,
      borderColor: '#f2f2f2',
      background: 'linear-gradient(transparent, black, black)', 
    },
    nameContainer: {
      flexDirection: 'row', 
      alignItems: 'center', 
      paddingTop: 5,
      display: 'flex'
    },
    nameText: {
      fontSize: 20, 
      fontWeight: '500', 
      paddingRight: 5
    },
    tabContainer: {
      flexDirection: 'row', 
      paddingTop: 5
    },
    tabButton: {
      width: '50%', 
      alignItems: 'center', 
      padding: 10 
    },
    tabButtonSelected: {
      borderBottom: '2px solid black',
      borderRadius: 0
    },
    gridContainerStyle: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)', // Three columns of equal width
      gridTemplateRows: 'repeat(3, 1fr)', // Three columns of equal width
      gap: '5px', // Adjust the gap as needed
      justifyContent: 'center', // Center the grid horizontally
    },
    gridItemStyle: {
      width: '100%',     // Set the width to 100% to fill the grid cell
      height: '100%',    // Set the height to 100% to fill the grid cell
      padding: 8,
      backgroundColor: 'transparent',
      border: 'none',
    },
    sectionContainer: {
      borderBottomWidth: 0.5, 
      margin: 15, 
      paddingBottom: 10
    },
    sectionContent: {
      flexDirection: 'row', 
      alignItems: 'center',
      display: 'flex',
    },
    sectionText: {
      fontSize: 16, 
      paddingVertical: 10,
      fontWeight: '500',
      paddingLeft: 5
    },
    sectionInput: { 
      fontSize: 15, 
      // borderRadius: 5, 
      // height: 80,
      width: '100%',
      backgroundColor: 'white' 
    },
    sectionInfo: {
      paddingVertical: 5, 
      color: '#b3b3b3'
    },
    sectionInfoWrapper: {
      borderRadius: 5, 
      // backgroundColor: '#f2f2f2', 
      flexWrap: 'wrap', 
      alignItems: 'flex-start'
    },
    logoutButton: {
      backgroundColor: 'rgba(90, 154, 230, 1)',
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 10,
      margin: 10,
      width: '100%',
      height: '6vh',
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonTitle: {
      fontSize: 15,
      color: 'white',
      fontWeight: '500'
    },
    small_frame: {
      width: /*'30%',*/ height / 5, 
      height: /*'40%',*/ width / 7,  
      borderWidth: 1, 
      borderRadius:10, 
      // borderColor: 'grey', 
      backgroundColor: 'lightgrey',
      alignItems: 'center'
    },
    small_picture: {
      width: /*'30%',*/ height / 5,
      height: /*'20%',*/ width / 7,
      borderRadius: 10,
      flexDirection: 'row',
      borderWidth: 1,
      backgroundColor: 'white',
    },
    loading: {
      justifyContent: 'center', 
      alignItems: 'center'
    },
    listContainer: {
      justifyContent: 'center', 
      flex: 1, 
      borderBottomWidth: 0.5, 
      paddingBottom: 10,
    },
    listContent: {
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      width: '95%'
    },
    listText: {
      paddingVertical: 5, 
      color: '#b3b3b3', 
      fontSize: 14, 
      paddingLeft: 5
    },
    cardContainer: {
      flex: 1, 
      alignItems: 'center', 
      height: /*height*/ '90vh', 
      padding: 10,
      display: 'flex',
      width: '100%'
    },
    card: {
      position: 'relative', 
      height: '95%', // temporary solution,
      width: '100%',
      borderRadius: 10,
      borderWidth: 0.5,
      borderColor: '#d3d3d3',
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.36,
      shadowRadius: 6.68,
      elevation: 1,
      // background: 'linear-gradient(transparent, black, black)', 
    },
    cardNameContainer: {
      flexDirection: 'row', 
      alignItems: 'center',
      display: 'flex'
    },
    cardName: {
      fontSize: 25, 
      fontWeight: 'bold', 
      color: 'white', 
      paddingLeft: 10
    },
    imageList: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10
    },
    cardInfoWrapper: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      // paddingHorizontal: 10, 
      // paddingVertical: 5,
      padding: 5,
      display: 'flex'
    },
    cardInfoText: {
      fontSize: 15, 
      color: 'white', 
      paddingLeft: 5
    },
    cardTagContainer: {
      flexWrap: 'wrap', 
      alignItems: 'center', 
      flexDirection: 'row', 
      // paddingHorizontal: 10, 
      // paddingVertical: 5, 
      padding: 5,
      justifyContent: 'flex-start',
      display: 'flex'
    },
    cardTag: {
      flexDirection: 'row', 
      padding: 5, 
      // paddingRight: 5,
      borderRadius: 20, 
      borderColor: 'white', 
      margin: 5, 
      alignItems: 'center',
      display: 'flex',
      border: '0.5px solid white',
    },
    cardTagIcon: {
      backgroundColor: 'transparent', 
      color: 'white'
    },
    background: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      width: '100%',
      // backgroundColor: 'transparent',
      borderRadius: 10,
      borderColor: '#d3d3d3',
      zIndex: 3,
      flexDirection: 'column',
      background: 'linear-gradient(transparent, black, black)', 
    },
    buttons: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      padding: 10,
      flex: 1,
      display: 'flex'
    },
    button: {
      width: 50,
      height: 50,
      borderRadius: 25,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white'
    },
    verifyStatus: {
      padding: 10
    }
  }
  const {
    user: { 
      id, displayName, age, photoURL, verifiedStatus, introduction, job, school, company, gender, sexualOrientation, datePurpose, zodiac, personality, interests, account, ticket, huntingTime
    }, snapShotUnsubscribeRef, t, setScreen, currentLanguage, setCurrentLanguage
  } = useContext(AuthContext);
  // const { screen, setScreen } = useContext(SelectAreaContext);
  const [intermediateInfo, setIntermediateInfo] = useState({
    id, displayName, age, photoURL, verifiedStatus, introduction, job, school, company, gender, sexualOrientation, datePurpose, zodiac, personality, interests, account, ticket, huntingTime
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [tab, setTab] = useState(0)
  const [renderContent, setRenderContent] = useState(true);
  const [addImageVisible, setAddImageVisible] = useState(false);
  const [deleteImageVisible, setDeleteImageVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [interestsVisible, setInterestsVisible] = useState(false);
  const [verifyVisible, setVerifyVisible] = useState(false);
  const [extendTimeVisible, setExpandTimeVisible] = useState(false);
  const [deleteAccountVisible, setDeleteAccountVisible] = useState(false);
  const [genderVisible, setGenderVisible] = useState(false);
  // const [sexualVisible, setSexualVisible] = useState(false);
  const [purposeVisible, setPurposeVisible] = useState(false);
  const imageIndex = useRef(null);
  // const [expanded, setExpanded] = useState({
  //   gender: false,
  //   sexualOrientation: false,
  //   datePurpose: false
  // });

  const [uploadProgress, setUploadProgress] = useState(false);
  // const deletePhoneNumber = async () => {
  //   const user = auth().currentUser;
  //   await user.updatePhoneNumber(null);
  // };
  const blurhash = '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';
  const genderlist = [
    { value: 'male', name: 'IoMdMale'}, 
    { value: 'female', name: 'IoMdFemale'}, 
    { value: 'other', name: 'IoMaleFemale'}
  ];
  const sexualOrientationlist = [
    { value: 'straight', name: 'FaVenusMars'}, 
    { value: 'gay', name: 'FaMarsDouble'}, 
    { value: 'lesbian', name: 'FaVenusDouble'}, 
    { value: 'bisexual', name: 'FaTransgenderAlt'}, 
    { value: 'asexual', name: 'FaGenderless'}, 
    { value: 'demisexual', name: 'FaMarsStroke'},
  ]; 
  const datingPurposeList = [
    { value: 'short-term-date', name: 'FaGlassCheers'}, 
    { value: 'make-friends', name: 'MdWavingHand'}, 
    { value: 'long-term-date', name: 'TbHeartHandshake'}, 
    { value: 'FWB-ONS', name: 'GiFireworkRocket'}
  ];
  const zodiaclist = [
    { value: 'aquarius', name: 'GiAquarius'}, 
    { value: 'aries', name: 'GiAries'}, 
    { value: 'cancer', name: 'GiCancer'}, 
    { value: 'capricorn', name: 'GiCapricorn'}, 
    { value: 'gemini', name: 'GiGemini'}, 
    { value: 'leo', name: 'GiLeo'},
    { value: 'libra', name: 'GiLibra'}, 
    { value: 'pisces', name: 'GiPisces'}, 
    { value: 'sagittarius', name: 'GiSagittarius'}, 
    { value: 'scorpio', name: 'GiScorpio'}, 
    { value: 'taurus', name: 'GiTaurus'}, 
    { value: 'virgo', name: 'GiVirgo'}
  ]; 
  const personalitylist = [
    {value: 'INTJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/intj-architect.png'}, 
    {value: 'INTP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/intp-logician.png'}, 
    {value: 'ENTJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/entj-commander.png'}, 
    {value: 'ENTP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/entp-debater.png'}, 
    {value: 'INFJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/infj-advocate.png'}, 
    {value: 'INFP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/infp-mediator.png'},
    {value: 'ENFJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/enfj-protagonist.png'}, 
    {value: 'ENFP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/enfp-campaigner.png'}, 
    {value: 'ISTJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/istj-logistician.png'}, 
    {value: 'ISFJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/isfj-defender.png'}, 
    {value: 'ESTJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/estj-executive.png'}, 
    {value: 'ESFJ', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/esfj-consul.png'},
    {value: 'ISTP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/istp-virtuoso.png'},
    {value: 'ISFP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/isfp-adventurer.png'},
    {value: 'ESTP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/estp-entrepreneur.png'},
    {value: 'ESFP', uri: 'https://www.16personalities.com/static/images/personality-types/avatars/esfp-entertainer.png'}
  ];
  const languageList = [
    // 'English', 'Spain'
    { name: 'English', code: 'en'}, 
    { name: 'Spainish', code: 'es'},
    { name: 'French', code: 'fr'},
    { name: 'German', code: 'de'},
    { name: 'Chinese', code: 'zh'},
    { name: 'Vietnamese', code: 'vi'},
    { name: 'Italian', code: 'it'},
    { name: 'Japanese', code: 'ja'},
    { name: 'Korean', code: 'ko'},
    // { name: 'Arabic', code: 'ar'},
    // { name: 'Bengali', code: 'bn'},
    { name: 'Czech', code: 'cs'},
    // { name: 'Greek', code: 'el'},
    { name: 'Finnish', code: 'fi'},
    // { name: 'Hindi', code: 'hi'},
    { name: 'Hungarian', code: 'hu'},
    { name: 'Dutch', code: 'nl'},
    { name: 'Norwegian', code: 'no'},
    { name: 'Polish', code: 'pl'},
    { name: 'Portuguese', code: 'pt'},
    // { name: 'Roumanian', code: 'ro'},
    // { name: 'Russian', code: 'ru'},
    { name: 'Swedish', code: 'sv'},
    // { name: 'Turkish', code: 'tr'},
    // { name: 'Urdu', code: 'ur'},
  ];
  const [changeInfo, setChangeInfo] = useState({title: '', list: [] });

  const restorePurchase = async () => {
    // const purchaserInfo = await Purchases.restorePurchases();
    // if (purchaserInfo.activeSubscriptions.length > 0) {
    //   alert(t('RestorePurchaseAlert1'));
    // } else {
    //   alert(t('RestorePurchaseAlert2'));
    // }
  };

  // const logout = () => {
  //   console.log('start clean up database')
  //   database().ref(`/status/${id}`).set({
  //     state: 'offline',
  //     last_changed: database.ServerValue.TIMESTAMP,
  //   })
  //   .then(() => {
  //     console.log('start go offline')
  //     database().goOffline()
  //     // snapShotUnsubscribeRef.current && snapShotUnsubscribeRef.current();
  //     // statusUnsubscribeRef.current && statusUnsubscribeRef.current();
  //     .then(() => {
  //       console.log('start sign out')
  //       auth().signOut()
  //       .then(console.log('Sign-out successful'))
  //       .catch((error) => console.log(error.message))
  //     })
  //     .catch((error) => console.log(error.message))
  //   })
  //   .catch((error) => console.log(error.message))
  // }

  const logout = async () => {
    try {
      await database().ref(`/status/${id}`).set({
        state: 'offline',
        last_changed: database.ServerValue.TIMESTAMP,
      });

      await auth().signOut();
  
      snapShotUnsubscribeRef.current?.()

      setScreen('frontpage')
  
      console.log('Sign-out successful');
    } catch (error) {
      console.log(error.message);
    }
  };
  

  // const logout = () => {
  //   // goOffline(rdb)
  //   set(userStatusDatabaseRef, isOfflineForDatabase).then(() => {
  //     goOffline(rdb);
  //     signOut(auth)
  //     .then(() => {
  //       // Sign-out successful.
  //       console.log('Sign-out successful')
  //     }).catch((error) => {
  //       // An error happened.
  //       console.log(error.message)
  //     });
  //   }).catch((error) => console.error(error))
  // }
  const updateImages = async (data) => {
    await firestore().doc(`users/${id}`).update({
      photoURL: data
    })
  }
  const updateInfo = async () => {
    const info = {id, displayName, age, photoURL, verifiedStatus, introduction, job, school, company, gender, sexualOrientation, datePurpose, zodiac, personality, interests}
    const { differences, isEqual } = compareObjects(info, intermediateInfo);
    if (isEqual) {
      navigation('home');
      return;
    }
    // console.log(differences, isEqual);
    // await updateDoc(doc(db, 'users', id), differences);
    await firestore().doc(`users/${id}`).update(differences);
    navigation('home')
  }

  const handleDeleteImageModal = (index) => {
    setDeleteImageVisible(true); 
    // setImageNum(index)
    imageIndex.current = index
  }

  const handleAddImageModal = () => {
    if (photoURL.length === 1) {
      alert(t('Profile-Alert1'));
      return;
    }
    if (uploadProgress) {
      return;
    }
    setAddImageVisible(true);
  }
  // console.log(storage);
  return (
    // <div
    //   style={{ flex: 1 }}
    //   colors={['#ccccff', '#33ccff']}
    // >
      <Container 
        component={Paper}
        maxWidth="md"
        // sx={styles.container}
        sx={{
          flex: 1,
          width: '100%',
          height: '100vh', // Set the desired height for the scrollable container
          overflowY: 'scroll', // Enable vertical scrolling
        }}
      >
        <div
          // style={{
          //   width: '100%',
          //   height: '100vh', // Set the desired height for the scrollable container
          //   overflowY: 'scroll', // Enable vertical scrolling
          //   border: '1px solid #ccc', // Add a border for styling
          //   backgroundColor: 'whitesmoke'
          // }}
        >
          { tab === 0 ? (
            <Box sx={styles.profileContainer}>
              <div style={styles.buttonContainer}>
                <Button
                  // sx={{display: { xs: 'none' }}}
                  onClick={updateInfo}
                  style={{...styles.buttonTitle, color: '#3399ff'}}
                >{ isMobile ? t('DoneButton') : t('SaveChangeButton')}</Button>
                <Button
                  sx={{display: { xs: 'none', md: 'flex' }}}
                  onClick={() => navigation('filter')}
                  style={{...styles.buttonTitle, color: '#3399ff'}}
                >{t('SwitchFilterButton')}</Button>
              </div>
              <VerifyModal
                visible={verifyVisible}
                changeVisible={setVerifyVisible}
                id={id}
                t={t}
              />
              <Avatar 
                // sx={{ width: width / 2, height: height / 2 }}
                alt={displayName?.charAt(0).toUpperCase()} 
                rounded={true} 
                src={/*{uri: photoURL[0]?.uri}*/ photoURL[0]?.uri}
                sx={{
                  width: { xs: 200, sm: 250, md: 350, lg: 200 }, // Set different widths based on screen size
                  height: { xs: 200, sm: 250, md: 350, lg: 200 },
                  // border: 1,
                  backgroundColor: 'whitesmoke'
                }}
                // containerStyle={styles.avatar}
                />
              <div style={styles.nameContainer}>
                <p style={styles.nameText}>{`${displayName}, ${age}`}</p>
                { verifiedStatus === 'not-verified' || verifiedStatus === 'verifiedError' ?
                  <IconButton
                    sx={{ color: '#b3b3b3' }}
                    onClick={() => { setVerifyVisible(!verifyVisible) }}
                  >
                    <CheckCircleIcon sx={{ fontSize: '22px' }} />
                  </IconButton>
                  : null }
                { verifiedStatus === 'verifying' ?
                  <IconButton
                    sx={{ color: '#00ff99' }}
                    // onClick={() => { setVerifyVisible(!verifyVisible) }}
                  >
                    <AccessTimeIcon sx={{ fontSize: '22px' }} />
                  </IconButton> : null }
                { verifiedStatus === 'verified' ?
                  <IconButton
                    sx={{ color: '#3399ff' }}
                    // onClick={() => { setVerifyVisible(!verifyVisible) }}
                  >
                    <CheckCircleIcon sx={{ fontSize: '22px' }} />
                  </IconButton> : null }
              </div>
              { verifiedStatus === 'verifiedError' ?
                  <p style={{color: 'red', padding: 10, backgroundColor: 'white'}}>
                    {t('Profile-VerifyError')}
                  </p> : null }
            </Box>
            ) : (
              <div></div>
            )}
          <div style={styles.tabContainer}>
            <Button
              onClick={() => {setTab(0)}}
              // disabled={tab !== 0}
              style={tab === 0 ? {...styles.tabButtonSelected, ...styles.tabButton} : styles.tabButton}>
              <p style={{fontSize: 16, fontWeight: '500'}}>{t('Profile-EditProfile')}</p>
            </Button>
            <Button
              onClick={() => {setTab(1)}}
              // disabled={tab !== 1}
              style={tab === 1 ? {...styles.tabButtonSelected, ...styles.tabButton} : styles.tabButton}>
              <p style={{fontSize: 16, fontWeight: '500'}}>{t('Profile-PreviewProfile')}</p>
            </Button>
          </div>
          { tab === 0 ? (
            <div>
              <div style={styles.sectionContainer}>
                <div style={styles.sectionContent}>
                  <PhotoIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-EditPicture')} :`}</p>
                </div>
                <AddImageModal
                  visible={addImageVisible} 
                  changeVisible={setAddImageVisible}
                  data={photoURL} 
                  updateData={updateImages}
                  updateProgress={setUploadProgress}
                  userId={id}
                  t={t}
                />
                <DeleteImageModal
                  visible={deleteImageVisible}
                  changeVisible={setDeleteImageVisible}
                  index={imageIndex.current}
                  data={photoURL}
                  updateData={updateImages}
                  userId={id}
                  t={t}
                />
                {/* {
                  Array(3).fill().map((_, rowIndex) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center'
                      }}
                      key={rowIndex}
                    >
                      {Array(3).fill().map((_, colIndex) => {
                        const index = rowIndex * 3 + colIndex;
                        const source = photoURL[index];
                        const isImagePresent = source !== undefined;

                        return (
                          <button
                            key={index}
                            style={{
                              padding: 12,
                              backgroundColor: 'transparent', // isImagePresent ? 'transparent' : 'blue',
                              border: 'none',
                              cursor: isImagePresent ? 'pointer' : 'default'
                            }}
                            disabled={uploadProgress}
                            onClick={() => {
                              if (isImagePresent) {
                                handleDeleteImageModal(index);
                              } else {
                                handleAddImageModal();
                              }
                            }}
                          >
                            {isImagePresent ? (
                              <img
                                style={styles.small_picture}
                                src={source.uri}
                                alt={`Image ${index}`}
                              />
                            ) : (
                              <div
                                style={{ ...styles.small_frame, ...styles.loading }}
                              >
                                {uploadProgress && index === photoURL?.length ? <div className="activity-indicator"></div> : null}
                              </div>
                            )}
                          </button>
                        );
                      })}
                    </div>
                  ))
                } */}

                <div style={styles.gridContainerStyle}>
                  {Array(9).fill().map((_, index) => {
                    const source = index < photoURL.length ? photoURL[index] : undefined;
                    const isImagePresent = source !== undefined;

                    return (
                      <Button
                        key={index}
                        style={{
                          ...styles.gridItemStyle,
                          cursor: isImagePresent ? 'pointer' : 'default',
                        }}
                        disabled={uploadProgress}
                        onClick={() => {
                          if (isImagePresent) {
                            handleDeleteImageModal(index);
                          } else {
                            handleAddImageModal();
                          }
                        }}
                      >
                        {isImagePresent ? (
                          // <img
                          //   style={{ width: '100%', height: '100%', borderRadius: 10, backgroundColor: 'whitesmoke' }}
                          //   src={source.uri}
                          //   alt={`Image ${index}`}
                          // />
                          <Box 
                            sx={{ 
                              flex: 1, 
                              // padding: 20,
                              backgroundImage: `url(${source.uri})`,
                              backgroundRepeat: 'no-repeat',
                              width: '100%',
                              height: { xs: '150px', sm: '200px', md: '300px', lg: '150px', },
                              borderRadius: 2,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'lightgray', // Placeholder background color
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 10
                            }}
                          >
                            {uploadProgress && index === photoURL?.length ? (
                              <div className="activity-indicator"></div>
                            ) : null}
                          </div>
                        )}
                      </Button>
                    );
                  })}
                </div>

                <div style={{padding: 10, justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                  <button
                    title={t('AddPhotos-Title')}
                    style={styles.logoutButton}
                    // buttonStyle={{backgroundColor: '#3364c8'}}
                    // titleStyle={styles.buttonTitle}
                    disabled={uploadProgress}
                    disabledStyle={{backgroundColor: '#b3b3b3'}}
                    onClick={handleAddImageModal}
                  >
                    {t('AddPhotos-Title')}
                  </button>
                </div>
              </div>
              <div style={styles.sectionContainer}>
                <div style={styles.sectionContent}>
                  <PersonIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-AboutYou')} :`}</p>
                </div>
                <TextField
                  sx={styles.sectionInput}
                  placeholder={t('Profile-AboutYouPlaceHolder')}
                  maxLength={500}
                  defaultValue={intermediateInfo?.introduction}
                  focusable
                  multiline
                  onChange={(event) =>{ 
                    setIntermediateInfo({...intermediateInfo, introduction: event.target.value});
                    // console.log(event.target.value);
                  }}
                />
                <p style={styles.sectionInfo}>{`${intermediateInfo?.introduction ? intermediateInfo?.introduction.length : 0} / 500`}</p>
              </div>
              <div style={styles.sectionContainer}>
                <div style={styles.sectionContent}>
                  <SchoolIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-School')} :`}</p>
                </div>
                <TextField
                  sx={styles.sectionInput}
                  placeholder={t('Profile-SchoolPlaceHolder')} 
                  maxLength={50}
                  defaultValue={intermediateInfo?.school}
                  focusable
                  multiline
                  onChange={(event) =>{setIntermediateInfo({...intermediateInfo, school: event.target.value});}}
                />
                <p style={styles.sectionInfo}>{`${intermediateInfo?.school ? intermediateInfo?.school.length : 0} / 50`}</p>
              </div>
              <div style={styles.sectionContainer}>
                <div style={styles.sectionContent}>
                  <WorkIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-Job')} :`}</p>
                </div>
                <TextField
                  sx={styles.sectionInput}
                  placeholder={t('Profile-JobPlaceHolder')} 
                  maxLength={50}
                  defaultValue={intermediateInfo?.job}
                  focusable
                  multiline
                  onChange={(event) =>{setIntermediateInfo({...intermediateInfo, job: event.target.value});}}
                />
                <p style={styles.sectionInfo}>{`${intermediateInfo?.job ? intermediateInfo?.job.length : 0} / 50`}</p>
              </div>
              <div style={styles.sectionContainer}>
                <div style={styles.sectionContent}>
                <LocationCityIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-Company')} :`}</p>
                </div>
                <TextField
                  sx={styles.sectionInput}
                  placeholder={t('Profile-CompanyPlaceHolder')}
                  maxLength={50}
                  defaultValue={intermediateInfo?.company}
                  focusable
                  multiline
                  onChange={(event) =>{setIntermediateInfo({...intermediateInfo, company: event.target.value});}}
                />
                <p style={styles.sectionInfo}>{`${intermediateInfo?.company ? intermediateInfo?.company.length : 0} / 50`}</p>
              </div>
              <InfoModal
                visible={infoVisible} 
                changeVisible={setInfoVisible}
                renderdata={changeInfo} 
                updateData={intermediateInfo}
                setUpdateData={setIntermediateInfo}
                t={t}
              />
              <InterestsModal
                visible={interestsVisible} 
                changeVisible={setInterestsVisible}
                renderdata={changeInfo} 
                updateData={intermediateInfo}
                setUpdateData={setIntermediateInfo}
                t={t}
              />
              <ExtendTimeModal 
                visible={extendTimeVisible}
                changeVisible={setExpandTimeVisible}
                t={t}
              />
              <Button 
                style={{...styles.sectionContainer, ...styles.listContent, textTransform: 'none'}}
                onClick={() => {
                  setChangeInfo({title: 'Gender', dataType: 'gender', list: genderlist }); 
                  setInfoVisible(true)
                  }}>
                <div style={styles.sectionContent}>
                <WcIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-Gender')} :`}</p>
                </div>
                <p style={styles.sectionInfo}>
                  {`${intermediateInfo?.gender ? t(intermediateInfo?.gender) : t('empty')}`}
                </p>
              </Button>
              <Button 
                style={{...styles.sectionContainer, ...styles.listContent, textTransform: 'none'}}
                onClick={() => {setChangeInfo({title: 'Looking for', dataType: 'datePurpose', list: datingPurposeList }); setInfoVisible(true)}}>
                <div style={styles.sectionContent}>
                <WcIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-DatingPurpose')} :`}</p>
                </div>
                <p style={styles.sectionInfo}>
                  {`${intermediateInfo?.datePurpose ? t(intermediateInfo?.datePurpose) : t('empty')}`}
                </p>
              </Button>
              <Button 
                style={{...styles.sectionContainer, ...styles.listContent, textTransform: 'none'}}
                onClick={() => {setChangeInfo({title: 'Sexual Orientation', dataType: 'sexualOrientation', list: sexualOrientationlist }); setInfoVisible(true)}}>
                <div style={styles.sectionContent}>
                <WcIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-SexualOrientation')} :`}</p>
                </div>
                <p style={styles.sectionInfo}>
                  {`${intermediateInfo?.sexualOrientation ? t(intermediateInfo?.sexualOrientation) : t('empty')}`}
                </p>
              </Button>
              <Button 
                style={{...styles.sectionContainer, ...styles.listContent, textTransform: 'none'}}
                onClick={() => {setChangeInfo({title: 'Zodiac', dataType: 'zodiac', list: zodiaclist }); setInfoVisible(true)}}>
                <div style={styles.sectionContent}>
                  <NightsStayIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-Zodiac')} :`}</p>
                </div>
                <p style={styles.sectionInfo}>{`${intermediateInfo?.zodiac ? t(intermediateInfo?.zodiac) : t('empty')}`}</p>
              </Button>
              <Button 
                style={{...styles.sectionContainer, ...styles.listContent, textTransform: 'none'}}
                onClick={() => {setChangeInfo({title: 'Personality', dataType: 'personality', list: personalitylist }); setInfoVisible(true)}}>
                <div style={{...styles.sectionContent}}>
                  <ExtensionIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-Personality')} :`}</p>
                </div>
                <p 
                  style={styles.sectionInfo}
                >
                  {`${intermediateInfo?.personality ? t(intermediateInfo?.personality) : t('empty')}`}
                </p>
              </Button>
              <Button 
                style={{
                  ...styles.sectionContainer, 
                  ...styles.listContent, 
                  justifyContent: 'space-between',
                  textTransform: 'none'
                }}
                onClick={() => {setInterestsVisible(true)}}>
                <div style={styles.sectionContent}>
                  <SportsBasketballIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-Interests')} :`}</p>
                </div>
                <div style={styles.sectionInfoWrapper}>
                  <p style={styles.sectionInfo}>
                    {`${intermediateInfo?.interests || intermediateInfo?.interests?.length > 0
                      ? intermediateInfo?.interests.map(interest => t(interest)) 
                      : t('empty')}`}</p>
                </div>
              </Button>
              <div 
                style={{
                  ...styles.sectionContainer, 
                  ...styles.listContent, 
                  display: 'flex',
                  margin: 25
                }}
                >
                <div style={styles.sectionContent}>
                  <AccountCircleIcon sx={{ fontSize: '20px', color: '#1769aa' }} />
                  <p style={{...styles.sectionText, color: '#1769aa'}}>{`${t('Profile-Account')} :`}</p>
                </div>
                <p style={styles.sectionInfo}>{`${intermediateInfo?.account}`}</p>
              </div>
              <div 
                style={{
                  ...styles.sectionContainer, 
                  ...styles.listContent, 
                  display: 'flex',
                  margin: 25
                }}
                >
                <div style={styles.sectionContent}>
                  <BookOnlineIcon sx={{ fontSize: '20px', color: '#1769aa'}} />
                  <p style={{...styles.sectionText, color: '#1769aa'}}>{`${t('TicketTitle')} :`}</p>
                </div>
                <p style={styles.sectionInfo}>{`${intermediateInfo?.ticket}`}</p>
              </div>
              <div 
                style={{
                  ...styles.sectionContainer, 
                  ...styles.listContent, 
                  display: 'flex',
                  margin: 25
                }}
                >
                <div style={styles.sectionContent}>
                  <TranslateIcon sx={{ fontSize: '20px', color: '#1769aa'}} />
                  <p style={{...styles.sectionText, color: '#1769aa'}}>{`${t('Language')} :`}</p>
                </div>
                <TextField
                  select
                  size="medium"
                  SelectProps={{
                    native: true,
                    onChange: (e) => {
                      i18next.changeLanguage(e.target.value);
                      setCurrentLanguage(e.target.value);
                    },
                    value: currentLanguage,
                  }}
                  sx={{ mt: 1, width: 150 }}
                >
                  {languageList.map((language) => (
                    <option value={language.code} key={language.code}>
                      {t(language.name)}
                    </option>
                  ))}
                </TextField>
              </div>
              {/* <div 
                style={{...styles.sectionContainer, ...styles.sectionContainer}}
                >
                <div style={styles.sectionContent}>
                  <Icon type='font-awesome' name='clock-o' size={25} />
                  <p style={{...styles.sectionText, paddingLeft: 5}}>{`${t('Profile-Time')} :`}</p>
                </div>
                <p style={styles.sectionInfo}>{`${intermediateInfo?.huntingTime.valueOf()}`}</p>
              </div> */}
              <Button 
                style={{...styles.sectionContainer, justifyContent: 'space-between', textTransform: 'none'}}
                onClick={() => {setExpandTimeVisible(!extendTimeVisible)}}
              >
                <div style={styles.sectionContent}>
                  <AccessTimeIcon sx={{ fontSize: '20px' }} />
                  <p style={styles.sectionText}>{`${t('Profile-Time')} :`}</p>
                </div>
                <div style={styles.sectionInfoWrapper}>
                  <p style={styles.sectionInfo}>
                    {`${firestore.Timestamp.now().valueOf() < intermediateInfo?.huntingTime.valueOf() ? intermediateInfo?.huntingTime.toDate() : intermediateInfo?.huntingTime.toDate()}`}
                  </p>
                </div>
              </Button>
              {/* <div style={{paddingHorizontal: 40}}> */}
              <DeleteAccountModal
                visible={deleteAccountVisible}
                changeVisible={setDeleteAccountVisible}
                t={t}
                // id={id}
                // displayName={displayName}
              />
              <div style={{flexDirection: 'column', padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button
                  title={t('RestorePurchase')}
                  // titleStyle={styles.buttonTitle}
                  style={styles.logoutButton}
                  buttonStyle={{backgroundColor: '#3364c8'}}
                  raised
                  onClick={restorePurchase} 
                >
                  {t('RestorePurchase')}
                </Button>
                <Button
                  title={t('LogOutButton')}
                  // titleStyle={{fontSize: 18, fontWeight: 500}}
                  buttonStyle={{backgroundColor: '#3364c8'}}
                  style={styles.logoutButton}
                  raised
                  onClick={logout} 
                >
                  {t('LogOutButton')}
                </Button>
                <Button
                  title={t('DeleteAccountButton')}
                  // titleStyle={{fontSize: 18, fontWeight: 500}}
                  // type='outline'
                  color='error'
                  style={{...styles.logoutButton, backgroundColor: 'red'}}
                  // sx={{backgroundColor: 'red'}}
                  raised
                  onClick={() => {setDeleteAccountVisible(!deleteAccountVisible)}} 
                >
                  {t('DeleteAccountButton')}
                </Button>
              </div>
            </div>
            // </div>
              ) : (
            <div style={styles.cardContainer}>
              <div style={styles.card}>
                {/* <ImageList 
                  photolist={photoURL} 
                  style={styles.imageList} 
                  mode='prediv'
                  content={renderContent}
                  changeContent={setRenderContent}
                /> */}
                <ImageList
                  photolist={photoURL}
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 10,
                  }}
                  mode='swiper'
                  content={renderContent}
                  changeContent={setRenderContent}
                />
                <div
                  // Background Linear Gradient
                  // colors={['transparent','black', 'black']}
                  style={styles.background}
                >
                  <div style={styles.cardNameContainer}>
                    <p style={styles.cardName}>{displayName}</p>
                    <p style={styles.cardName}>{age}</p>
                    <CheckCircleIcon sx={{ fontSize: '22px', marginLeft: 2, color: 'lightgray' }} />
                  </div>
                  { renderContent ? (
                    <>
                      { intermediateInfo?.introduction ? 
                        <div style={{...styles.cardInfoWrapper, alignItems: 'flex-start'}}>
                          <Typography 
                            style={styles.cardInfoText}
                            >{`${intermediateInfo?.introduction}`}</Typography>
                        </div>
                      : null }
                      { intermediateInfo?.school ? 
                        <div style={styles.cardInfoWrapper}>
                          <SchoolIcon sx={{ fontSize: '20px', color: 'white' }} />
                          <Typography 
                            style={styles.cardInfoText}
                            >{`${intermediateInfo?.school}`}</Typography>
                        </div>
                      : null }
                      { intermediateInfo?.job ? 
                        <div style={styles.cardInfoWrapper}>
                          <WorkIcon sx={{ fontSize: '20px', color: 'white' }} />
                          <Typography 
                            style={styles.cardInfoText}
                            >{`${intermediateInfo?.job}`}</Typography>
                        </div>
                      : null }
                      { intermediateInfo?.company ? 
                        <div style={styles.cardInfoWrapper}>
                          <LocationCityIcon sx={{ fontSize: '20px', color: 'white' }} />
                          <Typography 
                            style={styles.cardInfoText}
                            >{`${intermediateInfo?.company}`}</Typography>
                        </div>
                      : null }
                    </>
                  ) : null }
                  { !renderContent ? (
                    <div style={styles.cardTagContainer}>
                      { intermediateInfo?.interests ? intermediateInfo?.interests.map((interest, index) => (
                        <div key={interest} style={styles.cardTag}>
                          <Typography 
                            style={{fontSize: 15, color: 'white'}}
                            >{`${t(interest)}`}</Typography>
                        </div>
                      )) : null }
                      { intermediateInfo?.zodiac ? 
                        <div style={styles.cardTag}>
                          <NightsStayIcon sx={{ fontSize: '20px', color: 'white' }} />
                          <Typography 
                            style={styles.cardInfoText}
                            >{`${t(intermediateInfo?.zodiac)}`}</Typography>
                        </div>
                      : null }
                      { intermediateInfo?.personality ? 
                        <div style={styles.cardTag}>
                          <ExtensionIcon sx={{ fontSize: '20px', color: 'white' }} />
                          <Typography 
                            style={styles.cardInfoText}
                            >{`${t(intermediateInfo?.personality)}`}</Typography>
                        </div>
                      : null }
                    </div>
                  ) : null}
                  <div style={styles.buttons}>
                    <HighlightOffOutlinedIcon sx={{ fontSize: '40px', color: 'red' }} />
                    <ReplayCircleFilledOutlinedIcon sx={{ fontSize: '40px', color: 'orange' }} />
                    <FavoriteIcon sx={{ fontSize: '40px', color: 'yellowgreen' }} />
                  </div>
                </div>
              </div>
            </div>
            )}
        </div>
      </Container>
    // </div>
  )
}

export default ProfileScreen

