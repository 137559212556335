// import { collection, doc, getDoc, onSnapshot, updateDoc, addDoc } from "firebase/firestore";
import { firestore } from '../firebase';
// import { RTCPeerConnection, RTCIceCandidate, RTCSessionDescription } from "react-native-webrtc";
  const configuration = {
    iceServers: [
      {
        urls: ["stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19302"],
      },
    ],
    iceCandidatePoolSize: 10,
  };
  /** @param {Firestore} db
   * Get local peer connection and get offer from firestore.
   * Creates an answer and sets it in the room collection.
   * Sets up listeners for the caller candidate collection
   * @param {string} theRoomId
   * @param {string} calleeId           // newAdd
   * @param {string} calleeName         // newAdd
   * @param {Array} calleePhotoURL      // newAdd
   * @param {MediaStream} localStream
   * @param {MediaStream} remoteStream
   * @param {string} roomCollectionName
   * @param {string} calleeCandidatesCollectionName
   * @param {string} callerCandidatesCollectionName
   * @param {React.Dispatch<React.SetStateAction<undefined>>} setRemoteStream
   * @returns {Promise<RTCPeerConnection>}
   */
  export const getLocalPCandGetOffer = async (
    // db,
    theRoomId,
    calleeId,         // newAdd
    calleeName,       // newAdd
    calleePhotoURL,   // newAdd
    localStream,
    remoteStream,
    roomCollectionName,
    calleeCandidatesCollectionName,
    callerCandidatesCollectionName,
    setRemoteStream
  ) => {
    // console.log(theRoomId/*, roomCollectionName, calleeCandidatesCollectionName, callerCandidatesCollectionName*/)
    // const roomRef = doc(collection(db, roomCollectionName), theRoomId);
    const roomRef = firestore().doc(`${roomCollectionName}/${theRoomId}`)
    try {
      // const roomSnapshot = await getDoc(roomRef);
      const roomSnapshot = await roomRef.get()
      // console.log(roomSnapshot.data())
  
      if (!roomSnapshot.exists) return;
      const localPC = new RTCPeerConnection(configuration);
  
      // localPC.addStream(localStream);
      localStream.getTracks().forEach(track => {
        localPC.addTrack(track, localStream);
      });
      // const calleeCandidatesCollection = collection(
      //   db,
      //   `${roomCollectionName}/${theRoomId}/${calleeCandidatesCollectionName}`
      // );
      const calleeCandidatesCollection = firestore().collection(
        `${roomCollectionName}/${theRoomId}/${calleeCandidatesCollectionName}`
      );
      localPC.onicecandidate = async (e) => {
        console.log(e)
        if (!e.candidate) {
          console.log("Join Call Got final candidate!");
          return;
        }
        // console.log(e)
        // await addDoc(calleeCandidatesCollection, e.candidate.toJSON());
        await calleeCandidatesCollection.add(e.candidate.toJSON());
        // calleeCandidatesCollection.add(e.candidate.toJSON());
      };
      // localPC.onaddstream = (e) => {
      //   if (e.stream && remoteStream !== e.stream) {
      //     console.log(
      //       "Callee RemotePC received the stream join # of tracks:",
      //       e.stream._tracks.length
      //     );
      //     setRemoteStream(e.stream);
      //   }
      // };

      // Replace onaddstream
      localPC.ontrack = (e) => {
        if (e.streams && e.streams[0] && remoteStream !== e.streams[0]) {
          console.log(
            "Callee RemotePC received the stream join # of tracks:",
            e.streams[0].getTracks().length
          );
          setRemoteStream(e.streams[0]);
        }
      };
      
      const offer = roomSnapshot.data()?.offer;
      await localPC.setRemoteDescription(new RTCSessionDescription(offer));
  
      const answer = await localPC.createAnswer();
      await localPC.setLocalDescription(answer);
  
      const roomWithAnswer = { answer, calleeId, calleeName, calleePhotoURL };
      // await updateDoc(roomRef, roomWithAnswer);
      await roomRef.update(roomWithAnswer)
      // const callerCollection = collection(
      //   db,
      //   `${roomCollectionName}/${theRoomId}/${callerCandidatesCollectionName}`
      // );

      const callerCollection = firestore().collection(
        `${roomCollectionName}/${theRoomId}/${callerCandidatesCollectionName}`
      );
  
      // onSnapshot(callerCollection, (snapshot) => {
      //   snapshot.docChanges().forEach(async (change) => {
      //     if (change.type === "added") {
      //       let data = change.doc.data();
      //       await localPC.addIceCandidate(new RTCIceCandidate(data));
      //     }
      //   });
      // });

      callerCollection.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          if (change.type === "added") {
            let data = change.doc.data();
            await localPC.addIceCandidate(new RTCIceCandidate(data));
          }
        });
      })
      return localPC;
    } catch (error) {
      console.log("getLocalPCandGetOffer error:", error);
    }
  };