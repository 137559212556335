import React, { useContext } from 'react'
import { styled, Typography, Box, Container, Button } from "@mui/material";
import { AuthContext } from '../../context/AuthProvider';

function FeaturesOne(props) {
  const { key, title, content, mode, onClick, image, link } = props;
  const { t } = useContext(AuthContext);
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(10),
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const CustomButton = ({
    backgroundColor,
    color,
    buttonText,
    heroBtn,
    guideBtn,
    getStartedBtn,
    onClick
  }) => {
    const CustomButton = styled(Button)(({ theme }) => ({
      backgroundColor: backgroundColor,
      color: color,
      fontWeight: "700",
      fontSize: "14px",
      cursor: "pointer",
      padding: "0.5rem 1.25rem",
      borderRadius: "7px",
      textTransform: "none",
      display: "block",
      border: "2px solid transparent",
      "&:hover": {
        backgroundColor: color,
        color: backgroundColor,
        borderColor: backgroundColor,
      },
      [theme.breakpoints.down("md")]: {
        margin: (heroBtn || getStartedBtn) && theme.spacing(0, "auto", 3, "auto"),
        width: (heroBtn || getStartedBtn) && "100%",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: guideBtn && theme.spacing(3),
        width: guideBtn && "100%",
        alignSelf: 'center'
      },
    }));
  
    return <CustomButton onClick={() => onClick()}>{buttonText}</CustomButton>;
  };

  const ImgContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  // const LargeText = styled(Typography)(({ theme }) => ({
  //   fontSize: "64px",
  //   color: "#000",
  //   fontWeight: "700",
  //   [theme.breakpoints.down("md")]: {
  //     fontSize: "32px",
  //   },
  // }));

  // const SmallText = styled(Typography)(({ theme }) => ({
  //   fontSize: "18px",
  //   color: "#7B8087",
  //   fontWeight: "500",
  //   [theme.breakpoints.down("md")]: {
  //     fontSize: "14px",
  //   },
  // }));

  // const TextFlexbox = styled(Box)(({ theme }) => ({
  //   marginTop: theme.spacing(7),
  //   display: "flex",
  //   justifyContent: "space-between",
  //   padding: theme.spacing(0, 5, 0, 5),
  //   [theme.breakpoints.down("sm")]: {
  //     flexDirection: "column",
  //     gap: theme.spacing(5),
  //   },
  // }));

  const Divider = styled("div")(({ theme }) => ({
    width: "13%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));
  return (
    <Box key={key ? key : title} sx={{ py: 10, width: '100%' }}>
      <Container>
        <div id={link}>
          <CustomBox sx={{ flexDirection: mode }}>
            <ImgContainer>
              <img src={image} alt="house" style={{ maxWidth: "100%" }} />
            </ImgContainer>

            <Box /*sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}*/>
              <Divider />
              <Typography
                sx={{
                  fontSize: "35px",
                  color: "#000339",
                  fontWeight: "700",
                  my: 3,
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#5A6473",
                  lineHeight: "27px",
                  my: 3,
                }}
              >
                {content}
              </Typography>
              { onClick && 
                <CustomButton
                  backgroundColor="#0F1B4C"
                  color="#fff"
                  buttonText={t('LearnMoreButton')}
                  guideBtn={true}
                  onClick={() => onClick()}
                />
              }
            </Box>
          </CustomBox>

          {/* <TextFlexbox>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText>2500+</LargeText>
              <SmallText>Homes For Sale</SmallText>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText>3000+</LargeText>
              <SmallText>Properties Rented</SmallText>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText>3500+</LargeText>
              <SmallText>Homes Sold</SmallText>
            </Box>
          </TextFlexbox> */}
        </div>
      </Container>
    </Box>
  )
}

export default FeaturesOne