const sortByInterests = (members, interests) => {
    if (!interests?.length || !members?.length) {
      return members;
    }
    return members.sort((a, b) => {
      // Get the interests arrays for object 'a' and 'b'
      const interestsA = a.interests || [];
      const interestsB = b.interests || [];
      
      // Get the number of similar interests for object 'a'
      const similarInterestsA = interestsA.filter(interest => interests.includes(interest)).length;
      
      // Get the number of similar interests for object 'b'
      const similarInterestsB = interestsB.filter(interest => interests.includes(interest)).length;
      
      // Sort in descending order based on the number of similar interests
      return similarInterestsB - similarInterestsA;
    });
  }
  

// Define the compatibility levels between each pair of personality types
  const compatibilityChart = {
    'INFP': {
      ideals: ['ENFJ', 'ENTJ'],
      compatible: ['INFP', 'ENFP', 'INFJ', 'INTJ', 'INTP', 'ENTP'],
      neutral: [],
      notIdeals: [],
      incompatible: ['ISFP', 'ESFP', 'ISTP', 'ESTP', 'ISFJ', 'ESFJ', 'ISTJ', 'ESTJ']
    },
    'ENFP': {
      ideals: ['INFJ', 'INTJ'],
      compatible: ['INFP', 'ENFP', 'ENFJ', 'ENTJ', 'INTP', 'ENTP'],
      neutral: [],
      notIdeals: [],
      incompatible: ['ISFP', 'ESFP', 'ISTP', 'ESTP', 'ISFJ', 'ESFJ', 'ISTJ', 'ESTJ']
    },
    'INFJ': {
      ideals: ['ENFP', 'ENTP'],
      compatible: ['INFP', 'INFJ', 'ENFJ', 'INTJ', 'ENTJ', 'INTP'],
      neutral: [],
      notIdeals: [],
      incompatible: ['ISFP', 'ESFP', 'ISTP', 'ESTP', 'ISFJ', 'ESFJ', 'ISTJ', 'ESTJ']
    },
    'ENFJ': {
      ideals: ['INFP', 'ISFP'],
      compatible: ['ENFP', 'INFJ', 'ENFJ', 'INTJ', 'ENTJ', 'INTP', 'ENTP'],
      neutral: [],
      notIdeals: [],
      incompatible: ['ESFP', 'ISTP', 'ESTP', 'ISFJ', 'ESFJ', 'ISTJ', 'ESTJ']
    },
    'INTJ': {
      ideals: ['ENFP', 'ENTP'],
      compatible: ['INFP', 'INFJ', 'ENFJ', 'INTJ', 'ENTJ', 'INTP'],
      neutral: ['ISFP', 'ESFP', 'ISTP', 'ESTP'],
      notIdeals: ['ISFJ', 'ESFJ', 'ISTJ', 'ESTJ'],
      incompatible: []
    },
    'ENTJ': {
      ideals: ['INFP', 'INTP'],
      compatible: ['ENFP', 'INFJ', 'ENFJ', 'INTJ', 'ENTJ', 'ENTP'],
      neutral: ['ISFP', 'ESFP', 'ISTP', 'ESTP', 'ISFJ', 'ESFJ', 'ISTJ', 'ESTJ'],
      notIdeals: [],
      incompatible: []
    },
    'INTP': {
      ideals: ['ENTJ', 'ESTJ'],
      compatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ', 'INTJ', 'INTP', 'ENTP'],
      neutral: ['ISFP', 'ESFP', 'ISTP', 'ESTP'],
      notIdeals: ['ISFJ', 'ESFJ', 'ISTJ'],
      incompatible: []
    },
    'ENTP': {
      ideals: ['INFJ', 'INTJ'],
      compatible: ['INFP', 'ENFP', 'ENFJ', 'ENTJ', 'INTP', 'ENTP'],
      neutral: ['ISFP', 'ESFP', 'ISTP', 'ESTP'],
      notIdeals: ['ISFJ', 'ESFJ', 'ISTJ', 'ESTJ'],
      incompatible: []
    },
    'ISFP': {
      ideals: ['ESFJ', 'ESTJ', 'ENFJ'],
      compatible: [],
      neutral: ['ISFJ', 'ISTJ', 'INTJ', 'ENTJ', 'INTP', 'ENTP'],
      notIdeals: ['ISFP', 'ESFP', 'ISTP', 'ESTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ']
    },
    'ESFP': {
      ideals: ['ISFJ', 'ISTJ'],
      compatible: [],
      neutral: ['ESFJ', 'ESTJ', 'INTJ', 'ENTJ', 'INTP', 'ENTP'],
      notIdeals: ['ISFP', 'ESFP', 'ISTP', 'ESTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ']
    },
    'ISTP': {
      ideals: ['ESFJ', 'ESTJ'],
      compatible: [],
      neutral: ['ISFJ', 'ISTJ', 'INTJ', 'ENTJ', 'INTP', 'ENTP'],
      notIdeals: ['ISFP', 'ESFP', 'ISTP', 'ESTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ']
    },
    'ESTP': {
      ideals: ['ISFJ', 'ISTJ'],
      compatible: [],
      neutral: ['ESFJ', 'ESTJ', 'INTJ', 'ENTJ', 'INTP', 'ENTP'],
      notIdeals: ['ISFP', 'ESFP', 'ISTP', 'ESTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ']
    },
    'ISFJ': {
      ideals: ['ESFP', 'ESTP'],
      compatible: ['ISFJ', 'ESFJ', 'ISTJ', 'ESTJ'],
      neutral: ['ENTJ', 'ISFP', 'ISTP'],
      notIdeals: ['INTJ', 'INTP', 'ENTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ']
    },
    'ESFJ': {
      ideals: ['ISFP', 'ISTP'],
      compatible: ['ISFJ', 'ESFJ', 'ISTJ', 'ESTJ'],
      neutral: ['ENTJ', 'ESFP', 'ESTP'],
      notIdeals: ['INTJ', 'INTP', 'ENTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ']
    },
    'ISTJ': {
      ideals: ['ESFP', 'ESTP'],
      compatible: ['ISFJ', 'ESFJ', 'ISTJ', 'ESTJ'],
      neutral: ['ENTJ', 'ISFP', 'ISTP'],
      notIdeals: ['INTJ', 'INTP', 'ENTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ']
    },
    'ESTJ': {
      ideals: ['ISFP', 'ISTP', 'INTP'],
      compatible: ['ISFJ', 'ESFJ', 'ISTJ', 'ESTJ'],
      neutral: ['ENTJ', 'ESFP', 'ESTP'],
      notIdeals: ['INTJ', 'ENTP'],
      incompatible: ['INFP', 'ENFP', 'INFJ', 'ENFJ']
    },
    // Define compatibility levels for other types...
  };
  
  // Function to determine the compatibility level between two personality types
  const getCompatibility = (personality1, personality2) => {
    const compatibility = compatibilityChart[personality1];
  
    if (compatibility) {
      if (compatibility.ideals.includes(personality2)) {
        return 5;
      } else if (compatibility.compatible.includes(personality2)) {
        return 4;
      } else if (compatibility.neutral.includes(personality2)) {
        return 3;
      } else if (compatibility.notIdeals.includes(personality2)) {
        return 2;
      } else if (compatibility.incompatible.includes(personality2)) {
        return 1;
      }
    }
  
    return 0; // If compatibility information is not available
  }

  const sortByPersonality = (members, personalityType) => {
    if (!personalityType || !members?.length) {
      return members;
    }
    return members.sort((a, b) => {
      const personalityA = a.personality;
      const personalityB = b.personality;
  
      if (!personalityA || !personalityB) {
        return 0; // Return 0 if personality attribute is missing
      }
  
      const compatibilityA = getCompatibility(personalityA, personalityType);
      const compatibilityB = getCompatibility(personalityB, personalityType);
  
      return compatibilityB - compatibilityA; // Sort in descending order of compatibility
    });
  }

  const sortByAttractiveness = (members) => {
    if (!members?.length) {
      return members;
    }
    // Helper function to get the elo score or consider it as lowest value if not present
    const getEloScore = (member) => member.elo ? member.elo : Number.MIN_SAFE_INTEGER;

    // Sort the members array based on elo scores in descending order
    members.sort((a, b) => getEloScore(b) - getEloScore(a));

    return members;
  }


export { sortByInterests, sortByPersonality, getCompatibility, sortByAttractiveness }