import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import { auth, firestore } from '../firebase';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import useEmailLinkEffect from '../hooks/useEmailLinkEffect';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [info, setInfo] = useState({});
  const [isloading, setIsloading] = useState(true);
  // const [troubleLogin, setTroubleLogin] = useState(false)
  // const [isLogin, setIsLogin] = useState(false);
  const { mailRef, error } = useEmailLinkEffect();
  const [firstLogin, setFirstLogin] = useState(false);
  const logInPath = ['/home', '/chat', '/event', '/beliked', '/call', '/receive', '/verify']
  const snapShotUnsubscribeRef = useRef();
  const loginRef = useRef(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [screen, setScreen] = useState('frontpage');
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  // console.log(mailRef.current);

  useEffect(() => {
    const unsubscribedAuth = auth().onAuthStateChanged(async (user) => {
      if (user && user.phoneNumber && !mailRef.current) {
        snapShotUnsubscribeRef.current?.();
        snapShotUnsubscribeRef.current = firestore().doc(`users/${user.uid}`).onSnapshot((snapshot) => {
          if (snapshot.exists) {
            const userData = { ...snapshot.data(), id: snapshot.id };
            const { coordinate, ...userWithoutCoordinate } = userData;
            setUser(userWithoutCoordinate);
            // setIsLogin(true);
            setIsloading(false);
            !logInPath.includes(window.location.pathname) ? navigate('home') : void 0;
          } else {
            // setIsLogin(false);
            setIsloading(false);
            setUser({});
            navigate("/")
          }
        });
      } else {
        snapShotUnsubscribeRef.current?.();
        // setIsLogin(false);
        setIsloading(false);
        setUser({});
        navigate("/")
      }
    });

    return () => {
      snapShotUnsubscribeRef.current?.();
      unsubscribedAuth();
    };
  }, [mailRef.current, firstLogin]);

  const memo = useMemo(() => ({
    currentLanguage,
    setCurrentLanguage,
    t,
    loginRef,
    mailRef,
    // isLogin,
    // setIsLogin,
    isloading,
    setIsloading,
    user,
    setUser,
    info,
    setInfo,
    // troubleLogin, 
    // setTroubleLogin,
    firstLogin,
    setFirstLogin,
    snapShotUnsubscribeRef,
    screen,
    setScreen
  }), [ currentLanguage, t/*, isLogin*/, isloading, user, info/*, troubleLogin*/, firstLogin, snapShotUnsubscribeRef, screen, loginRef, mailRef ]);
  
  // console.log(isLogin);

  return (
    <AuthContext.Provider value={memo}>
      {isloading ? (
        <div className="loadingContainer">
          {/* Add loading content */}
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
