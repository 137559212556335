import React, { useContext } from 'react'
import { Box, Button, styled, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { AuthContext } from '../../context/AuthProvider';
// import buyIcon from "../media/buy_icon.png";
// import sellIcon from "../media/sell_icon.png";
// import rentIcon from "../media/rent_icon.png";

// : "Pricing",
// : "We divide into two types of prices, the price for subscription users and the entry fee for participants in the instant dating area. See the details below.",
// : "Instant Date Area Fee",
// : "Learn about Instant Date Area Fee",
// : "Subscriptions",
// : "Learn about subscriptions",
// : "See Full Pricing",


function Pricing(props) {
  const { onButtonClick, link } = props;
  const { t } = useContext(AuthContext);
  const CustomBox = styled(Box)(({ theme }) => ({
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "85%",
    },
  }));

  // const CustomButton = ({
  //   backgroundColor,
  //   color,
  //   buttonText,
  //   heroBtn,
  //   guideBtn,
  //   getStartedBtn,
  // }) => {
    const CustomButton = styled(Button)(({ theme }) => ({
      backgroundColor: '#0F1B4C',
      color: "#fff",
      fontWeight: "700",
      fontSize: "14px",
      cursor: "pointer",
      padding: "0.5rem 1.25rem",
      borderRadius: "7px",
      textTransform: "none",
      display: "block",
      border: "2px solid transparent",
      "&:hover": {
        backgroundColor: "#fff",
        color: '#0F1B4C',
        borderColor: '#0F1B4C',
      },
      [theme.breakpoints.down("md")]: {
        margin: /*(heroBtn || getStartedBtn) &&*/ theme.spacing(0, "auto", 3, "auto"),
        width: /*(heroBtn || getStartedBtn) &&*/ "90%",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: /*guideBtn &&*/ theme.spacing(3),
        width: /*guideBtn &&*/ "90%",
      },
    }));
  
  //   return <CustomButton>{buttonText}</CustomButton>;
  // };
  

  const GuidesBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    width: "70%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
      flexDirection: "column",
    },
  }));

  const GuideBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  }));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        paddingBottom: 10
      }}
    >
      <div
        style={{
          width: "5%",
          height: "5px",
          backgroundColor: "#000339",
          margin: "0 auto",
        }}
      ></div>
      <div id={link}>
        <Typography
          variant="h3"
          sx={{ fontSize: "35px", fontWeight: "bold", color: "#000339", my: 3 }}
        >
          {t("Frontpage-Intro3Title")}
        </Typography>
      </div>
      <CustomBox>
        <Typography
          variant="body2"
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#5A6473",
            textAlign: "center",
          }}
        >
          {t("Frontpage-Intro3")}
        </Typography>
      </CustomBox>

      <GuidesBox>
        <GuideBox>
          {/* <img src={buyIcon} alt="buyIcon" /> */}
          <ReceiptIcon sx={{ fontSize: '40px', color: 'orange' }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#3B3c45",
              my: 1,
            }}
          >
            {t("Frontpage-Intro3.1Title")}
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              {t("Frontpage-Intro3.1")}
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} />
          </Box>
        </GuideBox>

        <GuideBox>
          {/* <img src={rentIcon} alt="buyIcon" /> */}
          <PaymentIcon sx={{ fontSize: '40px', color: 'orange' }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#3B3c45",
              my: 1,
            }}
          >
            {t("Frontpage-Intro3.2Title")}
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              {t("Frontpage-Intro3.2")}
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} />
          </Box>
        </GuideBox>

        {/* <GuideBox>
          <img src={sellIcon} alt="buyIcon" />
          <ReceiptIcon sx={{ fontSize: '40px', color: 'orange' }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#3B3c45",
              my: 1,
            }}
          >
            Selling Guides
          </Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              How to sell
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} />
          </Box>
        </GuideBox> */}
      </GuidesBox>

      <CustomButton
        onClick={onButtonClick}
      >
        {t("FullPriceButton")}
      </CustomButton>
      {/* <CustomButton
        backgroundColor="#0F1B4C"
        color="#fff"
        buttonText="See Full Pricing"
        guideBtn={true}
      /> */}
    </Box>
  )
}

export default Pricing