import React, { useEffect, useRef, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import VideoFeeds from "../components/CallComponents/VideoFeeds";
import CallButtons from "../components/CallComponents/CallButtons"; 
import ToggleButtons from "../components/CallComponents/ToggleButtons";
import { getLocalPCAndSetOffer } from "../utilities/getLocalPCAndSetOffer";
import { getLocalStream } from "../utilities/getLocalStream";
import { useContext } from "react";
import { SelectAreaContext } from "../context/SelectAreaProvider";
import { AuthContext } from "../context/AuthProvider";
import { firestore } from "../firebase";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const CallScreen = (/*{ navigation, route }*/) => {
  // const { matchDetails } = route.params;
  const navigate = useNavigate();
  const location = useLocation();
  const { matchDetails } = location.state;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { connecting } = useContext(SelectAreaContext);
  const [callEnable, setCallEnable] = useState(true);
  const [timeoutId, setTimeoutId] = useState();
  const {
    user: { id, displayName, photoURL },
  } = useContext(AuthContext);
  const [localStream, setLocalStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const [cachedLocalPC, setCachedLocalPC] = useState();
  const [startCleanUp, setStartCleanUp] = useState(false);

  const videoFeedsStyles = {
    rtcContainer: {
      justifyContent: 'center',
      alignItems: 'center', 
      [theme.breakpoints.up('lg')]: {
        paddingTop: 20
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      height: '100%',  // Occupy the entire available height
      width: '100%', 
    },
    rtcviewRemote: {
      padding: '10px',
      border: '2px solid black',
      margin: '10px',
      width: 'auto', // isMobile ? '100%' : 'auto',
      height: 'auto', // isMobile ? 'auto' : '100%',
      overflow: 'hidden', // Hide content that overflows the container
    },
    rtcviewLocal: {
      padding: '10px',
      border: '2px solid black',
      margin: '10px',
      width: 'auto', // isMobile ? '100%' : 'auto',
      height: 'auto', // isMobile ? 'auto' : '100%',
      overflow: 'hidden', // Hide content that overflows the container
    },
    reactPlayer: {
      width: '100%',
      height: '100%',
    },
  };
  
  const callButtonsStyles = {
    container: {
      // container for the buttons to start/join a call
      padding: 20,
      marginBottom: 100,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      justifyContent: 'space-evenly',
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
    },
    buttons: {
      backgroundColor: '#4a4a4a',
      padding: 15,
      borderRadius: 50,
      justifyContent: 'center'
    },
  };
  
  const toggleButtonStyles = {
    container: {
      // container for the buttons to start/join a call
      padding: 10,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      justifyContent: 'space-evenly',
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      
    },
    buttons: {
      backgroundColor: '#4a4a4a',
      padding: 15,
      borderRadius: 50,
    },
  };

  const onBackPress = async () => {
    // Clear the local stream and cachedLocalPC
    // await streamCleanUp();
    // await firestoreCleanUp();
    await Promise.all([streamCleanUp(), firestoreCleanUp()]);
    connecting.current = false;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // window.location.reload();
    // navigate('/chat')
  };

  const streamCleanUp = async () => {
    if (cachedLocalPC) {
      // cachedLocalPC.removeStream(localStream);
      cachedLocalPC.getSenders().forEach((sender) => {
        cachedLocalPC.removeTrack(sender);
      });
      cachedLocalPC.close();
    }
    if (localStream) {
      localStream.getVideoTracks().forEach((track) => track.stop());
      localStream.getAudioTracks().forEach((track) => track.stop());
      localStream.getTracks().forEach((track) => track.stop());
    }
    setLocalStream();
    setRemoteStream();
    setCachedLocalPC();
  };

  const firestoreCleanUp = async () => {
    try {
      const roomRef = firestore().doc(`rooms/${matchDetails?.id}`);
      if (roomRef) {
        const calleeCandidates = await roomRef.collection("calleeCandidates").get();
        calleeCandidates.forEach(async (candidate) => {
          await candidate.ref.delete();
        });
        const callerCandidates = await roomRef.collection("callerCandidates").get();
        callerCandidates.forEach(async (candidate) => {
          await candidate.ref.delete();
        });
        await roomRef.delete();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startLocalStream = async () => {
    const newStream = await getLocalStream();
    setLocalStream(newStream);
    // localStreamRef.current.srcObject = newStream;
  };

  const startCall = async () => {
    connecting.current = true;
    const localPC = await getLocalPCAndSetOffer(
      matchDetails?.id,
      id,
      displayName,
      photoURL,
      localStream,
      remoteStream,
      "rooms",
      "calleeCandidates",
      "callerCandidates",
      setRemoteStream
    );
    setCachedLocalPC(localPC);
    const timeOut = setTimeout(() => {
      onBackPress();
    }, 30000);
    setTimeoutId(timeOut);
  };

  const handleCleanUp = () => {
    onBackPress();
  }

  useEffect(() => {
    startLocalStream();
  }, []);

  useEffect(() => {
    if (!remoteStream) return;
    clearTimeout(timeoutId);
  }, [remoteStream]);

  useEffect(() => {
    const unsubscribe = firestore().collection(`rooms/${matchDetails.id}/calleeCandidates`)
      .onSnapshot(async (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          if (change.type === 'removed') {
            setStartCleanUp(true)
            // console.log(change.type)
            // onBackPress();
            // window.location.reload();
            // handleCleanUp();
          }
        });
      });

    return unsubscribe;

  }, []);


  useEffect(() => {
    if (startCleanUp) {
      onBackPress().then(() => /*navigate('/chat')*/ window.location.reload()).catch(error => console.error(error));
    }

  }, [startCleanUp])


  return (
    <Grid 
      container 
      alignItems="center" 
      sx={{
        backgroundColor: 'whitesmoke',
      }}>
      <VideoFeeds
        remoteStream={remoteStream}
        localStream={localStream}
        videoFeedsStyles={videoFeedsStyles}
      />
      {localStream ? (
        callEnable ? (
          <CallButtons
            localStream={localStream}
            startCall={startCall}
            callEnable={callEnable}
            setCallEnable={setCallEnable}
            callButtonsStyles={callButtonsStyles}
            cancel={onBackPress}
          />
        ) : (
          <ToggleButtons
            remoteStream={remoteStream}
            localStream={localStream}
            cancel={onBackPress}
            toggleButtonStyles={toggleButtonStyles}
          />
        )
      ) : null}
    </Grid>
  );
};

export default CallScreen;

