import React, { useContext, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import {
  Paper, 
  Box,
  Grid,
  BottomNavigation, 
  BottomNavigationAction, 
  useMediaQuery, 
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
} from '@mui/material';
import HomeScreen from '../screens/HomeScreen';
import ProfileScreen from '../screens/ProfileScreen';
import FilterScreen from '../screens/FilterScreen';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
// import AdbIcon from '@mui/icons-material/Adb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { GiLightningBow } from "react-icons/gi";


const pages = [
  // 'home', 'event', 'chat',
  'Home-TabTitle', 'Event-TabTitle', 'Chat-TabTitle'
];

function HomeRoute() {
  const [screen, setScreen] = useState('home')
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('Home-TabTitle');


  const handleNavBarChange = (newValue) => {
    setSelectedTab(newValue);
    if (newValue === 'Chat-TabTitle') {
      navigate('/chat')
    } else if (newValue === 'Event-TabTitle') {
      navigate('/event')
    } else {
      navigate('/home');
    }
    // console.log(newValue);
    // navigate(`/${newValue}`);
  }

  const handleTabChange = (event, newValue) => {
    // console.log(event, newValue)
    setSelectedTab(newValue);
    if (newValue === 'Chat-TabTitle') {
      navigate('/chat')
    } else if (newValue === 'Event-TabTitle') {
      navigate('/event')
    } else {
      navigate('/home');
    }
    // console.log(newValue);
    // navigate(`/${newValue}`);
    // setSelectedTab(event.currentTarget);
  };

  const renderLeftGrid = () => {
    switch (screen) {
      case 'filter':
        return <FilterScreen navigation={setScreen} />;
      default:
        return <ProfileScreen navigation={setScreen} />;
    }
  }

  const renderRightGrid = () => {
    switch (screen) {
      case 'filter':
        return <FilterScreen navigation={setScreen}/>;
      case 'profile':
        return <ProfileScreen navigation={setScreen} />;
      default:
        return <HomeScreen navigation={setScreen} />;
    }
  }

  // const renderContent = () => {
  //   switch (/*selectedTab*/ screen) {
  //     case 'home':
  //       return <HomeRoute />;
  //     case 'event':
  //       return <EventScreen />;
  //     case 'chat':
  //       return <ChatRoute />;
  //     default:
  //       return <HomeScreen />;
  //   }
  // };
  // console.log(screen);
  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="static" sx={{display: { xs: 'none', md: 'flex' }}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <GiLightningBow style={{ fontSize: '30px', marginRight: 10, transform: 'rotate(270deg)' }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              // href="#app-bar-with-responsive-menu"
              onClick={() => navigate('/home')}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                cursor: 'pointer',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {'HUNTER'}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleNavBarChange(page)}
                  sx={{ 
                    my: 2, 
                    color: 'white', 
                    display: 'block', 
                    borderBottom: `2px solid ${selectedTab === page ? 'white' : 'transparent'}`, 
                    borderRadius: 0,
                    // writingMode: 'horizontal-tb'
                  }}
                >
                  {t(page)}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Grid container spacing={0}>
        <Grid item lg={4}>
          { !isMobile ? renderLeftGrid() : null}
        </Grid>
        <Grid item xs sm={12} lg={8}>
          { isMobile ? renderRightGrid() : <HomeScreen navigation={setScreen} />}
        </Grid>
      </Grid>
      <Paper 
        sx={{ 
          position: 'fixed', 
          bottom: 0, 
          left: 0, 
          right: 0,
          justifyContent: 'center',
          // width: isMobile ? '100%' : '50%', // Adjust the width based on the screen size
          // alignSelf: isMobile ? 'center' : 'flex-end',
          display: { xs: screen === 'home' ? '100%' : 'none', md: 'none' },
          zIndex: 1,
        }} 
        elevation={3}>
        <BottomNavigation
          showLabels 
          value={selectedTab /*screen*/} 
          onChange={handleTabChange}
        >
          <BottomNavigationAction label={t('Home-TabTitle')} value={'Home-TabTitle'} icon={<HomeIcon />} />
          <BottomNavigationAction label={t('Event-TabTitle')} value={'Event-TabTitle'} icon={<MapIcon />} />
          <BottomNavigationAction label={t('Chat-TabTitle')} value={'Chat-TabTitle'} icon={<QuestionAnswerIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  )
}

export default HomeRoute