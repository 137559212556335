import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  CssBaseline,
  Box,
  InputAdornment,
  IconButton,
  createTheme,
  ThemeProvider
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { auth } from '../firebase'; // You need to replace this with the appropriate Firebase library for React.js
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'

const defaultTheme = createTheme();

const VerifyEmailScreen = ({ navigation, isLogin }) => {
  const { t } = useContext(AuthContext);
  const mailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');
  // const navigate = useNavigate();
  // const { isLogin, error } = useEmailLinkEffect();

  useEffect(() => {
    if (isLogin /*&& step === 2*/) {
      // setStep(step + 1);
      setStep(3);
    }
  }, [isLogin]);

  
  const handleSubmit = (e) => {
    e.preventDefault();
    // setLoginLoading(true);
    const actionCodeSettings = {
      // this is the URL that we will redirect back to after clicking on the link in mailbox
      // url: `http://localhost:3000/?email=${email}`, `https://${process.env.REACT_APP_FIREBASE_PROJECTID}.firebaseapp.com` ||
      // url: `http://localhost:3000`,
      url: `${process.env.REACT_APP_DOMAIN}`,
      handleCodeInApp: true,
    }
    auth()
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      console.log('send link successful');
      window.localStorage.setItem('email', email);
      if (step === 1) {
        setStep(step + 1);
      }
      // setStep(step + 1);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    setStep(0);
    navigation('frontpage')
  };

  return (
    <div 
    style={{ 
      flex: 1, 
      // padding: 20,
      backgroundImage: `url(${loginImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: (t) =>
        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper elevation={3} sx={{ height: '100vh' }}>
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>
              <IconButton 
                sx={{ position: 'relative', flexDirection: 'row-reverse' }} 
                onClick={navigateToHome}
              >
                <CloseIcon />
              </IconButton>
              {step === 0 ? (
                <Box 
                sx={{ height: '100vh', justifyContent: 'space-evenly', alignItems: 'center', /*marginTop: '100px',*/ backgroundColor: 'white', borderRadius: '10px', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                  <Typography 
                    variant="h5" 
                    sx={{ fontSize: '30px', textAlign: 'justify', fontWeight: '500', textDecoration: 'underline' }}
                  >
                    {t('VerifyEmail-Title') /*'Title'*/}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    sx={{ fontSize: '22px', color: '#6666ff', lineHeight: '35px', textAlign: 'center', padding: '10px' }}
                  >
                    {t('VerifyEmail-Intro') /*'Intro'*/}
                  </Typography>
                  <Button 
                    variant="contained" 
                    sx={{ 
                      backgroundColor: 'rgba(90, 154, 230, 1)', 
                      borderColor: 'transparent', 
                      borderWidth: '0', 
                      borderRadius: '30px', 
                      marginTop: '30px', 
                      width: '300px' 
                    }} 
                    onClick={() => setStep(step + 1)}>
                    {t('EmailLoginButton') /*'Login With Email'*/}
                  </Button>
                </Box>
              ) : null}

              {step === 1 ? (
                <Box 
                  sx={{ 
                    // marginTop: '20px', 
                    height: '90vh',
                    padding: '20px', 
                    backgroundColor: 'white', 
                    borderRadius: '10px', 
                    textAlign: 'center',
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography variant="h5" sx={{ fontSize: '30px', textAlign: 'center', fontWeight: '500' }}>
                    {t('AddEmail-Title') /*'AddEmail'*/}
                  </Typography>
                  <Box>
                    <TextField
                      sx={{ marginTop: '20px' }}
                      fullWidth
                      id="email"
                      name="email"
                      label="Email: yourmail@example.com"
                      autoFocus
                      autoComplete="email"
                      variant="outlined"
                      color="primary"
                      onChange={(e)=>setEmail(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <i className="fa fa-envelope" style={{ color: '#b3b3ff' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="body1" sx={{ fontSize: '18px', color: 'black', marginTop: '10px', textAlign: 'justify' }}>
                      {t('VerifyEmail-Intro2')}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', padding: '15px' }}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: 'rgba(90, 154, 230, 1)', borderColor: 'transparent', borderWidth: '0', borderRadius: '30px', marginTop: '30px', width: '300px' }}
                      onClick={handleSubmit}
                      disabled={!mailreg.test(email)}
                    >
                      {t('SendLinkButton')}
                    </Button>
                  </Box>
                </Box>
              ) : null}

              {step === 2 ? (
                <Box sx={{ height: '90vh', justifyContent: 'space-evenly', alignItems: 'center', /*marginTop: '100px',*/ backgroundColor: 'white', borderRadius: '10px', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h5" sx={{ fontSize: '30px', textAlign: 'justify', fontWeight: '500', textDecoration: 'underline' }}>
                    {t('VerifyEmail-Title3') /*'Title3'*/}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '22px', color: 'grey', lineHeight: '35px', textAlign: 'center' }}>
                    {t('VerifyEmail-Intro3') /*'Intro3'*/}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '22px', color: 'grey', textAlign: 'center' }}>
                    {t('VerifyEmail-Intro4') /*'Intro4'*/}
                  </Typography>
                  <Button 
                    variant="contained" 
                    sx={{ 
                      backgroundColor: 'rgba(90, 154, 230, 1)', 
                      borderColor: 'transparent', 
                      borderWidth: '0', 
                      borderRadius: '30px', 
                      marginTop: '30px', 
                      width: '300px' 
                    }} 
                    // onClick={formik.handleSubmit}
                    onClick={handleSubmit}
                  >
                    {t('ResendButton') /*'Resend'*/}
                  </Button>
                </Box>
              ) : null}

              {step === 3 ? (
                <Box sx={{ height: '90vh', justifyContent: 'space-evenly', alignItems: 'center',/* marginTop: '100px',*/ backgroundColor: 'white', borderRadius: '10px', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                  <Typography 
                    variant="h5" 
                    sx={{ fontSize: '30px', textAlign: 'justify', fontWeight: '500', textDecoration: 'underline' }}
                  >
                    {t('VerifyEmail-Title4') /*'Title4'*/}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '22px', color: 'grey', lineHeight: '35px', textAlign: 'center' }}>
                    {t('VerifyEmail-Intro5') /*'Intro5'*/}
                  </Typography>
                  <Button 
                    variant="contained" 
                    sx={{ 
                      backgroundColor: 'rgba(90, 154, 230, 1)', 
                      borderColor: 'transparent', 
                      borderWidth: '0', 
                      borderRadius: '30px', 
                      marginTop: '30px', 
                      width: '300px' 
                      }} 
                    // onClick={() => navigate('/PhoneNumber')}
                    onClick={() => navigation('phone')}
                  >
                    {t('VerifyButton') /*'Verify'*/}
                  </Button>
                </Box>
              ) : null}
            </div>
          </Paper>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default VerifyEmailScreen;
