import React, { useContext, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  Typography,
  Icon,
  Box,
  Modal
} from "@mui/material";
import { SelectAreaContext } from "../context/SelectAreaProvider";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { firestore } from "../firebase"; // Replace this import with your Firebase setup

const EnterAreaScreen = ({ specialZone, visible, changeVisible }) => {
  // const { specialZone } = route.params;
  const [content, switchContent] = useState(true);
  const {
    user: { id, ticket, huntingTime }, t
  } = useContext(AuthContext);
  const { 
    setSelectedAreaId, openSubscription, setOpenSubscription, openTicket, setOpenTicket 
  } = useContext(SelectAreaContext);
  const navigate = useNavigate();
  const enterSpecialZoneText = t("SpecialZoneIntro1");
  const howItWorksText = t("SpecialZoneIntro2");

  const handleUseTicket = async () => {
    if (firestore.Timestamp.now().valueOf() > huntingTime.valueOf()) {
      // Replace with your Firebase Firestore update logic
      await firestore().collection("users").doc(id).update({
        accessAreas: firestore.FieldValue.arrayUnion(specialZone?.id),
        ticket: ticket - 1,
        huntingTime: firestore.Timestamp.fromMillis((firestore.Timestamp.now().toMillis() + (24 * 60 * 60 * 1000))),
      });
      setSelectedAreaId(specialZone?.id);
    } else {
      // Replace with your Firebase Firestore update logic
      await firestore().collection("users").doc(id).update({
        accessAreas: firestore.FieldValue.arrayUnion(specialZone?.id),
        ticket: ticket - 1,
        huntingTime: firestore.Timestamp.fromMillis((huntingTime.toMillis() + (24 * 60 * 60 * 1000))),
      });
      setSelectedAreaId(specialZone.id);
    }
    switchContent(true);
    changeVisible(!visible);
    // navigate(-1); // Go back to the previous route
  };

  return (
    <Modal
      sx={{
        backgroundColor: 'rgba(0.5, 0.25, 0, 0.5)', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      // maxWidth={'md'}
      open={visible}
      onClose={() => {
        changeVisible(!visible);
      }}
      backdropClickThrough={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth="sm"
        style={{
          background: "linear-gradient(to bottom, white, white, #ff0066)",
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          borderRadius: 20,
        }}
      >
        <CssBaseline />
          <Box justifyContent="center" alignItems="center" display={'flex'} flexDirection={'column'}>
            <Typography variant="h6" style={{ fontSize: 25, fontWeight: "bold", padding: 10 }}>
              {content ? t("SpecialZoneTitle1") : t("SpecialZoneTitle2")}
            </Typography>
            <Icon type="font-awesome" name="ticket" size={35} sx={{ color: "#ff0066" }} />
            <Typography
              variant="body2"
              style={{
                fontSize: 14,
                padding: 8,
                textAlign: "justify",
                lineHeight: 2,
              }}
            >
              {content ? enterSpecialZoneText : howItWorksText}
            </Typography>
            <Box width="100%" justifyContent={'center'} display={'flex'} flexDirection={'column'}>
              {content ? (
                <Button
                  variant="contained"
                  sx={{
                    borderColor: "transparent",
                    borderRadius: 30,
                    marginTop: 2,
                    // backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  onClick={() => switchContent(!content)}
                >
                  {t("ContinueButton")}
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      borderColor: "transparent",
                      borderRadius: 30,
                      marginTop: 2,
                      backgroundColor: "#33cc33",
                      color: "black",
                    }}
                    onClick={() => {
                      switchContent(true);
                      // navigate(-1); // Go back to the previous route
                      changeVisible(!visible);
                      setOpenTicket(!openTicket);
                      // navigate("Ticket");
                    }}
                  >
                    {t("GetTicketButton")}
                  </Button>
                  <Typography
                    variant="body2"
                    style={{
                      alignSelf: "center",
                      padding: 1,
                    }}
                  >
                    {t("SpecialZoneIntro3")}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      borderColor: "transparent",
                      borderRadius: 30,
                      marginTop: 2,
                      backgroundColor: "#cc33ff",
                      color: "black",
                    }}
                    onClick={() => {
                      switchContent(true);
                      // navigate(-1); // Go back to the previous route
                      changeVisible(!visible);
                      setOpenSubscription(!openSubscription);
                      // navigate("Subscription");
                    }}
                  >
                    {t("GetSubsciptionButton")}
                  </Button>
                  <Typography
                    variant="body2"
                    style={{
                      alignSelf: "center",
                      padding: 1,
                    }}
                  >
                    {t("SpecialZoneIntro4")}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      borderColor: "transparent",
                      borderRadius: 30,
                      marginTop: 2,
                    }}
                    onClick={handleUseTicket}
                    disabled={ticket === 0}
                  >
                    {`${t("UseTicketButton")} (${ticket})`}
                  </Button>
                  <Typography
                    variant="body2"
                    style={{
                      alignSelf: "center",
                      padding: 1,
                    }}
                  >
                    {t("SpecialZoneIntro3")}
                  </Typography>
                </>
              )}
              <Button
                variant="outlined"
                sx={{
                  // backgroundColor: "white",
                  borderColor: "transparent",
                  borderWidth: 1,
                  borderRadius: 30,
                  marginTop: 2,
                  color: "black",
                }}
                onClick={() => {
                  switchContent(true);
                  changeVisible(!visible);
                  // navigate(-1); // Go back to the previous route
                }}
              >
                {t("MaybeLaterButton")}
              </Button>
            </Box>
          </Box>
      </Container>
    </Modal>
  );
};

export default EnterAreaScreen;
