import firebase from "firebase/compat/app";
import 'firebase/compat/analytics';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY, 
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN, 
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL, 
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID, 
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET, 
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID, 
  appId: process.env.REACT_APP_FIREBASE_APPID, 
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = firebase.initializeApp(firebaseConfig);
firebase.analytics();

const firestore = firebase.firestore;
const auth = firebase.auth;
const database = firebase.database;
const storage = firebase.storage;

if (window.location.hostname === 'localhost') {
  // auth.useEmulator('http://localhost:9099');
  // db.useEmulator('localhost', '8080');
}

export { app, firestore, auth, database, storage };
export default firebase;