  const filterId = (members, listId) => {
      return members.filter(member => !listId.includes(member.id))
  };
  
  const filterGender = (members, showGender) => {
    if (showGender === 'Everyone') {
      return members
    } else {
      return members.filter(member => member.gender === showGender)
    }
  };

  const filterAgeRange = (members, ageRange) => {
    return members.filter(member => ageRange[0] <= member.age <= ageRange[1])
  };

  const filterOnlySeeVerified = (members, onlySeeVerified) => {
    if (onlySeeVerified) {
      return members.filter(member => member.verifiedStatus === 'verified')
    } else {
      return members
    }
  };

  const filterVerifiedSeeOnly = (members, verifiedStatus) => {
    return members.filter(member => {
        if (member.verifiedSeeOnly) {
            if (verifiedStatus === 'verified') {
                return member
            }
            return;
        }
        return member;
    })
  };

  export { filterId, filterGender, filterAgeRange, filterOnlySeeVerified, filterVerifiedSeeOnly }