import React from 'react';

// Get the window dimensions using window.innerWidth and window.innerHeight
export const width = window.innerWidth;
export const height = window.innerHeight;

// Define the SPACING constant
export const SPACING = 10;


