import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  Modal,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import { AuthContext } from '../../context/AuthProvider';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Camera } from 'react-html5-camera-photo'; // Install this package for HTML5 camera support
import 'react-html5-camera-photo/build/css/index.css';

const TakePictureModal = ({ visible, changeVisible, handleImage, example }) => {
  const { t } = useContext(AuthContext);
  // const [facePhotos, setFacePhotos] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // Copy from AddPhotoScreen
  const cameraRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [stream, setStream] = useState();
  const [startCamera, setStartCamera] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [cameraType, setCameraType] = useState('front');
  // const [flashMode, setFlashMode] = useState('off');
  // const [uploadedImage, setUploadedImage] = useState([]);
  const styles = {
    container: {
      flex: 1,
      // backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      // position: 'relative',
      // maxWidth: '500px'
    },
    closeButton: {
      position: 'absolute',
      top: '10px', /* Adjust the top and right values as needed */
      right: '10px',
      padding: '10px',
      zIndex: 2,
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      cursor: 'pointer',
      fontSize: '25px'
    },
    large_frame: {
      width: '90%',
      height: 310,
      borderWidth: 1,
      borderRadius:10,
      borderColor: 'grey',
      backgroundColor: '#d3d3d3'
    },
    small_picture: {
      width: 120,
      height: 200,
      borderRadius: 10,
      // padding: 5
      },
    small_frame: {
      width: 180,
      height: 300,
      borderWidth: 1,
      borderRadius:10,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'grey',
      backgroundColor: '#d3d3d3'
    },
    enablebutton: {
      width: 250,
      // backgroundColor: '#14274e',
      // backgroundColor: 'rgba(90, 154, 230, 1)',
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30,
      marginTop: 10,
    },
    buttontitle: {
      fontSize: 20,
      color: 'white'
    },
    disablebutton: {
      backgroundColor: 'rgba(90, 154, 230, 1)',
      width: 50,
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 5
    },
    disabletitle: {
      fontSize: 10,
      color: 'white'
    },
    cancelbutton: {
      width: 250,
      backgroundColor: 'white',
      // borderColor: 'transparent',
      borderWidth: 1,
      borderRadius: 30,
      marginTop: 10
    },
    cancelbuttontitle: {
      fontSize: 20,
      color: 'black'
    },
    cameraPreviewPaper: {
      width: '80%',
      height: '80%',  // Use 'vh' for viewport height or 'px' for pixels
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2), // You can adjust the padding as needed
      backgroundColor: 'white', // Change this to your desired background color
      borderRadius: 10, // Adjust the border radius as needed
      boxShadow: theme.shadows[3], // Add shadow to the paper
    },
    cameraPreviewText: {

    }
  };

  const startCameraHandler = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    // console.log(stream);

    if (stream ) {
      setStartCamera(true);
      setStream(stream);
    } else {
      alert(t('Verify-Permission') /*"Verify-Permission"*/);
    }
  };

  useEffect(() => {
    if (!visible) return;
    startCameraHandler().catch(error => console.error(error))
  }, [visible])

  const takePictureHandler = async (dataUri) => {
    setPreviewVisible(true);
    if (example && !isMobile) {
    // Create an image object and handle its loading
      const img = new Image();
      img.onload = async () => {
        // Calculate the crop dimensions based on the height
        const aspectRatio = 10 / 16;
        const imgWidth = img.width;
        const imgHeight = img.height;
        const cropHeight = imgHeight;
        const cropWidth = imgHeight * aspectRatio;

        // Create a canvas to perform cropping
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = cropWidth;
        canvas.height = cropHeight;

        // Crop the image
        context.drawImage(img, (imgWidth - cropWidth) / 2, 0, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

        // Apply contrast enhancement
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        // applyHistogramEqualization(imageData);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          // Increase the contrast by boosting or reducing RGB values
          // Adjust the following coefficients as needed for your enhancement
          data[i] *= 1.2; // Red channel
          data[i + 1] *= 1.2; // Green channel
          data[i + 2] *= 1.2; // Blue channel
        }

        context.putImageData(imageData, 0, 0);

        // Convert the cropped data URI to a Blob
        const croppedDataUri = canvas.toDataURL('image/jpeg');
        setCapturedImage({ uri: croppedDataUri });
      };

      img.src = dataUri; // Start loading the image

      img.onerror = () => {
        console.error('Error loading image:', dataUri);
      };
      // console.log(croppedDataUri)
    } else {
      setCapturedImage({ uri: dataUri });
    }
    stopCamera();
  };

  const handleCapture = (dataUri) => {
    // Convert the data URI to a Blob
    const byteString = atob(dataUri.split(',')[1]);
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const savePhotoHandler = async () => {
    try {
      const blob = handleCapture(capturedImage.uri)
      await handleImage(blob)
      setPreviewVisible(false);
      setStartCamera(false);
      changeVisible(!visible);
    } catch (error) {
      console.error(error);
      alert(t('Verify-Error') /*"Verify-Error"*/);
    } finally {
      setLoading(false);
    }
  };
  

  const retakePictureHandler = () => {
    setCapturedImage(null);
    setPreviewVisible(false);
    startCameraHandler();
  };

  // const handleFlashMode = () => {
  //   if (flashMode === 'on') {
  //     setFlashMode('off');
  //   } else if (flashMode === 'off') {
  //     setFlashMode('on');
  //   } else {
  //     setFlashMode('auto');
  //   }
  // };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop()); // Call the stop() method on the cameraRef
    }
    cameraRef.current = null;
  };

  const closeModalHandler = () => {
    setCapturedImage(null);
    setPreviewVisible(false);
    stopCamera();
    changeVisible(!visible);
  }


  return (
    <Modal
      style={styles.container}
      maxWidth={'md'}
      open={visible}
      onClose={() => {
        stopCamera();
        changeVisible(!visible)
      }}
      backdropClickThrough={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {startCamera ? (
        <div className="camera-container">
          {previewVisible && capturedImage ? (
            example ? (
              <VerifyPreview
                photo={capturedImage}
                example={example}
                savePhoto={savePhotoHandler}
                retakePicture={retakePictureHandler}
                closeModal={closeModalHandler}
                loading={loading}
                counter={count}
                t={t}
                styles={styles}
              />
            ) : (
              <CameraPreview
                photo={capturedImage}
                // example={facePhotos[count]}
                savePhoto={savePhotoHandler}
                retakePicture={retakePictureHandler}
                closeModal={closeModalHandler}
                loading={loading}
                counter={count}
                t={t}
                styles={styles}
              />
            )
          ) : (
            <>
              { example ? (
                <img
                  alt='Screen Capture'
                  src={example.uri} 
                  style={{...styles.closeButton, height: '30vh'}} 
                />
              ) : (
                <IconButton
                  style={styles.closeButton}
                  size='large'
                  onClick={() => {
                    stopCamera();
                    changeVisible(!visible)
                  }}>
                  <CloseIcon color='white' />
                </IconButton>
              )}
              <Camera
                idealFacingMode={cameraType === 'front' ? 'user' : 'environment'}
                // idealResolution={{ width: 768, height: 1024 }}
                onTakePhoto={(dataUri) => takePictureHandler(dataUri)}
                // isImageMirror={false}
                imageCompression={1}
                isMaxResolution={false}
                isSilentMode={false}
                isDisplayStartCameraError={true}
                isFullscreen={isMobile ? true : false}
                sizeFactor={1}
                onCameraStart={() => {}}
                onCameraStop={() => {}}
                onCameraError={(error) => console.error('Camera error:', error)}
                ref={cameraRef}
                imageType='jpg'
              />
            </>
          )}
        </div>
      ) : (
        <div></div>
        // <Container
        //   maxWidth='xs'
        //   style={{
        //     backgroundImage: `url(${facePhotos[count]?.uri})`,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundPosition: 'center center',
        //     backgroundAttachment: 'fixed',
        //     backgroundSize: 'contain',
        //     width: '100%',
        //     height: '100vh',
        //   }}
        // >
        //   <Button
        //     variant="contained"
        //     color="primary"
        //     onClick={startCameraHandler}
        //     sx={{ marginTop: theme.spacing(5) }}
        //   >
        //     {/*i18n.t('AddPhotoButton')*/ "Add Photo"}
        //   </Button>
        // </Container>
      )}
    </Modal>
  );
};

const CameraPreview = ({ photo, example, retakePicture, savePhoto, closeModal, loading, counter, t, styles }) => {
  return (
    <Container
      // className={styles.cameraPreview}
      // maxWidth='md'
      style={{
        // background: `url(${photo.uri}) no-repeat center center fixed`,
        width: '100vw', /* Adjust the width as needed */
        height: '100vh', /* Use vh for responsive height */
        backgroundImage: `url(${photo.uri})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: '100% 100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'column'
        // minHeight: '100vh',
      }}
    >
      <Box style={{justifyContent: 'space-evenly', width: '100%', display: 'flex', padding: 10}}>
        <IconButton
          style={styles.closeButton}
          size='large'
          onClick={closeModal}
        >
          <CloseIcon color='white' />
        </IconButton>
        <Button
          startIcon={<RestartAltIcon />}
          variant="contained"
          color="primary"
          onClick={retakePicture}
          disabled={loading}
        >
          {t('RetakeButton') /*"Retake"*/}
        </Button>
        <Button
          startIcon={<TaskAltIcon />}
          variant="contained"
          color="inherit"
          onClick={savePhoto}
          disabled={loading}
        >
          {counter === 0 ? t('NextPoseButton') /*"Done"*/ : t('GetVerifyButton') /*"Verify"*/}
        </Button>
      </Box>
    </Container>
  );
};

const VerifyPreview = ({ photo, example, retakePicture, savePhoto, loading, counter, t, styles }) => {
  return (
    <Container
      // className={styles.cameraPreview}
      // maxWidth='md'
      style={{
        // background: `url(${photo.uri}) no-repeat center center fixed`,
        width: '100vw', /* Adjust the width as needed */
        // height: '70vh', /* Use vh for responsive height */
        // backgroundImage: `url(${photo.uri})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      {/* <img src={photo.uri} /> */}
      <Paper elevation={3} className={styles.cameraPreviewPaper}>
        <Typography variant="h5" className={styles.cameraPreviewText}>
          {t('Verify-Instruction2') /*"Verify-Instruction2"*/}
        </Typography>
        <Typography variant="body1" className={styles.cameraPreviewText}>
          {t('Verify-Instruction3') /*"Verify-Instruction3"*/}
        </Typography>
        <Grid container spacing={2} justifyContent="center" alignItems={'center'}>
          <Grid item>
            <Box
              variant="contained"
              color="primary"
              // onClick={retakePicture}
              // disabled={loading}
            >
              <img
                src={`${photo.uri}`}
                alt='Selfie'
                style={{
                  // background: `url(${photo.uri}) no-repeat center center fixed`,
                  // backgroundImage: `url(${photo.uri})`,
                  // backgroundRepeat: 'no-repeat',
                  // backgroundPosition: 'center center',
                  // backgroundAttachment: 'fixed',
                  // backgroundSize: 'contain',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  // width: '18vh',
                  height: '32vh',
                  resize: 'cover'
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              variant="contained"
              color="primary"
              // onClick={savePhoto}
              // disabled={loading}
            >
              <img
                src={`${example.uri}`}
                alt='Selfie Result'
                style={{
                  // background: `url(${photo.uri}) no-repeat center center fixed`,
                  // backgroundImage: `url(${photo.uri})`,
                  // backgroundRepeat: 'no-repeat',
                  // backgroundPosition: 'center center',
                  // backgroundAttachment: 'fixed',
                  // backgroundSize: 'contain',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  // width: '18vh',
                  height: '32vh',
                  resize: 'cover'
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" flexDirection={'column'} alignItems={'center'}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={retakePicture}
              disabled={loading}
            >
              {t('RetakeButton') /*"Retake"*/}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={savePhoto}
              disabled={loading}
            >
              {counter === 0 ? t('NextPoseButton') /*"Next Pose"*/ : t('GetVerifyButton') /*"Verify"*/}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default TakePictureModal;
