import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Checkbox, FormControlLabel, useMediaQuery, useTheme } from '@mui/material'; // Replace with appropriate Material-UI components
import TuneIcon from '@mui/icons-material/Tune';
import ChatRow from './ChatRow'; // Import your ChatRow component
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AuthContext } from '../../context/AuthProvider';
import { SelectAreaContext } from '../../context/SelectAreaProvider';
import getMatchedUserInfo from '../../lib/getMatchedUserInfo';

const Chatlist = ({ style, datas, horizontal, barStyle, navigation }) => {
  const { selectedAreaId, selectedArea } = useContext(SelectAreaContext);
  const {
    user: { id },
    t,
  } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [filter, setFilter] = useState(t('Chat-FilterCurrentArea') /*'current area'*/);
  const [filterList, setFilterList] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const list = [
    { value: t('Chat-FilterAll'), info: ""},
    { value: t('Chat-FilterCurrentArea'), info: `(${t('Chat-FilterCurrentArea1')} ${selectedArea?.name})`},
    { value: t('Chat-FilterNotCurrentArea'), info: `(${t('Chat-FilterNotCurrentArea1')} ${selectedArea?.name})`},
    // 'all', 'current area', 'not current area'
    // t('Chat-FilterAll'), t('Chat-FilterCurrentArea'), t('Chat-FilterNotCurrentArea'),
];

  useEffect(() => {
    if (datas.length === 0) return;
    const filterData = () => {
      if (filter === t('Chat-FilterAll') /*'all'*/) {
        setFilterList(datas);
      }
      if (filter === t('Chat-FilterCurrentArea') /*'current area'*/) {
        setFilterList(
          datas.filter((data) => {
            let matchedUserInfo = getMatchedUserInfo(data.users, id);
            if (matchedUserInfo.accessAreas.includes(selectedAreaId)) {
              return data;
            }
            return null;
          })
        );
      }
      if (filter === t('Chat-FilterNotCurrentArea') /*'not current area'*/) {
        setFilterList(
          datas.filter((data) => {
            let matchedUserInfo = getMatchedUserInfo(data.users, id);
            if (!matchedUserInfo.accessAreas.includes(selectedAreaId)) {
              return data;
            }
            return null;
          })
        );
      }
    };

    filterData();
  }, [filter, datas, id, selectedAreaId, t]);


  return (
    <div style={{ backgroundColor: /*'#f7e6ff'*/ 'whitesmoke', ...style }}>
      <div style={styles.container}>
        <div style={styles.filterBox}>
          <div style={styles.titleBox}>
            <span style={styles.title}>
              {horizontal ? t('Chat-NewMatchesList') /*'New Matches'*/ : t('Chat-MessagesList') /*'Messages'*/ }
            </span>
            <Badge sx={{pl: 1}} badgeContent={datas.length ? datas.length : '0'} color="error" />
          </div>
          <div style={styles.filter}>
            <Button sx={{backgroundColor: '#3366ff'}} onClick={() => setExpanded(!expanded)}>
              <span style={{ fontSize: '12px', color: 'white', marginRight: 5, fontWeight: 'bold' }}>
                {t('ChatFilterButton') /*'Filter'*/}
              </span>
              <TuneIcon /*color="warning"*/ sx={{color: 'white'}} fontSize="medium" />
            </Button>
          </div>
        </div>
        {expanded && (
          <div style={styles.filterOverlay}>
            <div style={{...styles.filterContent, width: isMobile ? '90%' : '50%'}}>
              <span style={styles.filterTitle}>
                {horizontal ? t('Chat-NewMatchesFilterTitle') /*'New Matches Filter'*/ : t('Chat-MessagesFilterTitle') /*'Messages Filter'*/}
              </span>
              <span style={styles.filterIntro}>{t('Chat-FilterIntro') /*"Filters help you see how close you are to your match based on the area in Area tab you're entering"*/}</span>
              {list.map((listvalue, index) => (
                <FormControlLabel
                  key={listvalue.value}
                  control={
                    <Checkbox
                      checked={filter === listvalue.value}
                      onChange={() => setFilter(listvalue.value)}
                    />
                  }
                  label={`${listvalue.value} ${listvalue.info}` /*listvalue*/}
                />
              ))}
              <Button
                variant="contained"
                color="primary"
                sx={styles.button}
                onClick={() => setExpanded(!expanded)}
              >
                {t('DoneButton') /*'Done'*/}
              </Button>
            </div>
          </div>
        )}
        {filterList.length > 0 ? (
          <div 
            style={{...styles.flatlist, overflowY: !horizontal ? 'scroll' : 'null', overflowX: horizontal ? 'scroll' : 'null', height: !horizontal ? '350px': null}}
          >
            {filterList.map((item) => (
              <ChatRow key={item.id} matchDetails={item} barStyle={barStyle} navigation={navigation} />
            ))}
          </div>
        ) : !horizontal ? (
          <div style={styles.noMessages}>
            <span style={{ fontSize: 20, color: '#808080' }}>
              {t('Chat-Messages_Instruction') /*'No message sent at the moment'*/}</span>
          </div>
        ) : (
          <div style={styles.noMatches}>
            <div style={styles.userIcon}>
              <AccountCircleIcon color="#d3d3d3" fontSize="large" sx={{width: 40, height: 40}} />
              <span style={styles.iconText}>
                {t('Chat-NewMatches_Instruction') /*'No match yet'*/}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chatlist;

const styles = {
  container: {
    padding: 10,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '12px',
    color: 'deeppink',
    marginRight: 6,
    fontWeight: 'bold',
  },
  filter: {
    position: 'relative',
    right: 5,
  },
  filterOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 3
  },
  filterContent: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 10,
    paddingBottom: 50,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  },
  filterTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    padding: 10,
  },
  filterIntro: {
    color: '#808080',
    // lineHeight: 20,
    padding: 10,
  },
  button: {
    backgroundColor: '#3364c8',
    marginTop: 10,
    width: 200,
    alignSelf: 'center',
  },
  flatlist: {
    position: 'relative',
    paddingTop: 10,
    overflow: 'hidden',
  },
  noMessages: {
    paddingTop: 30,
    alignItems: 'center',
    justifyContent: 'center',
    // height: '100vh',
  },
  noMatches: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  userIcon: {
    paddingTop: 10,
    width: 100,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  iconText: {
    paddingTop: 5,
    fontWeight: '500',
    fontSize: 10,
  },
};
