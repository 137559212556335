import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Button,
  Container,
  // useMediaQuery,
  useTheme,
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { firestore, storage } from '../firebase';
import { useNavigate } from 'react-router-dom';
import TakePictureModal from '../components/PhotoComponents/TakePictureModal';

const VerifyScreen = () => {
  const { user, t } = useContext(AuthContext);
  const [facePhotos, setFacePhotos] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  // Copy from AddPhotoScreen
  // const cameraRef = useRef();
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const [startCamera, setStartCamera] = useState(false);
  // const [stream, setStream] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [cameraType, setCameraType] = useState('front');
  const [flashMode, setFlashMode] = useState('off');
  // const [uploadedImage, setUploadedImage] = useState([]);
  const [takePictureVisible, setTakePictureVisible] = useState(false);
  const styles = {
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center'
    },
    exampleImage: {
      position: 'absolute',
      top: '10px', /* Adjust the top and right values as needed */
      right: '10px',
      padding: '10px',
      height: '30vh',
      zIndex: 2,
      color: 'white',
      border: 'none',
      // borderRadius: '50%',
      cursor: 'pointer',
      // fontSize: '25px'
    },
    large_frame: {
      width: '90%', 
      height: 310, 
      borderWidth: 1, 
      borderRadius:10, 
      borderColor: 'grey', 
      backgroundColor: '#d3d3d3' 
    },
    small_picture: {
      width: 120,
      height: 200,
      borderRadius: 10,
      // padding: 5
      },
    small_frame: {
      width: 180, 
      height: 300, 
      borderWidth: 1, 
      borderRadius:10,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'grey', 
      backgroundColor: '#d3d3d3' 
    },
    enablebutton: {
      width: 250,
      // backgroundColor: '#14274e',
      // backgroundColor: 'rgba(90, 154, 230, 1)',
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30,
      marginTop: 10,
    },
    buttontitle: {
      fontSize: 20,
      color: 'white'
    },
    disablebutton: {
      backgroundColor: 'rgba(90, 154, 230, 1)',
      width: 50,
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 5
    },
    disabletitle: {
      fontSize: 10,
      color: 'white'
    },
    cancelbutton: {
      width: 250,
      backgroundColor: 'white',
      // borderColor: 'transparent',
      borderWidth: 1,
      borderRadius: 30,
      marginTop: 10
    },
    cancelbuttontitle: {
      fontSize: 20,
      color: 'black'
    },
    cameraPreviewPaper: {
      width: '80%',
      height: '80%',  // Use 'vh' for viewport height or 'px' for pixels
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2), // You can adjust the padding as needed
      backgroundColor: 'white', // Change this to your desired background color
      borderRadius: 10, // Adjust the border radius as needed
      boxShadow: theme.shadows[3], // Add shadow to the paper
    },
    cameraPreviewText: {

    }
  };

  useEffect(() => {
    const unsubscribe = firestore()
      .collection(`faces`)
      .onSnapshot((snapshot) => {
        setFacePhotos(
          snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort(() => 0.5 - Math.random())
            .slice(0, 2)
        );
      });

    return () => unsubscribe();
  }, []);

  // const handleCapture = (dataUri) => {
  //   // Convert the data URI to a Blob
  //   const byteString = atob(dataUri.split(',')[1]);
  //   const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
  //   const ab = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(ab);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   const blob = new Blob([ab], { type: mimeString });
  //   return blob;
  // };


  const savePhotoHandler = async (blob) => {
    try {
      // setLoading(true);

      // Use capturedImage.uri to upload the image

      // setUploadedImage([...uploadedImage, { uri: capturedImage.uri }]);
      // if(capturedImage) {
      //   const blob = handleCapture(capturedImage.uri)
        const fileRef = storage().ref(`${user?.id}/face-recognition/${user?.id + facePhotos[count]?.id}`)
        const result = await fileRef.put(blob)
        setCapturedImage(null);
        setPreviewVisible(false);
        // setStartCamera(false);
      // }
      if (count === 0) {
        setCount(count + 1);
      } else {
        navigate('/home')
      }
    } catch (error) {
      console.error(error);
      // alert(/*i18n.t('Verify-Error')*/ "Verify-Error");
    } finally {
      setLoading(false);
    }
  };


  const handleFlashMode = () => {
    if (flashMode === 'on') {
      setFlashMode('off');
    } else if (flashMode === 'off') {
      setFlashMode('on');
    } else {
      setFlashMode('auto');
    }
  };

  return (
    <div style={styles.container}>
      <Container
        maxWidth='sm'
        style={{
          // background: `url(${facePhotos[count]?.uri}) no-repeat center center fixed`,
          backgroundImage: `url(${facePhotos[count]?.uri})`, 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center center', 
          backgroundAttachment: 'fixed',
          backgroundSize: 'contain',
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <TakePictureModal 
          visible={takePictureVisible}
          changeVisible={setTakePictureVisible}
          handleImage={savePhotoHandler}
          example={facePhotos[count]}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={/*startCameraHandler*/ () => setTakePictureVisible(!takePictureVisible)}
          sx={{ marginBottom: theme.spacing(5) /*width: '200px' */}}
        >
          {t('AddPhotoButton') /*"Add Photo"*/}
        </Button>
      </Container>
    </div>
  );
};


export default VerifyScreen;
