// AppRouter.js
import React, { useContext } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import VerifyScreen from '../screens/VerifyScreen';
import CallScreen from '../screens/CallScreen';
import ReceiveCallScreen from '../screens/ReceiveCallScreen';
import SubscriptionScreen from '../screens/SubscriptionScreen';
import TicketScreen from '../screens/TicketScreen';
import TimeExtendScreen from '../screens/TimeExtendScreen';
import BeLikedScreen from '../screens/BeLikedScreen';
import IntroScreen from '../screens/IntroScreen';
import { AuthContext } from '../context/AuthProvider';
import HomeRoute from './HomeRoute';
import ChatRoute from './ChatRoute';
import EventRoute from './EventRoute';
import { SelectAreaContext } from '../context/SelectAreaProvider';
import SignUpRoute from './SignUpRoute';
import ProductScreen from '../screens/ProductScreen';
import PricingScreen from '../screens/PricingScreen';
import PolicyScreen from '../screens/PolicyScreen';
import TermScreen from '../screens/TermScreen';
import PrivacyScreen from '../screens/PrivacyScreen';
import PhoneNumberScreen from '../screens/PhoneNumberScreen';
import { Box, Typography } from '@mui/material';
import NotFoundScreen from '../screens/NotFoundScreen';

const AppRouter = () => {
  // Add your authentication logic here if needed
  // const { isLogin } = useContext(AuthContext); // Replace with your authentication check
  const { 
    openSubscription, setOpenSubscription, openTicket, setOpenTicket, openIntro, setOpenIntro, openTimeExtend, setOpenTimeExtend
  } = useContext(SelectAreaContext);


  return (
    <>
      <SubscriptionScreen 
        visible={openSubscription}
        changeVisible={setOpenSubscription}
      />
      <TicketScreen
        visible={openTicket}
        changeVisible={setOpenTicket}
      />
      <IntroScreen 
        visible={openIntro}
        changeVisible={setOpenIntro}
      />
      <TimeExtendScreen 
        visible={openTimeExtend}
        changeVisible={setOpenTimeExtend}
      />
      <Routes>
        <Route path="home" element={<HomeRoute />} /> 
        <Route path="event" element={<EventRoute />} />
        <Route path="chat" element={<ChatRoute />} />
        <Route path="beliked" element={<BeLikedScreen />} />
        <Route path="call" element={<CallScreen />} />
        <Route path="receive" element={<ReceiveCallScreen />} />
        {/* <Route path="timeextend" element={<TimeExtendScreen />} /> */}
        <Route path="verify" element={<VerifyScreen />} />
        <Route path="/" element={/*<NotFoundScreen />*/ <SignUpRoute />} />
        <Route path="pricing" element={<PricingScreen />} />
        <Route path="products" element={<ProductScreen />} />
        <Route path="policy" element={<PolicyScreen />} />
        <Route path='termandconditions' element={<TermScreen />} />
        <Route path='privacypolicy' element={<PrivacyScreen />} />
        <Route path='*' element={<NotFoundScreen />}  />
        {/* <Route path="/phone_number" element={<PhoneNumberScreen/>} />
        <Route path="/add_email" element={<AddEmailScreen/>} />
        <Route path="/add_name" element={<AddNameScreen/>} />
        <Route path="/add_birthday" element={<AddBirthdayScreen/>} />
        <Route path="/add_sexual" element={<AddSexualOrientationScreen/>} />
        <Route path="/add_photo" element={<AddPhotoScreen/>} />
        <Route path="/add_location" element={<AddLocationScreen/>} /> */}
        {/* Add other authentication-related routes here */}
      </Routes>
    </>
  );
};

export default AppRouter;
