import React, { useContext, useEffect, useState, useCallback, useLayoutEffect } from 'react';
import {
  Container,
  Typography,
  Text,
  IconButton,
  Button,
  CssBaseline,
  Paper,
  Box,
} from '@mui/material'; // Import Material-UI components
import PersonIcon from '@mui/icons-material/Person';
import TuneIcon from '@mui/icons-material/Tune';
import { AuthContext } from '../context/AuthProvider';
import { LocationContext } from '../context/LocationProvider';
import DeckSwiper from '../components/HomeComponents/DeckSwiper';
import { SelectAreaContext } from '../context/SelectAreaProvider';
import { firestore } from '../firebase';
import MatchScreen from './MatchScreen';

const HomeScreen = ({ navigation }) => {
  const {
		user: {
      id/*, ageRange, showGender, verifiedStatus, onlySeeVerified, sortInterests, sortPersonality, interests, personality*/
    }, firstLogin, t
	} = useContext(AuthContext);
  const { status, isProMember, setStatus } = useContext(LocationContext);
  const { selectedArea, /*members, selectedUsersId,*/ profiles, profilesIndex, profilesChildRefs, profilesIndexRef, setProfilesIndex, homeSwipe, setHomeSwipe, openIntro, setOpenIntro } = useContext(SelectAreaContext);
	// const [profiles, setProfiles] = useState([])
  const [matchDetail, setMatchDetail] = useState();
  const [matchVisible, setMatchVisible] = useState(false);
  const [counter, setCounter] = useState(0);

	const onSwipeLeft = useCallback(async (cardIndex) => {
    if (!profiles[cardIndex].id) return;
    const userSwiped = profiles[cardIndex]
    console.log(`You swipe PASS on ${userSwiped.displayName}`)
    firestore().doc(`users/${id}/passed/${userSwiped.id}`).set(userSwiped);
  }, [profiles, id])

  const onSwipeRight = useCallback(async (cardIndex) => {
    if (!profiles[cardIndex].id) return;
    const userSwiped = profiles[cardIndex]
    console.log(`You swipe LIKE on ${userSwiped.displayName}`)
    firestore().doc(`users/${id}/liked/${userSwiped.id}`).set(userSwiped);
    console.log(counter);
    if (!isProMember && counter === 50) {
      firestore().doc(`users/${id}`).update({
        limitTime: firestore.Timestamp.now()
      });
    } else {
      setCounter(counter + 1)
    }
  }, [profiles, isProMember, counter, id ])

  useLayoutEffect(() => {
    if (firstLogin) {
      setOpenIntro(!openIntro)
    }

  }, [firstLogin])

  const handleAllowLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude, longitude)
          setStatus(true);
        },
        (error) => {
          console.error('Error getting location:', error.message);
          if (error.code === 1) {
            // Permission denied
            alert(t('Home-Alert1'));
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser.');
    }
  }


  useEffect(() => {
    const unsub = firestore().collection('matches')
      .where('usersMatched', 'array-contains', id )
      .orderBy('timestamp')
      .onSnapshot((snapshot) => {
        snapshot?.docChanges()?.forEach((change) => {
          if (change.doc.exists && change.type === 'added') {
            let newmatch = {...change.doc.data(), id: change.doc.id};
            if (Array.isArray(newmatch?.seen) && !newmatch?.seen.includes(id)) {
              setMatchDetail(newmatch);
              setMatchVisible(!matchVisible)
              
            }
          }
        })
      })

    return () => unsub();
  }, [])


  return (
    <Container
      component={Paper}
      maxWidth="lg"
      sx={{
        flex: 1,
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
        
      }}
    >
      {/* Header */}
      <CssBaseline />
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          // width: '100%'
        }}
      >
        <Button
          sx={({ pressed }) => [
            {
              backgroundColor: pressed ? '#e6e6e6' : 'transparent',
            },
            { padding: 10, zIndex: 1 },
          ]}
          onClick={() => {
            navigation('profile');
          }}
        >
          <IconButton size="large" sx={{ display: { md: 'flex' ,lg: 'none' } }}>
            <PersonIcon fontSize="inherit" name="person" />
          </IconButton>
        </Button>

        <Typography
          variant="h5"
          component="div"
          sx={{
            fontSize: 30,
            fontWeight: 'bold',
            display: { md: 'flex', lg: 'none' }
          }}
        >
          {t('Home-Title') /*'Home'*/}
        </Typography>

        <Button
          sx={({ pressed }) => [
            {
              backgroundColor: pressed ? '#e6e6e6' : 'transparent',
            },
            { padding: 10, zIndex: 1},
          ]}
          onClick={() => {
            navigation('filter');
          }}
        >
          <IconButton size="large" sx={{display: { md: 'flex', lg: 'none' }}}>
            <TuneIcon fontSize="inherit" name="tune" />
          </IconButton>
        </Button>
      </Box>
      <Box sx={{display: {xs: 'none' , sm: 'none', md: 'none', lg: 'flex' }, padding: 3}}></Box>
      {/* End of Header */}

      {matchDetail ? (
        <MatchScreen 
          newmatch={matchDetail}
          visible={matchVisible}
          changeVisible={setMatchVisible}
        />
      ) : null}

      {/* Card */}
      {status ? (
        <DeckSwiper
          style={{ flex: 1, zIndex: -2 }}
          name={'Home'}
          onSwipeLeft={onSwipeLeft}
          onSwipeRight={onSwipeRight}
          data={profiles}
          t={t}
          currentIndex={profilesIndex} 
          setCurrentIndex={setProfilesIndex}
          childRefs={profilesChildRefs}
          currentIndexRef={profilesIndexRef}
          swipeUsers={homeSwipe.filter(user => user?.accessAreas && user?.accessAreas.includes(selectedArea?.id))}
          setSwipeUsers={setHomeSwipe}
        />
      ) : (
        <div
          style={{
            // flex: 1,
            justifyContent: 'center',
            borderColor: 'grey',
            minHeight: '80vh'
          }}
        >
          <div
            style={{
              padding: 10,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 20,
              height: '100%',
              backgroundColor: '#ccccff',
              minHeight: '80vh',
              display: 'flex',
              flexDirection: 'column'
              // flex: 1,
            }}
          >
            <Typography variant="h6">{t('Home-Instruction')}</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleAllowLocation}
            >
              {t('AllowLocationButton')}
            </Button>
          </div>
        </div>
      )}
    </Container>
  )
}

export default HomeScreen

const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  iconWrapper: {
    padding: 10,
    zIndex: 1
  },
  deckSwiper: {
    flex: 1,
    zIndex: -2
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: 10,
    // height: 80
    // flex: 3
  },
  button: {
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  offlineContainer: {
    flex: 1,
    justifyContent: 'center',
    borderColor: 'grey'
  },
  offlineWrapper: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    // borderWidth: 0.5,
    borderRadius: 20,
    height: '100%',
    backgroundColor: '#ccccff'
  },
  settingButton: {
    backgroundColor: 'rgba(90, 154, 230, 1)',
    borderColor: 'transparent',
    borderWidth: 0,
    borderRadius: 30,
    marginTop: 10,
    width: 200
  },
  buttonTitle: {
    fontSize: 20,
    color: 'white'
  }
}
