import React, { useState } from 'react';
import {
  Button,
  Paper,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';


const InterestsModal = ({ visible, changeVisible, updateData, setUpdateData, t }) => {
  const [interests, setInterests] = useState(updateData?.interests ? [...updateData?.interests] : []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [search, setSearch] = useState('');
	const interestsList = [ 'Mental Health Awareness', 'Voter Rights', 'Black Lives Matter', 'Rave', 'Military', 'Climate Change', 'Road Trips','Second-hand apparel', 'Feminism', 'LGBTQ+ Rights', 'Football', 'Country Music', 'Snowboarding', 'Skiing', 'Festivals','Tattoos', 'Activism', 'Crossfit', 'Sports', 'Photography', 'Reading', 'Hot Springs', 'Walking', 'Aquarium', 'Instagram', 'K-pop', 'Street Food', 'Clubbing', 'Foodie Tour', 'Exhibition', 'Shopping', 'Brunch', 'Escape Cafe', 'Boba Tea', 'Cars', 'Start Ups', 'Badminton', 'Collecting', '90s Kid', 'Rugby', 'Boxing', 'Sneakers', 'Running' ,'Travel', 'Language Exchange', 'Hot Yoga', 'Meditation', 'Sushi', 'Self Care', 'Home Workout', 'Siam Poetry', 'Hockey', 'Basketball', 'Gym', 'Social Media', 'Skincare', 'Movies', 'Cricket', 'Poetry', 'Stand up Comedy', 'Skateboarding', 'Singing','Coffee', 'Karaoke', 'Fortnite', 'Free Diving', 'Jiu-jitsu', 'Reggaeton', 'Investing', 'Ice Skating', 'Expoditions', 'Ice Cream', 'Car Racing', 'Cheerleading', 'Choir', 'Cosplay', 'Motor Sports', 'Catan', 'Broadway', 'Pilates', 'Content Creation', 'E-Sports', 'Binge-Watching TV shows', 'Bicycle Racing', 'Songwriter', 'Painting', 'Surfing', 'Bowling', 'Paddie Boarding', 'Padle', 'Cooking', 'Entrepreneurship', 'Motorcycles', 'Beach Bars',
	'Astrology', 'Dancing', 'Amateur Cook', 'Art', 'Politics', 'Museum', 'Fencing', 'Soccer', 'Gardening', 'Craft Beer', 'Iced Tea', 'Drummer', 'BBQ', 'Real Estate', 'Podcasts', 'DAOs', 'Tea', 'Environmentalism', 'Rollerskating', 'Wine', 'Dungeons & Dragon', 'Writing', 'Vlogging', 'Electronic Music', 'Drawing', 'Trivia', 'Volunteering', 'Board Games', 'Ramen', 'Martial Arts', 'Marvel','Vegan Cooking', 'Makeup', 'Volleyball', 'Manga', 'League of Legends', 'Vinyasa', 'NFTs', 'Bar Hopping', 'Ballet', 'Band', 'Baseball', 'Sailing', 'Acapella', 'Art Galleries', 'Motocycling', 'Memes', 'Backpacking', 'Fishing', 'Concerts', 'Hiking', 'Mountains', 'Archery','Camping', 'Baking', 'Climbing', 'DIY', 'Active Lifestyle', 'Outdoors', 'TikTok', 'Picnicking', 'Blogging', 'Fashion', 'Cycling','Anime', 'Swimming', 'Table Tennis', 'Music', 'Triathlon', 'Netflix', 'Disney', 'Working out', 'Yoga', 'Twitch', 'Comedy', 'Stocks'
]

  const handlePress = () => {
    if (interests.length === 0) {
      setUpdateData({ ...updateData, interests: null });
      changeVisible(!visible);
      return;
    }
    if (interests.length < 3 || interests.length > 5) {
      alert(t('InterestAlert') /*'InterestAlert'*/);
      return;
    }
    setUpdateData({ ...updateData, interests: interests });
    changeVisible(!visible);
  };

  return (
    <Dialog 
      open={visible}
      fullScreen={fullScreen}
      onClose={() => changeVisible(false)}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography variant="h5" fontWeight="bold">
          {t('Profile-Interests') /*'Interests'*/}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Paper>
          <TextField
            label={t('InterestPlaceHolder') /*'InterestPlaceHolder'*/}
            fullWidth
            variant="standard"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Paper>
        <Paper>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              padding: 10
            }}
          >
            {interests.map((item) => (
              <Paper
                key={item}
                variant="outlined"
                style={{
                  borderRadius: '15px',
                  padding: '5px',
                  margin: '3px',
                  display: 'inline-flex',
                  alignItems: 'center'
                }}
                onClick={() => setInterests(interests.filter((interest) => interest !== item))}
              >
                <Typography style={{ color: interests.includes(item) ? '#ff9933' : '#b3b3b3', marginRight: 5 }}>
                  {t(item) /*item*/}
                </Typography>
                <ClearIcon fontSize='15' color='#b3b3b3' />
              </Paper>
            ))}
          </div>
        </Paper>
        <Paper>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              padding: 10
            }}
          >
            {interestsList
              .filter((interest) => t(interest).toLowerCase().includes(search.toLowerCase()))
              .map((item) => (
                <Paper
                  key={item}
                  variant="outlined"
                  style={{
                    borderRadius: '15px',
                    padding: '5px',
                    margin: '3px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setInterests([...interests, item])}
                  disabled={interests.includes(item)}
                >
                  <Typography style={{ color: interests.includes(item) ? '#ff9933' : '#b3b3b3' }}>
                    {t(item) /*item*/}
                  </Typography>
                </Paper>
              ))}
          </div>
        </Paper>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: 'white',
            borderWidth: '1px',
            borderRadius: '30px',
            marginTop: '10px',
            color: '#ff9933'
          }}
          onClick={handlePress}
        >
          {interests.length === 0 ? t('MaybeLaterButton') /*'Maybe Later'*/ : t('DoneButton') /*'Done'*/}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InterestsModal;
