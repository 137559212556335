import React from 'react';
import { Box, Typography } from '@mui/material';

const SenderMessage = ({ message }) => {
  // Calculate the maxWidth based on the length of the message
  let maxWidth = 'auto'; // Default to 'auto'

  // If the message length is greater than 250 characters, set maxWidth to 250
  if (message.message.length > 250) {
    maxWidth = '250px';
  } else {
    // Adjust the factor as needed to control the width for shorter messages
    maxWidth = `${message.message.length * 12}px`;
  }
  return (
    <Box
      sx={{
        backgroundColor: '#ff00ff',
        borderRadius: 8,
        padding: '12px 20px',
        margin: '8px 12px',
        alignSelf: 'flex-start',
        marginLeft: 'auto',
        maxWidth: maxWidth, // Set the maxWidth dynamically
        display: 'flex', // Center the content horizontally
        justifyContent: 'center', // Center the content horizontally
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
        {message.message}
      </Typography>
    </Box>
  );
};

export default SenderMessage;
