import React, { useContext } from 'react';
import { Badge, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { AuthContext } from '../../context/AuthProvider';
import { LocationContext } from '../../context/LocationProvider';
import { useNavigate } from 'react-router-dom';
import { SelectAreaContext } from '../../context/SelectAreaProvider';

const Beliked = ({ data }) => {
  const {
    t,
  } = useContext(AuthContext);
  const { isProMember } = useContext(LocationContext);
  const { setOpenSubscription, openSubscription } = useContext(SelectAreaContext);
  const navigate = useNavigate();

  const handleNavigation = () => {
    isProMember
      ? navigate('/beliked') // Replace with your React Router navigation logic
      : setOpenSubscription(!openSubscription) // Replace with your React Router navigation logic
  };

  return (
    <div style={{ backgroundColor: '#f7e6ff' }}>
      <div style={styles.titleBox}>
        <span style={styles.title}>{t('Chat-IsLikedList') /*'Who likes you'*/}</span>
        <Badge sx={{pl: 1}} badgeContent={data.length ? data.length : '0'} color="error" />
      </div>
      <div style={styles.chatbox} onClick={handleNavigation}>
        <div>
          <IconButton style={{ alignSelf: 'baseline', backgroundColor: 'white' }} color="primary">
            <FavoriteIcon fontSize="large" color="primary" />
          </IconButton>
        </div>
        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>
            {data?.length > 0
              ? `${data?.length} ${t('Chat-IsLiked_Intro2') /*'people liked you'*/}`
              : t('Chat-IsLiked_Intro1') /*"You haven't been liked yet"*/}
          </span>
          <span style={{ paddingTop: 10, paddingBottom: 10 }}>
            {isProMember
              ? t('Chat-IsLiked_Intro4') /*'Go see who liked you'*/
              : t('Chat-IsLiked_Intro3') /*"Get Hunter's Pro to see who liked you"*/}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Beliked;

const styles = {
  chatbox: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    borderRadius: 10,
    alignItems: 'center',
    margin: '12px',
    // boxShadow: '0px 5px 6.68px rgba(0, 0, 0, 0.36)',
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    paddingTop: '5px',
    alignItems: 'center',
  },
  title: {
    fontSize: '12px',
    color: 'deeppink',
    marginRight: '6px',
    fontWeight: 'bold',
  },
};
