import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  TextField,
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import Header from '../components/ChatComponents/Header';
import SenderMessage from '../components/ChatComponents/SenderMessage';
import ReceiverMessage from '../components/ChatComponents/ReceiverMessage';
import { firestore } from '../firebase'; // Import Firestore from Firebase
import { SelectAreaContext } from '../context/SelectAreaProvider';

const MessageScreen = ({ navigation }) => {
  const { user } = useContext(AuthContext);
  // const location = useLocation();
  // const { matchDetails } = location.state;
  const { selectedMatch } = useContext(SelectAreaContext);
  // const matchDetails = selectedMatch;
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  // const renderMessages = ({ item: message }) =>
  //   message.senderId === user.id ? (
  //     <Box key={message.id} sx={{ transform: 'scaleY(-1)' }}>
  //       <SenderMessage message={message} />
  //     </Box>
  //   ) : (
  //     <Box key={message.id} sx={{ transform: 'scaleY(-1)' }}>
  //       <ReceiverMessage message={message} />
  //     </Box>
  //   );

  useEffect(() => {
    if (!selectedMatch.id) return;
    const unsubscribe = firestore()
      .collection(`matches/${selectedMatch.id}/messages`)
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        setMessages(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      });

    return () => unsubscribe;
  }, [selectedMatch.id]);

  const sendMessage = () => {
    if (messages.length === 0) {
      firestore().doc(`matches/${selectedMatch.id}`).update({ newAdd: false });
    }
    firestore()
      .collection(`matches/${selectedMatch.id}/messages`)
      .add({
        timestamp: firestore.FieldValue.serverTimestamp(),
        senderId: user.id,
        displayName: user.displayName,
        photoURL: selectedMatch.users[user.id].photoURL,
        message: input,
      });

    setInput('');
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Set minimum height to cover the entire viewport
        backgroundColor: 'white',
      }}
      component={Paper}
      maxWidth="md"
    >
      <CssBaseline />
      {/* <Box> */}
      <Box
        // component={Paper}
        elevation={3}
        sx={{height: '70px', borderBottom: '2px solid lightgray'}}
      >
        <Header navigation={navigation} />
      </Box>
      {/* </Box> */}
      <Box
        // component={Paper}
        elevation={1}
        sx={{
          // flex: 1,
          // backgroundColor: 'white',
          // borderColor: 'gray',
          // borderTopWidth: 1,
          // flexDirection: 'column',
          flex: 1, 
          overflowY: 'hidden'
        }}
      >
        {/* Messages */}
        <Box
          sx={{
            height: `calc(100% - 56px)`,
            display: 'flex',
            flexDirection: 'column',
            padding: '11px',
            justifyContent: 'flex-end'
          }}
        >
          <Box
            sx={{
              flexDirection: 'column-reverse', // Reverse the direction to keep the latest message at the bottom
              overflowY: 'auto',
              maxHeight: '100%',
              // flex: 1,
            }}
          >
            {messages.slice().reverse().map((message) =>
              message.senderId === user.id ? (
                <Box
                  key={message.id}
                  sx={{ transform: 'scaleY(1)' }}
                >
                  <SenderMessage message={message} />
                </Box>
              ) : (
                <Box
                  key={message.id}
                  sx={{ transform: 'scaleY(1)' }}
                >
                  <ReceiverMessage message={message} />
                </Box>
              )
            )}
          </Box>
        </Box>
        {/* Input */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2px 2px 2px 0',
            border: '1px solid rgb(230, 230, 230)',
            borderRadius: '2px',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Send Message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            multiline
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendMessage();
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={sendMessage}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default MessageScreen;
