import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Button,
  Grid,
  MobileStepper,
  Modal
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import IntroImage1 from '../assets/welcome.webp'
import IntroImage2 from '../assets/swipe_left_swipe_right.jpg'
import IntroImage3 from '../assets/Area_Screen_1.jpg'
import IntroImage4 from '../assets/Area_Screen_2.jpg'
import IntroImage5 from '../assets/Area_Screen_3.jpg'
import IntroImage6 from '../assets/Chat_Screen_1.jpg'
import IntroImage7 from '../assets/Chat_Screen_2.jpg'
import IntroImage8 from '../assets/bowing.jpg'

const IntroScreen = ({ navigation, visible, changeVisible }) => {
  const {
    t,
    firstLogin,
    setFirstLogin,
  } = useContext(AuthContext);

  const [activeStep, setActiveStep] = useState(0);

  const slides = [
    {
      key: 'slide1',
      title: t('Intro-Title1'),
      text: t('Intro-Text1'),
      image: IntroImage1 // require('../assets/welcome.webp').default,
    },
    {
      key: 'slide2',
      title: t('Intro-Title2'),
      text: t('Intro-Text2'),
      image: IntroImage2 // require('../assets/swipe_left_swipe_right.jpg').default,
    },
    {
      key: 'slide3',
      title: t('Intro-Title3'),
      text: t('Intro-Text3'),
      image: IntroImage3 // require('../assets/Area_Screen_1.jpg').default,
    },
    {
      key: 'slide4',
      title: t('Intro-Title4'),
      text: t('Intro-Text4'),
      image: IntroImage4 // require('../assets/Area_Screen_2.jpg').default,
    },
    {
      key: 'slide5',
      title: t('Intro-Title5'),
      text: t('Intro-Text5'),
      image: IntroImage5 // require('../assets/Area_Screen_3.jpg').default,
    },
    {
      key: 'slide6',
      title: t('Intro-Title6'),
      text: t('Intro-Text6'),
      image: IntroImage6 // require('../assets/Chat_Screen_1.jpg').default,
    },
    {
      key: 'slide7',
      title: t('Intro-Title7'),
      text: t('Intro-Text7'),
      image: IntroImage7 // require('../assets/Chat_Screen_2.jpg').default,
    },
    {
      key: 'slide8',
      title: t('Intro-Title8'),
      text: t('Intro-Text8'),
      image: IntroImage8 // require('../assets/bowing.jpg').default,
    },
    // Add more slides as needed
  ];

  const maxSteps = slides.length;

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFinish = () => {
    setFirstLogin(!firstLogin);
    changeVisible(!visible);
    // navigation.goBack();
  };

  // console.log(slides[activeStep].image)

  return (
    <Modal
      sx={{
        backgroundColor: 'rgba(0.5, 0.25, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      // maxWidth={'md'}
      open={visible}
      onClose={() => {
        changeVisible(!visible);
      }}
      // backdropClickThrough={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container
        maxWidth={'md'}
        sx={{
          flex: 1,
          // backgroundColor: 'rgba(0.5, 0.25, 0, 0.5)',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <Box
          sx={{
            padding: 2,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#ffffff',
              fontSize: 28,
              fontWeight: '500',
              paddingBottom: 2,
            }}
          >
            {slides[activeStep].title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#ffffff',
              fontSize: 15,
              fontWeight: '500',
              lineHeight: '20px',
              textAlign: 'justify',
            }}
          >
            {slides[activeStep].text}
          </Typography>
        </Box>
        <Box
          // maxWidth={'xs'}
          sx={{
            height: '60%',
            // width: '100%',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Add this line to center the image vertically
            alignItems: 'center', // Add this line to center the image horizontally
          }}
        >
          <img
            src={slides[activeStep].image}
            alt="Slide"
            style={{
              // flex: 1,
              width: '100%',
              height: '100%',
              paddingBottom: 20,
              objectFit: 'contain', 
            }}
          />
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="dots"
            activeStep={activeStep}
            sx={{ alignSelf: 'center', paddingTop: 2 }}
          />
        </Box>
        <Box
          sx={{
            padding: 2,
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            display: 'flex'
          }}
        >
          {activeStep > 0 && activeStep < maxSteps - 1 && (
            <Button
              variant="contained"
              onClick={handleBack}
              sx={{ padding: 2 }}
            >
              {t('PrevButton')}
            </Button>
          )}
          {activeStep < maxSteps - 1 && (
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ padding: 2 }}
            >
              {t('NextButton')}
            </Button>
          )}
          {activeStep === maxSteps - 1 && (
            <Button
              variant="contained"
              onClick={handleFinish}
              sx={{ padding: 2 }}
            >
              {t('FinishButton')}
            </Button>
          )}
        </Box>
      </Container>
    </Modal>
  );
};

export default IntroScreen;
