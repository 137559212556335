import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CssBaseline,
  Checkbox,
  Slider,
  Container,
  FormControlLabel,
  useTheme,
  useMediaQuery
} from "@mui/material";
import compareObjects from '../lib/compareObjects';
import { firestore } from "../firebase";
import { useNavigate } from "react-router-dom";
import MBTIModal from '../components/FilterComponents/MBTIModal';
import { LocationContext } from '../context/LocationProvider';
import { AuthContext } from '../context/AuthProvider';
import { SelectAreaContext } from '../context/SelectAreaProvider';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ExploreIcon from '@mui/icons-material/Explore';
import WcIcon from '@mui/icons-material/Wc';
import PersonIcon from '@mui/icons-material/Person';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { GiLightningBow } from "react-icons/gi";


const FilterScreen = ({ navigation }) => {
  const {
    user: { id, ageRange, showGender, verifiedSeeOnly, onlySeeVerified, sortInterests, sortPersonality }, t
  } = useContext(AuthContext);
  const { isProMember } = useContext(LocationContext);
  const { selectedArea, openSubscription, setOpenSubscription, openTicket, setOpenTicket } = useContext(SelectAreaContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [intermediateFilter, setIntermediateFilter] = useState({ 
    id, ageRange, showGender, verifiedSeeOnly, onlySeeVerified, sortInterests, sortPersonality 
  });
  const navigate = useNavigate();
  const [mbtiVisible, setMbtiVisible] = useState(false);
  const styles = {
    container: {
      flex: 1,
      height: '100vh',
      // backgroundColor: 'lightgrey',
      overflowY: 'scroll', // Enable vertical scrolling
      border: '1px solid #ccc', // Add a border for styling
      // paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0
      backgroundColor: 'whitesmoke' 
    },
    doneContainer: { 
      // padding: 10, 
      width: '100%', 
      display: 'flex',
      justifyContent: 'space-between', 
      flexDirection: 'row',
      borderWidth: 1,
      bordercolor: 'black'
    },
    doneButton: {
      fontSize: 16, 
      color: '#3399ff'
    },
    scrollContainer: {
      // paddingHorizontal: 15,
      paddingLeft: 15,
      paddingRight: 15,
      display: 'flex',
      flexDirection: 'column'
    },
    proButton: {
      // paddingVertical: 2,
      paddingTop: 2,
      paddingBottom: 2
    },
    proContainer: {
      marginTop: 10, 
      padding: 10, 
      backgroundColor: 'white', 
      borderRadius: 10, 
      height: 80,
      width: '100%',
      justifyContent: 'center', 
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    proItems: {
      display: 'flex',
      // flexDirection: 'row', 
      padding: 5
    },
    proIcon: {
      marginRight: 5
    },
    proTitle: {
      fontSize: 18, 
      fontWeight: '500'
    },
    proIntro: {
      fontSize: 13
    },
    filterTitle: {
      fontSize: 15, 
      paddingTop: 10, 
      alignSelf: 'center'
    },
    areaContainer: {
      marginTop: 10, 
      padding: 10, 
      backgroundColor: 'white', 
      borderRadius: 10
    },
    areaWrapper: {
      // flexDirection: 'row', 
      justifyContent: 'space-between',
      display: 'flex'
    },
    areaItems: {
      display: 'flex'
      // flexDirection: 'row'
    },
    areaText: {
      fontSize: 18, 
      marginLeft: 5, 
      fontWeight: '500'
    },
    areaName: {
      fontSize: 17, 
      marginRight: 5
    },
    areaIntro: {
      paddingTop: 5
    },
    catagoryContainer: {
      marginTop: 10, 
      backgroundColor: 'white', 
      borderRadius: 10,
      padding: 10
    },
    catagoryItems: {
      // flexDirection: 'row', 
      display: 'flex',
      padding: 5
    },
    catagoryTitle: {
      fontSize: 18, 
      marginLeft: 5, 
      fontWeight: '500'
    },
    catagoryIntro: {
      fontSize: 13, 
      // marginLeft: 5, 
      // lineHeight: 20
    },
    catagoryList: {
      margin: 5,
      display: 'flex',
      flexDirection: 'column'
    },
    catagoryOption: {
      borderRadius: 10
    },
    sliderContainer: {
      flexDirection: 'row', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      padding: 10,
      display: 'flex'
    },
    sliderTrack: { 
      // height: 3,
      width: '80%'
    },
    sliderMarker: { 
      width: 16, 
      height: 16, 
      borderRadius: 8, 
      backgroundColor: '#008ee6' 
    },
    sliderSelected: { 
      backgroundColor: '#008ee6' 
    }
  }

  // const { screen, setScreen } = useContext(SelectAreaContext);
  // const [openSubscription, setOpenSubscription] = useState(false);
  // const [openTicket, setOpenTicket] = useState(false);

  const updateFilter = async () => {
    const filter = { id, ageRange, showGender, verifiedSeeOnly, onlySeeVerified, sortInterests, sortPersonality };
    const { differences, isEqual } = compareObjects(filter, intermediateFilter);
    if (isEqual && isMobile) {
      // navigate(-1);
      navigation('home');
      return;
    }
    // console.log(differences, isEqual);
    // await updateDoc(doc(db, 'users', id), differences);
    await firestore().doc(`users/${id}`).update(differences)
    // navigate(-1);
    isMobile ? navigation('home') : void 0
  };

  const handleAgeRangeChange = (event, newValue) => {
    setIntermediateFilter({ ...intermediateFilter, ageRange: newValue });
  };
  // console.log(multiSliderValue);
  return (
    <Container 
      component="main"
      maxWidth="md"
      sx={styles.container}
    >
      <CssBaseline />
      {/* <SubscriptionScreen 
        visible={openSubscription}
        changeVisible={setOpenSubscription}
      />
      <TicketScreen
        visible={openTicket}
        changeVisible={setOpenTicket}
      /> */}
      <Box sx={styles.doneContainer}>
        <Button
          sx={{display: { xs: 'flex', md: 'flex' }}}
          onClick={updateFilter}
          style={styles.doneButton}
        >{ isMobile ? t('DoneButton') : t('SaveChangeButton') }</Button>
        <Button
          sx={{display: { xs: 'none', md: 'flex' }}}
          onClick={() => navigation('profile')}
          style={{...styles.buttonTitle, color: '#3399ff'}}
        >{t('SwitchProfileButton')}</Button>
      </Box>
      <div 
        // style={{ overflowY: 'scroll', height: '100vh', backgroundColor: 'whitesmoke' }}
      >
        <Box style={styles.scrollContainer}>
          <Button style={styles.proButton} onClick={() => setOpenSubscription(!openSubscription) /*navigate('../subscription')*/}>
            <Box style={styles.proContainer}>
              <Box style={styles.proItems}>
                <GiLightningBow style={{ fontSize: '30px', marginRight: 10, transform: 'rotate(270deg)'}} />
                <Typography style={styles.proTitle}>{t('SubscriptionTitle') /*'Subscription'*/}</Typography>
              </Box>
              <Typography style={styles.proIntro}>{t('SubscriptionIntro') /*'Unlock all feature'*/}</Typography>
            </Box>
          </Button>
          <Button style={styles.proButton} onClick={() => setOpenTicket(!openTicket) /*navigate('../ticket')*/ }>
            <Box style={styles.proContainer}>
              <Box style={styles.proItems}>
                <GiLightningBow style={{ fontSize: '30px', marginRight: 10, transform: 'rotate(270deg)'}} />
                <Typography style={styles.proTitle}>{t('TicketTitle') /*'Ticket'*/}</Typography>
              </Box>
              <Typography style={styles.proIntro}>{t('TicketIntro') /*'Buy Special Zone Ticket'*/}</Typography>
            </Box>
          </Button>
          <Typography style={styles.filterTitle}>{t('Filter-Title') /*'We will sort out your ideal partner base on these criteria'*/}</Typography>
          {/* <Button style={{paddingVertical: 5}} onClick={() => { navigate('Event') }}> */}
            <Box style={styles.areaContainer}>
              <Box style={styles.areaWrapper}>
                <Box style={styles.areaItems}>
                  <ExploreIcon size={25} />
                  <Typography style={styles.areaText}>{t('Filter-Area') /*'Area'*/}</Typography>
                </Box>
                <Typography style={styles.areaName}>{selectedArea?.name}</Typography>
              </Box>
              <Box style={styles.areaIntro}>
                <Typography style={{color: '#808080'}}>{t('Filter-I1') /*'Please go to Area Tabs to change searching area'*/}</Typography>
              </Box>
            </Box>
          {/* </Button> */}
          {/* <Button style={{paddingVertical: 5}}> */}
          <Box style={styles.catagoryContainer}>
            <Box style={styles.catagoryItems}>
              <WcIcon name='venus-mars' size={25} />
              <Typography style={styles.catagoryTitle}>{t('Filter-SearchGender') /*"Partner's Gender"*/}</Typography>
            </Box>
            <Box style={styles.catagoryList}>
              <FormControlLabel
                label={t('male') /*"male"*/} 
                control={
                  <Checkbox
                    checked={intermediateFilter.showGender === 'male'}
                    // onChange={handleChange1}
                    onClick={() => {setIntermediateFilter({...intermediateFilter, showGender: 'male'})}}
                  />
                }
              />
              <FormControlLabel
                label={t('female') /*"female"*/} 
                control={
                  <Checkbox
                    checked={intermediateFilter.showGender === 'female'}
                    // onChange={handleChange1}
                    onClick={() => {setIntermediateFilter({...intermediateFilter, showGender: 'female'})}}
                  />
                }
              />
              <FormControlLabel
                label={t('everyone') /*"everyone"*/} 
                control={
                  <Checkbox
                    checked={intermediateFilter.showGender === 'everyone'}
                    // onChange={handleChange1}
                    onClick={() => {setIntermediateFilter({...intermediateFilter, showGender: 'everyone'})}}
                  />
                }
              />
            </Box>
          </Box>
          {/* </Button> */}
          {/* <Button style={{paddingVertical: 5}}> */}
          <Box style={styles.catagoryContainer}>
            <Box style={styles.catagoryItems}>
              <PersonIcon size={25} />
              <Typography style={styles.catagoryTitle}>{t('Filter-AgeRange') /*'Age range'*/}</Typography>
            </Box>
            {/* <RangeSlider
              boundaryMin={18} boundaryMax={100}
              initValMin={intermediateFilter.lowestAge} initValMax={intermediateFilter.highestAge}
              onValueChange={(min, max) => setIntermediateFilter({...intermediateFilter, lowestAge: min, highestAge: max})}
            /> */}
            <Box style={styles.sliderContainer}>
              <Typography>{intermediateFilter?.ageRange[0]}</Typography>
              <Slider
                value={intermediateFilter?.ageRange}
                onChange={handleAgeRangeChange}
                valueLabelDisplay="auto"
                min={18}
                max={80}
                step={1}
                marks
                valueLabelFormat={(value) => value.toString()}
                style={styles.sliderTrack}
              />
              <Typography>{intermediateFilter?.ageRange[1]}</Typography>
            </Box>
          </Box>
            <Box style={styles.catagoryContainer}>
              <Box style={styles.catagoryItems}>
                <AutoAwesomeIcon size={25} />
                <Typography style={styles.catagoryTitle}>{t('Filter-SpecialZone') /*"Hunter's Pro and Special Zone"*/}</Typography>
              </Box>
              <Typography style={styles.catagoryIntro}>{t('Filter-I2') /*"Below feature is available for Hunter's Pro member and in special zone only"*/}</Typography>
            </Box>
            <Box style={styles.catagoryContainer}>
              <Box style={styles.catagoryItems}>
                <CrisisAlertIcon size={25} />
                <Typography style={styles.catagoryTitle}>{t('Filter-VerifiedSeeOnly') /*"Who can see me"*/}</Typography>
              </Box>
              <Typography style={styles.catagoryIntro}>{t('Filter-I3') /*"Control who is allowed to see your profile"*/}</Typography>
              <Box style={styles.catagoryList}>
                <FormControlLabel
                  label={t('Filter-I3-O1') /*"Everyone"*/} 
                  control={
                    <Checkbox
                      checked={intermediateFilter.verifiedSeeOnly === false}
                      // onChange={handleChange1}
                      onClick={() => {
                        !isProMember && selectedArea?.type !== 'polygon' 
                        ? setOpenSubscription(!openSubscription)
                        : setIntermediateFilter({...intermediateFilter, verifiedSeeOnly: false})
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={t('Filter-I3-O2') /*"Just person who are verified"*/} 
                  control={
                    <Checkbox
                      checked={intermediateFilter.verifiedSeeOnly === true}
                      // onChange={handleChange1}
                      onClick={() => {
                        !isProMember && selectedArea?.type !== 'polygon' 
                        ? setOpenSubscription(!openSubscription)
                        : setIntermediateFilter({...intermediateFilter, verifiedSeeOnly: true})
                      }}
                    />
                  }
                />
              </Box>
            </Box>
            <Box style={styles.catagoryContainer}>
              <Box style={styles.catagoryItems}>
                <ManageSearchIcon size={25} />
                <Typography style={styles.catagoryTitle}>{t('Filter-OnlySeeVerified') /*"I want to see"*/}</Typography>
              </Box>
              <Typography style={styles.catagoryIntro}>{t('Filter-I4') /*"Control which type of profile you want to see"*/}</Typography>
              <Box style={styles.catagoryList}>
                <FormControlLabel
                    label={t('Filter-I4-O1') /*"Everyone"*/} 
                    control={
                      <Checkbox
                        checked={intermediateFilter.onlySeeVerified === false}
                        // onChange={handleChange1}
                        onClick={() => {
                          !isProMember && selectedArea?.type !== 'polygon' 
                          ? setOpenSubscription(!openSubscription)
                          : setIntermediateFilter({...intermediateFilter, onlySeeVerified: false})
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t('Filter-I4-O2') /*"Just person who are verified"*/} 
                    control={
                      <Checkbox
                        checked={intermediateFilter.verifiedSeeOnly === true}
                        // onChange={handleChange1}
                        onClick={() => {
                          !isProMember && selectedArea?.type !== 'polygon' 
                          ? setOpenSubscription(!openSubscription)
                          : setIntermediateFilter({...intermediateFilter, onlySeeVerified: true})
                        }}
                      />
                    }
                  />
              </Box>
            </Box>
            <MBTIModal
              visible={mbtiVisible}
              changeVisible={setMbtiVisible}
              t={t}
            />
            <Box style={styles.catagoryContainer}>
              <Box style={styles.catagoryItems}>
                <FilterListIcon size={25} />
                <Typography style={styles.catagoryTitle}>{t('Filter-Interests') /*"Sorting Interests"*/}</Typography>
              </Box>
              <Typography style={styles.catagoryIntro}>{t('Filter-I5') /*"Place people with the same interests as you on top. Matching preferences will show orange color. (This function requires your interests filled in)"*/}</Typography>
              <Box style={styles.catagoryList}>
                {/* Remember change isProMember to !isProMember */}
                <FormControlLabel
                    label={t('Filter-I5-O1') /*"No sorting"*/} 
                    control={
                      <Checkbox
                        checked={intermediateFilter.sortInterests === false}
                        // onChange={handleChange1}
                        onClick={() => {
                          !isProMember && selectedArea?.type !== 'polygon' 
                          ? setOpenSubscription(!openSubscription)
                          : setIntermediateFilter({...intermediateFilter, sortInterests: false})
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t('Filter-I5-O2') /*"See people have same interests first"*/} 
                    control={
                      <Checkbox
                        checked={intermediateFilter.sortInterests === true}
                        // onChange={handleChange1}
                        onClick={() => {
                          !isProMember && selectedArea?.type !== 'polygon' 
                          ? setOpenSubscription(!openSubscription)
                          : setIntermediateFilter({...intermediateFilter, sortInterests: true})
                        }}
                      />
                    }
                  />
              </Box>
            </Box>
            <Box style={styles.catagoryContainer}>
              <Button 
                style={{...styles.catagoryItems, alignItems: 'center'}}
                onClick={() => setMbtiVisible(!mbtiVisible)}
              >
                <FilterListIcon size={25} />
                <Typography style={styles.catagoryTitle}>{t('Filter-Personality') /*"Sorting Personality"*/}</Typography>
                <ErrorOutlineIcon 
                  size={18} 
                  color='black' 
                  style={{paddingLeft: 5}}
                  // onClick={() => setMbtiVisible(!mbtiVisible)}
                />
              </Button>
              <Typography style={styles.catagoryIntro}>{t('Filter-I6') /*"Place people with the most suitable personality for you on top. (This function requires your personality filled in)"*/}</Typography>
              <Box style={styles.catagoryList}>
                <FormControlLabel
                  label={t('Filter-I6-O1') /*"No sorting"*/} 
                  control={
                    <Checkbox
                      checked={intermediateFilter.sortPersonality === false}
                      // onChange={handleChange1}
                      onClick={() => {
                        !isProMember && selectedArea?.type !== 'polygon' 
                        ? setOpenSubscription(!openSubscription)
                        : setIntermediateFilter({...intermediateFilter, sortPersonality: false})
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={t('Filter-I6-O2') /*"See most suitable personality first"*/} 
                  control={
                    <Checkbox
                      checked={intermediateFilter.sortPersonality === true}
                      // onChange={handleChange1}
                      onClick={() => {
                        !isProMember && selectedArea?.type !== 'polygon' 
                        ? setOpenSubscription(!openSubscription)
                        : setIntermediateFilter({...intermediateFilter, sortPersonality: true})
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          {/* </Button> */}
          <Box sx={{paddingTop: 5, paddingBottom: 5}}></Box>
        </Box>
      </div>
    </Container>
  )
}

export default FilterScreen

