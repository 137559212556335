import React from 'react';
import { Box, IconButton } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';

const CallButtons = ({
  localStream,
  startCall,
  callEnable,
  setCallEnable,
  cancel,
  callButtonsStyles,
}) => {
  return (
    <Box style={callButtonsStyles.container}>
      {localStream && callEnable ? (
        <>
          <IconButton
            onClick={() => cancel()}
            style={{ backgroundColor: 'red', color: 'white' }}
            variant="contained"
          >
            <CallEndIcon />
          </IconButton>
          <IconButton
            style={{ backgroundColor: 'green', color: 'white' }}
            onClick={() => {
              startCall();
              setCallEnable(!callEnable);
            }}
            variant="contained"
          >
            <CallIcon />
          </IconButton>
        </>
      ) : null}
    </Box>
  );
};

export default CallButtons;
