import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { 
  useJsApiLoader, GoogleMap, Polygon, DirectionsRenderer, /*useLoadScript, LoadScript, MarkerF,*/ Marker 
} from '@react-google-maps/api'; // Replace with your Google Maps library
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DirectionsIcon from '@mui/icons-material/Directions'; // Use the appropriate Material-UI icon component
// import MapViewDirections from 'react-map-gl-directions'; // Replace with your map directions library
import { findNearest, isPointInPolygon } from 'geolib';
import { AuthContext } from '../../context/AuthProvider';
import { LocationContext } from '../../context/LocationProvider';
// import Constants from 'expo-constants';
import carousel_bg from '../../assets/carousel2.jpg';
import { Box, Skeleton } from '@mui/material';
import locationImage from '../../assets/location.png'


export default function Map ({ style/*, specialZones, status, position*/ }) {
  const { t } = useContext(AuthContext);
  const { specialZones, status, position } = useContext(LocationContext);
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const [origin, setOrigin] = useState({
  //   lat: position.latitude,
  //   lng: position.longitude
  // });
  // const position = {
  //   lat: 20.861777235105347, 
  //   lng: 106.66999391291871
  // };
  const origin = useMemo(() => ({ lat: position.latitude, lng: position.longitude }), [position]);
  const [directionsResponse, setDirectionsResponse] = useState(null)
  // const [destination, setDestination] = useState();
  const _map = useRef();
  const _carousel = useRef();

  const styles = {
    container: {
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
      // height: isMobile ? '70vh' : '100vh',
    },
    cardContainer: {
      backgroundColor: 'whitesmoke',
      height: 220,
      width: '100%',
      // padding: 10,
      borderRadius: 10,
      flex: 1,
      boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
      display: 'flex'
    },
    infoBox: {
      position: 'absolute',
      bottom: 0,
      height: 120,
      width: '100%',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      justifyContent: 'space-evenly',
      // padding: 5,
      // display: 'flex',
      // flexDirection: 'row'
    },
    cardImage: {
      height: 100,
      width: '100%',
      top: 0,
      position: 'absolute',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    cardTitle: {
      color: 'black',
      fontSize: 16,
      fontWeight: 'bold',
    },
    direction: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 20,
      width: 120,
      // height: 30,
      padding: 5,
      justifyContent: 'space-evenly',
      // cursor: 'pointer',
    },
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_APIKEY,
    // libraries: ['places'],
  })

  // const [map, setMap] = useState(null)

  const onLoad = useCallback((map) => (
    _map.current = map
    // setMap(map)
    ), []);

  const onUnmount = useCallback(function callback(map) {
    // setMap(null)
    _map.current = null;
  }, [])

  const renderCarouselItem = ({ item }) => (
    <div style={styles.cardContainer}>
      <img
        style={styles.cardImage}
        src={carousel_bg}
        alt="Carousel Background"
      />
      <div style={styles.infoBox}>
        <p style={styles.cardTitle}>{item.name}</p>
        <button
          disabled={isPointInPolygon(position, item.coordinates)}
          style={{
            ...styles.direction,
            backgroundColor: isPointInPolygon(position, item.coordinates)
              ? 'grey'
              : '#3364c8',
          }}
          onClick={() => calculateRoute(item.coordinates)}
          // onClick={() => {
          //   setOrigin(position);
          //   setDestination(findNearest(position, item.coordinates));
          // }}
        >
          <DirectionsIcon color="action" fontSize="small" />
          <span style={{ color: 'white', fontSize: 14 }}>{t('DirectionButton')}</span>
        </button>
      </div>
    </div>
  );

  const calculateRoute = async (coordinates) => {
    if (!origin || !coordinates || !coordinates.length) {
      return
    }
    const nearest = findNearest(origin, coordinates)
    const destination = {
      lat: nearest.latitude,
      lng: nearest.longitude
    }
    // console.log(destination);
    // eslint-disable-next-line no-undef
    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
    // setDistance(results.routes[0].legs[0].distance.text)
    // setDuration(results.routes[0].legs[0].duration.text)
  }

  // const locationIcon = {
  //   url: locationImage, // Replace with the path to your custom icon
  //   scaledSize: new window.google.maps.Size(20, 20), // eslint-disable-next-line no-undef
  // };

  const onCarouselItemChange = (index) => {
    let polygon = specialZones[index];
    let polygonCoord = {
      lat: polygon.coordinates[0].latitude,
      lng: polygon.coordinates[0].longitude,
    }
    _map.current.panTo(polygonCoord)

  };

  const onPolygonPressed = (polygon, index) => {
    let polygonCoord = {
      lat: polygon.coordinates[0].latitude,
      lng: polygon.coordinates[0].longitude,
    }
    _map.current.panTo(polygonCoord)
    // _carousel.current.slideTo(index);
  };

  if(!isLoaded) {
    return <Skeleton />
  }

  // console.log('revert');
  return (
    <div style={{ ...styles.container, ...style }}>
      <GoogleMap
        ref={_map}
        onLoad={onLoad}
        center={/*center*/ {
          lat: origin && origin.lat ? origin.lat : -3.745,
          lng: origin && origin.lng ? origin.lng : -38.523,
        }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        zoom={13}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        onUnmount={onUnmount}
      >
        <Marker
          position={origin}
          icon={{
            url: locationImage, // Replace with the path to your custom icon
            scaledSize: new window.google.maps.Size(20, 20), // eslint-disable-next-line no-undef
          }}
        />
        {specialZones.length
          ? specialZones.map((polygon, index) => {
            const coords = polygon?.coordinates.length ? polygon?.coordinates.map((coordinate) => ({
              lat: coordinate?.latitude,
              lng: coordinate?.longitude
            })) : [];

            return (
              <Polygon
                key={polygon?.id}
                path={coords}
                onClick={() => onPolygonPressed(polygon, index)}
                options={{
                  // fillColor: 'rgba(100, 100, 200, 0.3)',
                  fillColor: 'rgb(102, 140, 255)',
                  zIndex: 1
                }}
              />
            )})
          : null}

        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
        <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
          <Box
            sx={{
              position: 'absolute',
              bottom: 10,
              width: { xs: '80%', md: '40%'},
              zIndex: 1, // Ensure the carousel is on top
            }}
          >
            {specialZones && status ? (
              <Carousel
                ref={_carousel}
                itemsToShow={3}
                emulateTouch
                // className={styles.carousel}
                showThumbs={false}
                onChange={onCarouselItemChange}
              > 
                {specialZones.map((zone) => (
                  <div 
                    key={zone.id} 
                    // style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}
                  >{renderCarouselItem({ item: zone })}</div>
                ))}
              </Carousel>
            ) : null}
          </Box>
        </div>
      </GoogleMap>
    </div>
  );
};

// export default Map;


