import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { firestore } from '../firebase';
import useCollection from '../hooks/useCollection';
import useSubCollection from '../hooks/useSubCollection'
import { LocationContext } from './LocationProvider';
import { AuthContext } from './AuthProvider';
import useHomeFilter from '../hooks/useHomeFilter';
import useLikeFilter from '../hooks/useLikeFilter';

export const SelectAreaContext = createContext({});

const SelectAreaProvider = ({ children }) => {
  const {
    user: { id, huntingTime, ageRange, showGender, verifiedStatus, onlySeeVerified, sortInterests, sortPersonality, interests, personality },
  } = useContext(AuthContext);
  const { areas, isProMember } = useContext(LocationContext);
  const navigate = useNavigate(); // Use history for navigation
  const [seenMessage, setSeenMessage] = useState(false);
  const [selectedAreaId, setSelectedAreaId] = useState('');
  const [selectedUsersId, setSelectedUsersId] = useState([]);
  const [selectedMatchId, setSelectedMatchId] = useState('');
  // const [selectedUsers, setSelectedUsers] = useState();
  const [homeSwipe, setHomeSwipe] = useState([]);
  const [likeSwipe, setLikeSwipe] = useState([]);
  const [openSubscription, setOpenSubscription] = useState(false); 
  const [openTicket, setOpenTicket] = useState(false);
  const [openIntro, setOpenIntro] = useState(false);
  const [openTimeExtend, setOpenTimeExtend] = useState(false);
  const connecting = useRef(false);

  useEffect(() => {
    const getLikedAndPassedData = async () => {
      if (!id) {
        setSelectedUsersId([]);
        return;
      }
      const liked = await firestore()
        .collection(`users/${id}/liked`)
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.id));
      const passed = await firestore()
        .collection(`users/${id}/passed`)
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.id));
      setSelectedUsersId([...liked, ...passed, id]);
    };

    getLikedAndPassedData().catch((error) => console.error(error));
  }, [id]);

  useEffect(() => {
    if (areas.length === 0 || (selectedAreaId && areas.some((area) => area.id === selectedAreaId))) {
      return;
    }
    setSelectedAreaId(areas[0]?.id);
  }, [areas]);

  useEffect(() => {
    if (!Array.isArray(areas) || !areas.some((area) => area.type === 'polygon') || firestore.Timestamp.now().valueOf() > huntingTime.valueOf() || isProMember || !id) {
      return;
    }
    const timeOut1 = setTimeout(async () => {
      const polygon = areas.find((area) => area?.type === 'polygon');
      await firestore().doc(`users/${id}`).update({
        accessAreas: firestore.FieldValue.arrayRemove(polygon?.id),
      });
      polygon?.id === selectedAreaId ? setSelectedAreaId(areas[0]?.id) : void 0;
    }, huntingTime.toMillis() - firestore.Timestamp.now().toMillis());

    const timeOut2 = setTimeout(() => {
      seenMessage ? void 0 : setOpenTimeExtend(!openTimeExtend);
    }, huntingTime.toMillis() - firestore.Timestamp.now().toMillis() - (5 * 60 * 1000));

    return () => {
      clearTimeout(timeOut1);
      clearTimeout(timeOut2);
    };
  }, [areas, selectedAreaId, huntingTime, isProMember, id]);

  // Rest of your code remains unchanged

  const selectedArea = useMemo(
    () => areas.find((area) => area.id === selectedAreaId) || {},
    [areas, selectedAreaId]
    );

  const membersConditions = useMemo(() => {
    return {
      fieldName: 'accessAreas',
      operator: 'array-contains',
      compareValue: selectedArea?.id,
      limit: 10000
    };
  }, [selectedArea?.id]);

  const members = useCollection('users', membersConditions);

  const isLikedCollections = useMemo(() => {
	  return {
      collection: 'users',
      doc: id,
      subcollection: 'isliked',
	  };
  }, [id]);
  
  const isliked = useSubCollection(isLikedCollections);

  const matchesConditions = useMemo(() => {
	  return {
		  fieldName: 'usersMatched',
		  operator: 'array-contains',
		  compareValue: id,
		  limit: 10000
	  };
  }, [id])

  const matches = useCollection('matches', matchesConditions);

  const selectedMatch = useMemo(
	  () => matches.find((match) => match.id === selectedMatchId) || {},
	  [matches, selectedMatchId]
	);

  const { 
    profiles, profilesIndex, setProfilesIndex, profilesChildRefs, profilesIndexRef 
  } = useHomeFilter(members, selectedUsersId, ageRange, showGender, verifiedStatus, onlySeeVerified, selectedArea, sortInterests, sortPersonality, interests, personality, isProMember, homeSwipe)

  const { 
    isLikedProfiles, likedIndex, setLikedIndex, likedChildRefs, likedIndexRef 
  } = useLikeFilter(isliked, likeSwipe)

  useEffect(() => {
	  if (matches.length === 0 || !id) {
		  return;
	  }
	  const unsubscribe = firestore().collection(`rooms`)
	    .where(firestore.FieldPath.documentId(), 'in', Array.from(matches, match => match?.id))
	    .onSnapshot(async (snapshot) => {
			  snapshot?.docChanges()?.forEach((change) => {
          if (change.doc.exists && change.type !== 'removed') {
            let matchDetails = {...change.doc.data(), id: change.doc.id};
            console.log(matchDetails)
            if (matchDetails.offer && matchDetails.callerId !== id && !connecting.current) {
              // navigate("/receive", {matchDetails: matchDetails})
              navigate('/receive', {state: { matchDetails }});
            }
          }
			  })
		  })
	
		  return unsubscribe;
	}, [matches, id])


  // console.log(window.location.pathname)
  
  const memo = useMemo(() => ({
    selectedUsersId, 
    selectedAreaId, 
    setSelectedAreaId,
    seenMessage,
    setSeenMessage,
    selectedArea,
    isliked,
    members, 
    matches,
    selectedMatchId,
    setSelectedMatchId,
    selectedMatch,
    connecting,
    profiles, 
    profilesIndex, 
    setProfilesIndex, 
    profilesChildRefs, 
    profilesIndexRef,
    homeSwipe, 
    setHomeSwipe,
    // selectedUsers, 
    // setSelectedUsers,
    isLikedProfiles, 
    likedIndex, 
    setLikedIndex, 
    likedChildRefs, 
    likedIndexRef,
    likeSwipe,
    setLikeSwipe,
    openSubscription, 
    setOpenSubscription,
    openTicket, 
    setOpenTicket,
    openIntro, 
    setOpenIntro,
    openTimeExtend, 
    setOpenTimeExtend
  }), [ selectedUsersId, selectedAreaId, seenMessage, selectedArea, isliked, members, matches, selectedMatchId, selectedMatch, connecting, profiles, profilesIndex, profilesChildRefs, profilesIndexRef,/* selectedUsers,*/ homeSwipe, isLikedProfiles, likedIndex, likedChildRefs, likedIndexRef, likeSwipe, openSubscription, openTicket, openIntro, openTimeExtend ]);

  return (
    <SelectAreaContext.Provider
      value={memo /* {selectedUsersId, selectedAreaId, setSelectedAreaId, seenMessage, setSeenMessage, selectedArea, isliked, members, matches, selectedMatchId, setSelectedMatchId, selectedMatch, connecting} */}
    >
      {children}
    </SelectAreaContext.Provider>
  );
};

export default SelectAreaProvider;
