import React, { useContext, useState, useEffect } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Typography,
} from '@mui/material'; // Replace with appropriate Material-UI components
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import VideocamIcon from '@mui/icons-material/Videocam';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AuthContext } from '../../context/AuthProvider';
import { SelectAreaContext } from '../../context/SelectAreaProvider';
import getMatchedUserInfo from '../../lib/getMatchedUserInfo';
import ReportModal from './ReportModal';
import { firestore } from '../../firebase';

const Header = ({ navigation }) => {
  const navigate = useNavigate();
  const { selectedMatch } = useContext(SelectAreaContext);
  const {
    user: { id, displayName },
    t,
  } = useContext(AuthContext);
  const [matchedUserInfo, setMatchedUserInfo] = useState(
    //selectedMatch ? getMatchedUserInfo(selectedMatch?.users, id) : {}
    {}
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [toggle, setToggle] = useState(
    // selectedMatch && selectedMatch?.callEnabled[id] ? selectedMatch?.callEnabled[id] : false
    selectedMatch?.callEnabled?.[id] || false
  );
  const [reportVisible, setReportVisible] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    firestore().doc(`matches/${selectedMatch?.id}`).update({
      [`callEnabled.${id}`]: toggle
    })
    setIsDialogOpen(false);
  };

  const openCallScreen = () => {
    handleDialogClose();
    // navigate('/call', { matchDetails: matchDetails });
    navigate('/call', {state: { matchDetails: selectedMatch }});
  };

  useEffect(() => {
    if (!selectedMatch) return;
    setMatchedUserInfo(getMatchedUserInfo(selectedMatch?.users, id))
  }, [selectedMatch, id])

  return (
    <div style={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ padding: 2 }}>
        <ChevronLeftIcon
          fontSize="large"
          style={{ color: '#FF5864', cursor: 'pointer' }}
          onClick={() => {
            // setSelectedMatchId('null');
            navigation('chat');
            // navigate('../chat');
          }}
        />
      </div>
      <div style={{ ...styles.chatAvatar/*, marginRight: 100*/ }}>
        <div
          style={{
            borderRadius: 30,
            borderWidth: 0.5,
            cursor: 'pointer',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Avatar
            size="large"
            src={matchedUserInfo?.photoURL ? matchedUserInfo?.photoURL[0]?.uri : 'N'}
            onClick={() => {
              navigation('info')
              // navigate('/info', {state: { info: matchedUserInfo }});
            }}
          />
          {matchedUserInfo?.isOnline ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              sx={{ position: 'absolute', top: 4, right: 4 }}
            />
          ) : null}
        </div>
        <Typography
          variant="subtitle2"
          style={{ paddingTop: 5, fontSize: 12, fontWeight: '500' }}
        >
          {matchedUserInfo?.displayName}
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', padding: 2 }}>
        <div style={{ ...styles.callButton, marginRight: 0 }}>
          <VideocamIcon
            fontSize="large"
            style={{ color: '#3399ff', cursor: 'pointer' }}
            onClick={ selectedMatch && selectedMatch.id ? handleDialogOpen : null /*alert('No activity available')*/}
          />
        </div>
        <div style={{ ...styles.callButton, marginRight: 0 }}>
          <MoreVertIcon
            fontSize="large"
            style={{ color: 'black', cursor: 'pointer' }}
            onClick={() => selectedMatch && selectedMatch.id ? setReportVisible(!reportVisible) : null}
          />
          <ReportModal
            visible={reportVisible}
            changeVisible={setReportVisible}
            t={t}
            id={id}
            displayName={displayName}
            reportedId={matchedUserInfo?.id}
            reportedName={matchedUserInfo?.displayName}
          />
        </div>
      </div>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{t('Message-VCTitle') /*'Video Call'*/}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Message-VCInstuction1') /*'Try to see if you are meant to be together. When you both agree, you will enable video call'*/}</DialogContentText>
          <DialogContentText>
            {`${t('Message-VCInstuction2') /*'I want to use chat video with'*/} ${matchedUserInfo?.displayName}.`}
          </DialogContentText>
          <DialogContentText color="grey">{t('Message-VCInstuction3') /*'You can change your mind any time'*/}</DialogContentText>
          <Switch
            color="primary"
            checked={toggle}
            onChange={() => {
              setToggle(!toggle);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            {t('DoneButton') /*'Done'*/}
          </Button>
          <Button
            onClick={openCallScreen}
            color="primary"
            disabled={
              selectedMatch.callEnabled 
              ? (!selectedMatch?.callEnabled[id] || !selectedMatch?.callEnabled[matchedUserInfo.id])
              : null
            }
          >
            {t('StartCallButton') /*'Start Call'*/}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;

const styles = {
  callButton: {
    padding: 4,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    cursor: 'pointer',
  },
};
