import React from 'react';
import AuthProvider from './context/AuthProvider';
import LocationProvider from './context/LocationProvider';
import SelectAreaProvider from './context/SelectAreaProvider';
import AppRouter from './navigation/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// Vấn đề của android bị reload nằm ở đây

export default function App() {
  return (
    <div className="App">
      {/* <h1>Let's build Hunter's</h1> */}
      <BrowserRouter>
        <AuthProvider>
          <LocationProvider>
            <SelectAreaProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AppRouter />
              </LocalizationProvider>
            </SelectAreaProvider>
          </LocationProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

