import React, { useContext, useState } from 'react';
import {
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  CssBaseline,
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { auth, firestore } from '../firebase';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'


const defaultTheme = createTheme();

const AddBirthdayScreen = ({ navigation }) => {
  const { t, info, setInfo } = useContext(AuthContext);
  const [message, setMessage] = useState(t('AddBirthday-Instruction'));
  const [onpress, setOnpress] = useState(false);

  // const navigate = useNavigate();

  const classes = {
    container: {
      // padding: '16px', //theme.spacing(2),
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '90vh',
      backgroundColor: 'white'
    },
  };

  const getNavigation = (data) => {
    if (!data.email) {
      // navigate('add_email');
      navigation('email');
      return;
    } else if (!data.phoneNumber) {
      // navigate('phone_number');
      navigation('phone');
      return;
    } else if (!data.displayName) {
      // navigate('add_name');
      navigation('name');
      return;
    } else if (!data.birthday) {
      // navigate('add_birthday');
      navigation('birthday');
      return;
    } else if (!data?.gender) {
      // navigate('add_sexual');
      navigation('sexual');
      return;
    } else if (!data.photoURL || data?.photoURL?.length === 0) {
      // navigate('add_photo');
      navigation('photo');
      return;
    } else {
      // navigate('add_location');
      navigation('location');
      return;
    }
  };

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  const getAge = (/*dateString*/ dateObject) => {
    const today = new Date();
    const birthDate = /*new Date(dateString)*/ dateObject.$d ;
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };


  const handleDateChange = (newDate) => {

    if (!newDate) {
      alert(t('AddBirthday-EnterBirthday'));
    }

    const user_age = getAge(newDate);
    if (user_age <= 18) {
      setMessage(t('AddBirthday-InvalidAge'));
      setOnpress(false);
      return;
    }

    setMessage(t('AddBirthday-ValidAge'));

    // Convert the selected date to a Firestore Timestamp
    const firestoreTimestamp = firestore.Timestamp.fromDate(newDate.$d);
    setInfo({
      ...info,
      birthday: firestoreTimestamp,
      age: user_age,
    });
    setOnpress(true);
    // console.log(info);
  };

  const Handlebutton = () => {
    if (onpress) {
      getNavigation(info);
    }
  };


  return (
    <div 
    style={{ 
      flex: 1, 
      // padding: 20, https://source.unsplash.com/random?wallpapers
      backgroundImage: `url(${loginImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: (t) =>
        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <ThemeProvider theme={defaultTheme} >
        <Container 
          component="main"
          maxWidth="xs"
          // style={classes.container}
          sx={{ height: '100vh', backgroundColor: 'white' }}
        >
          <CssBaseline />
          <IconButton sx={{ position: 'relative', flexDirection: 'row-reverse' }} onClick={navigateToHome}>
            <CloseIcon />
          </IconButton>
          <Box sx={classes.container}>
            <Typography sx={{fontSize: 30}} variant="h4" >{t('AddBirthday-EnterBirthday')}</Typography>
            <Box sx={{width: '100%', justifyContent: 'center'}}>
              <DatePicker
                label="Birthday"
                sx={{width: '100%'}}
                onChange={handleDateChange}
              />
              <Typography variant="body1" sx={{ marginTop: '10px' }}>
                {message}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={Handlebutton}
              disabled={!onpress}
            >
              {t('ContinueButton')}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default AddBirthdayScreen;
