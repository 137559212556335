import React, { useState } from 'react'
import Map from '../components/MapComponents/Map';
import MatchingArea from '../components/MapComponents/MatchingArea';
import { Button, Box, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const EventScreen = () => {
  // const { areas } = useContext(LocationContext);
  const [flexbox, setFlexbox] = useState(80)
  // const [openDrawer, setOpenDrawer] = useState(true);
  // const { specialZones, status, position } = useContext(LocationContext);

  const toggleBarHeight = () => {
    if (flexbox === 50) {
      setFlexbox(80); // Set to the desired height value when expanding
    } else {
      setFlexbox(50); // Set to the original height value when collapsing
    }
  }
  // const toggleBarHeight = () => {
  //   setFlexbox(flexbox === 0.1 ? flexbox + flexbox * (areas?.length + 0.1) : 0.1)
  //   // setOpenDrawer(!openDrawer)
  // }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = {
    header: {
      backgroundColor: '#FFFFFF',
      shadowColor: '#333333',
      shadowOffset: {width: -1, height: -3},
      shadowRadius: 2,
      shadowOpacity: 0.4,
      // elevation: 5,
      paddingTop: 20,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    panelHeader: {
      alignItems: 'center',
    },
    panelHandle: {
      width: 40,
      height: 8,
      borderRadius: 4,
      backgroundColor: '#00000040',
      marginBottom: 10,
    },
    panelTitle: {
      fontSize: 27,
      height: 35,
    },
  }
  // console.log('revertEvent')

  return (
    <Box sx={{ height: '90vh', overflow: 'hidden', flexDirection: 'column'}}>
      <Box sx={{ overflow: 'auto', height: `${flexbox}vh` }}>
        {/* Custom Map Component */}
        <Map 
          // position={position}
          // specialZones={specialZones}
          // status={status}
        />
      </Box>
      <Box sx={{ overflow: 'auto', height: `${90 - flexbox}vh` }}>
        <Button onClick={toggleBarHeight} sx={{ margin: "0 auto", display: "block" }}>
          {flexbox === 50 ? (
            <KeyboardArrowDownIcon sx={{ fontSize: '30px' }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ fontSize: '30px' }} />
          )}
        </Button>
        <MatchingArea />
      </Box>
    </Box>

    // <Box sx={{ flex: 1, display: "flex", flexDirection: 'column'}}>
    //   <Map style={{height: '85vh'}}/>
    //   <AppBar position='static'>
    //     <Toolbar>
    //       <Button onClick={toggleBarHeight} sx={{ margin: "0 auto", display: "block", color: 'whitesmoke' }}>
    //         Toggle Bar
    //       </Button>
    //     </Toolbar>
    //   </AppBar>
    //   <Drawer open={openDrawer} onClose={toggleBarHeight} anchor={"bottom"}>
    //     <MatchingArea />
    //   </Drawer>
    // </Box>

    // <Box sx={{ flexGrow: 1}}>
    //   <Grid container spacing={3} sx={{flexDirection: isMobile ? 'column-reverse': 'row'}}>
    //     <Grid item xs={isMobile ? 12 : 3}>
    //       { isMobile ? (
    //         <div
    //           style={{
    //             position: 'relative',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             display: 'flex',
    //           }}
    //         >
    //           <Button onClick={toggleBarHeight}>
    //             Toggle Bar
    //           </Button>
    //         </div>
    //       ) : null }
    //       <MatchingArea />
    //       { !isMobile ? (
    //         <div
    //           style={{
    //             position: 'relative',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             display: 'flex',
    //           }}
    //         >
    //           <Button onClick={toggleBarHeight}>
    //             Toggle Bar
    //           </Button>
    //         </div>
    //       ) : null }
    //     </Grid>
    //     <Grid item xs={isMobile ? 12 : 9} >
    //       <Map style={{width: '100%'}}/>
    //     </Grid>
    //   </Grid>
    // </Box>
  )
}

export default EventScreen
