import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthContext } from './AuthProvider';
import { firestore, database } from '../firebase';
import useAllAreas from '../hooks/useAllArea';
// import useRevenueCat from '../hooks/useRevenueCat';
// import useNotifications from '../hooks/useNotifications';
import { findNearest, getPreciseDistance, isPointInPolygon } from 'geolib';

export const LocationContext = createContext({});

const LocationProvider = ({ children }) => {
  const {
    user: { id, accessAreas, polygons },
  } = useContext(AuthContext);
  const [status, setStatus] = useState(false);
  const [position, setPosition] = useState({});
  const foregroundSubscriptionRef = useRef(null);
  const coordinateRef = useRef();
  const disconnectRef = useRef();

  useEffect(() => {
    const connectedRef = database().ref('.info/connected');
    if (!id) {
      connectedRef.off('value');
      return;
    }

    // const connectedRef = database().ref('.info/connected');
    const userStatusDatabaseRef = database().ref(`/status/${id}`);

    const onValueChange = (snapshot) => {
      if (snapshot?.val() === false) {
        console.log('not connected');
        return;
      }
      disconnectRef.current = userStatusDatabaseRef.onDisconnect().set({
        state: 'offline',
        last_changed: database.ServerValue.TIMESTAMP,
      })
      .then(() => userStatusDatabaseRef.set({
        state: 'online',
        last_changed: database.ServerValue.TIMESTAMP,
      }))
      .catch((error) => console.error(error));
    };

    connectedRef.on('value', onValueChange);

    return () => {
      connectedRef.off('value', onValueChange);
    };
  }, [id]);


  useEffect(() => {
    const checkLocationStatus = async () => {
      if (!id) return;
      const granted  = await navigator.permissions.query({ name: 'geolocation' });
      if (granted.state === 'denied') {
        return;
      } else {
        setStatus(true);
      }
    }
  
    checkLocationStatus();
  }, [id])
  


  useEffect(() => {
    const startForegroundUpdate = async () => {
      if (!id || !status) return;
      // const granted  = await navigator.permissions.query({ name: 'geolocation' });
      // console.log(granted)
      // if (granted.state === 'denied') return;
      // if (granted.state !== 'denied') {
      //   setStatus(true);
        // foregroundSubscriptionRef.current?.remove();
        foregroundSubscriptionRef.current = navigator.geolocation.watchPosition(
          (location) => {
            let coordinate = {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude
            };
            if (!id || !location.coords.latitude || !location.coords.longitude || (coordinateRef.current && getPreciseDistance(coordinate, coordinateRef.current) < 15)) {
              // console.log('location not change')
              return;
            }
            // console.log(location.coords)
            firestore().doc(`users/${id}`).update({
              coordinate: coordinate
            });
            setPosition(coordinate);
            coordinateRef.current = coordinate;
          },
          (error) => {
            console.error(error);
            setStatus(false);
          },
          {
            enableHighAccuracy: true,
          }
        );
      // }
    };

    startForegroundUpdate();

    // return () => {
    //   foregroundSubscriptionRef.current && foregroundSubscriptionRef.current?.remove();
    // };
  }, [id, status]);

  // Rest of your code remains unchanged
  // const { currentOffering, customerInfo, isProMember } = useRevenueCat(id);
  // const { notification, expoPushToken } = useNotifications(id)

  const isProMember = false;
  const allAreas = useAllAreas(accessAreas, polygons);

  const areas = useMemo(() => {
    if (!accessAreas || !Array.isArray(accessAreas) || !Array.isArray(allAreas)) {
      return [];
    }
  
    const sortedTypes = {
      country: 1,
      region: 2,
      polygon: 3,
    };
  
    return allAreas
      .filter((area) => accessAreas.includes(area.id) || isPointInPolygon(position, area?.coordinates))
      .sort((area1, area2) => {
        const type1 = area1?.type;
        const type2 = area2?.type;
  
        return sortedTypes[type1] - sortedTypes[type2];
      });
  }, [allAreas, accessAreas, position]);
  

  const specialZones = useMemo(() => {
    if (!polygons || !Array.isArray(polygons) || !Array.isArray(allAreas)) {
      return [];
    }
    // Function to calculate the distance between zone and user's current position
    const calculateDistance = (area) => {
      const areaPosition = findNearest(position, area.coordinates)
      return getPreciseDistance(position, areaPosition);
    };

    return allAreas
      .filter((area) => polygons.includes(area.id))
      .sort((area1, area2) => {
        const distance1 = calculateDistance(area1);
        const distance2 = calculateDistance(area2);
  
        // Sort in ascending order of distance (closest first)
        return distance1 - distance2;
      });
  }, [allAreas, polygons, position]);
  
    const memo = useMemo(() => ({
      status,
      setStatus, 
      position,
      setPosition,
      areas,
      specialZones,
      // currentOffering,
      // customerInfo, 
      isProMember,
      // notification, 
      // expoPushToken
    }), [status, position, areas, specialZones, isProMember/*, currentOffering, customerInfo, isProMember, notification, expoPushToken*/]);

    // console.log(status);
  return (
    <LocationContext.Provider value={memo /* {status, setStatus, position, setPosition, areas, specialZones, currentOffering, customerInfo, isProMember, notification, expoPushToken} */}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
