const compareObjects = (obj1, obj2) => {
    let differences = {};
    let isEqual = true;
    for (let key in obj1) {
        if (obj2.hasOwnProperty(key)) {
            if (obj1[key] !== obj2[key]) {
                // differences[key] = {
                //     original: obj1[key],
                //     modified: obj2[key]
                // };
                differences[key] = obj2[key]
                isEqual = false;
            }
        } else {
            // differences[key] = {
            //     original: obj1[key],
            //     modified: null
            // };
            differences[key] = obj2[key]
            isEqual = false;
        }
    }
    for (let key in obj2) {
        if (!obj1.hasOwnProperty(key)) {
            // differences[key] = {
            //     original: null,
            //     modified: obj2[key]
            // };
            differences[key] = obj2[key]
            isEqual = false;
        }
    }
    return { differences, isEqual };
  }
  
  export default compareObjects;