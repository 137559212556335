import React, { useState, useEffect } from 'react'
import { firestore } from '../firebase';
// import { collection, doc, getDocs, onSnapshot, query, where, orderBy, limit } from 'firebase/firestore';
// import firestore from '@react-native-firebase/firestore';

const useSubCollection = (collections) => {
    const [documents, setDocuments] = useState([]);
    
    useEffect(() => {
			if (!collections.doc || !collections.subcollection) {
        setDocuments([]);
        return;
      }
      // let collectionRef = collection(db, collections?.collection, collections?.doc, collections?.subcollection)
      // let queryRef = query(collectionRef);
      let collectionRef = firestore()
        .collection(`${collections.collection}/${collections.doc}/${collections.subcollection}`)
        .orderBy('createdAt')
      // if (condition) {
      //   if (!condition.compareValue || !condition.compareValue.length || !collections.doc || !collections.subcollection || collections.limit) {
      //     // reset documents data
      //     setDocuments([]);
      //     return;
      //   }

      //   queryRef = query(collectionRef, where(
      //     condition.fieldName,
      //     condition.operator,
      //     condition.compareValue
      //   ))
      // }
      // if (boundary && boundary > 0) {
      //   // collectionRef = collectionRef, limit(boundary)
      //   queryRef = query(collectionRef, where(
      //     condition.fieldName,
      //     condition.operator,
      //     condition.compareValue
      //   ), limit(boundary))
      // }

      // let queryRef = query(collectionRef);

      // console.log(collectionRef.path);
  
      // const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      //   let documents
      //   documents = snapshot.docs.map((doc) => ({
      //     ...doc.data(),
      //     id: doc.id,
      //   }));
  
      //   setDocuments(documents);
      // });

      const unsubscribe = collectionRef.onSnapshot((snapshot) => {
        const documents = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
  
        setDocuments(documents);
      }, (error) => {
        console.error(error);
      });
  
      return unsubscribe;
    }, [collections]);
  
    return documents;
}

export default useSubCollection