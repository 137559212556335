import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
// import { useNavigate } from 'react-router-dom';

const useAuthentication = (info, setInfo, navigation, loginRef) => {
  // const navigate = useNavigate();

  const GoogleProvider = new auth.GoogleAuthProvider();
  const FacebookProvider = new auth.FacebookAuthProvider();

  const getNavigation = (data) => {
    if (!data.email) {
      // navigate('add_email');
      navigation('email');
      return;
    } else if (!data.phoneNumber) {
      // navigate('phone_number');
      navigation('phone');
      return;
    } else if (!data.displayName) {
      // navigate('add_name');
      navigation('name');
      return;
    } else if (!data.birthday) {
      // navigate('add_birthday');
      navigation('birthday');
      return;
    } else if (!data?.gender) {
      // navigate('add_sexual');
      navigation('sexual');
      return;
    } else if (!data.photoURL || data?.photoURL?.length === 0) {
      // navigate('add_photo');
      navigation('photo');
      return;
    } else {
      // navigate('add_location');
      navigation('location');
      return;
    }
  };

  // Other utility functions like calculateAge can remain the same

  const handleGoogleSignIn = async () => {
    try {
      const result = await auth().signInWithPopup(GoogleProvider);
      const user = result.user;
      const collectedData = {
        ...info,
        id: user.uid,
        email: user.email,
      };
      setInfo(collectedData);
      !loginRef.current ? getNavigation(collectedData) : void 0;
    } catch (error) {
      // Handle Google Sign-In error
      console.error('Google Sign-In Error:', error);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const result = await auth().signInWithPopup(FacebookProvider);
      const user = result.user;
      const collectedData = {
        ...info,
        id: user.uid,
        email: user.email,
      };
      setInfo(collectedData);
      !loginRef.current ? getNavigation(collectedData) : void 0;
    } catch (error) {
      // Handle Facebook Sign-In error
      console.error('Facebook Sign-In Error:', error);
    }
  };

  // Apple Sign-In logic can be added here if needed

  return {
    handleGoogleSignIn,
    handleFacebookSignIn,
    // Add other authentication methods here if needed
  };
};

export default useAuthentication;
