import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Container,
  Typography,
  Box,
  Button,
  LinearProgress,
  Card,
  CardContent,
  CardActions,
  Modal,
  Skeleton,
  useMediaQuery,
  Paper
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import getMatchedUserInfo from '../lib/getMatchedUserInfo';
import { firestore } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';

const MatchScreen = ({ newmatch, visible, changeVisible}) => {
  const { user, t } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  // const { newmatch } = location.state;
  const navigate = useNavigate();
  const [matchDetail, setMatchDetail] = useState(null);

  useEffect(() => {
    setMatchDetail(getMatchedUserInfo(newmatch?.users, user.id));
  }, [newmatch, user.id]);

  const goBack = () => {
    firestore().doc(`matches/${newmatch?.id}`).update({
      seen: firestore.FieldValue.arrayUnion(user?.id)
    })
    .then(() =>  changeVisible(!visible) /*navigate('/home')*/)
    .catch((error) => console.log(error))
  }

  const goChat = () => {
    firestore().doc(`matches/${newmatch?.id}`).update({
      seen: firestore.FieldValue.arrayUnion(user?.id)
    })
    .then(() => changeVisible(!visible) /*navigate('/chat')*/)
    .catch((error) => console.log(error))
  }
  
  // console.log(matchDetail, newmatch)

  if(!matchDetail) {
    return <Skeleton />
  }
  
  return (
    <Modal
      sx={{
        // backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      maxWidth={'md'}
      open={visible}
      onClose={() => {
        goBack();
      }}
      backdropClickThrough={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // opacity: 1,
          justifyContent: 'center',
          width: '100vw',
          minHeight: '100vh',
        }}
        // maxWidth={'sm'}
      >
        {/* <img
          src={ matchDetail?.photoURL[0] ? matchDetail?.photoURL[0].uri : ''}
          alt="Match"
          style={{ height: '100vh', width: '100%', resize: 'block' }}
        /> */}
        <Paper 
          elevation={3} 
          style={{
            width: !isMobile ? '40%' : '100%',
            height: !isMobile ? '90%' : '100%',  // Use 'vh' for viewport height or 'px' for pixels
            backgroundImage: `url(${matchDetail?.photoURL[0].uri})`,
            backgroundSize: 'auto auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(2), // You can adjust the padding as needed
            // backgroundColor: 'white', // Change this to your desired background color
            borderRadius: 10, // Adjust the border radius as needed
            boxShadow: theme.shadows[3], // Add shadow to the paper
          }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: 80,
              color: '#00e64d',
              fontWeight: 'bold',
              position: 'absolute',
              bottom: '40%',
              fontStyle: 'italic',
            }}
          >
            MATCH
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              display: 'flex',
            }}
          >
            <Card
              sx={{
                backgroundColor: 'transparent',
                flexDirection: 'column',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
              }}
            >
              <CardContent>
                <Box sx={{ justifyContent: 'space-evenly', paddingBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Box sx={{ padding: 1 }}>
                    <Typography
                      variant="button"
                      sx={{
                        alignSelf: 'center',
                        color: '#ff0000',
                        fontWeight: 'bold',
                        fontSize: 20
                      }}
                      onClick={goBack}
                    >
                      {t('SendMessageButton') /*'Send Message'*/}
                    </Typography>
                  </Box>
                  <Box sx={{ padding: 1 }}>
                    <Typography
                      variant="button"
                      sx={{
                        alignSelf: 'center',
                        fontWeight: 'bold',
                        fontSize: 20
                      }}
                      onClick={goChat}
                    >
                      {t('GoBackButton') /*'Go Back'*/}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default MatchScreen;