import React, { useContext } from "react";
import { Container, CssBaseline, Box } from "@mui/material";
import Chatlist from "../components/ChatComponents/Chatlist";
import { SelectAreaContext } from "../context/SelectAreaProvider";
import Beliked from "../components/ChatComponents/Beliked";

const ChatScreen = ({ navigation }) => {
  const { matches, isliked } = useContext(SelectAreaContext);

  return (
    <Container 
      maxWidth="md"
    >
      <CssBaseline />
      <Box /*mt={2} sx={{height: '125px'}}*/>
        <Beliked data={isliked} />
      </Box>
      <Box /*mt={2} sx={{height: '140px'}}*/>
        <Chatlist
          style={{ flex: 1, height: '20vh' /*`calc(100vh - 305px)`*/}} 
          datas={matches.filter((match) => match.newAdd)}
          horizontal={true}
          barStyle={false}
          navigation={navigation}
        />
      </Box>
      <Box /*mt={2} sx={{height: `calc(100% - 265px)`}}*/>
        <Chatlist
          style={{ flex: 1, height: '60vh' /*`calc(100vh - 305px)`*/}} // No new match 275px need fix
          datas={matches.filter((match) => !match.newAdd)}
          horizontal={false}
          barStyle={true}
          navigation={navigation}
        />
      </Box>
    </Container>
  );
};

export default ChatScreen;
