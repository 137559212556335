import { Grid, Paper } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';

const VideoFeeds = ({ remoteStream, localStream, videoFeedsStyles }) => {
  return (
    // <div style={videoFeedsStyles.rtcContainer}>
    //   <div style={videoFeedsStyles.rtcviewRemote}>
    //     {remoteStream ? (
    //       <ReactPlayer
    //         url={remoteStream} // Set the appropriate URL for remoteStream
    //         style={videoFeedsStyles.rtcRemote}
    //         width="100%"
    //         height="100%"
    //         playing={true}
    //         controls={true}
    //       />
    //     ) : null}
    //   </div>
    //   <div style={videoFeedsStyles.rtcviewLocal}>
    //     {localStream ? (
    //       <ReactPlayer
    //         url={localStream} // Set the appropriate URL for localStream
    //         style={videoFeedsStyles.rtcLocal}
    //         width="100%"
    //         height="100%"
    //         playing={true}
    //         controls={true}
    //         muted={true}
    //       />
    //     ) : null}
    //   </div>
    // </div>

    <Grid container sx={videoFeedsStyles.rtcContainer}>
      {remoteStream ? (
        <Paper sx={videoFeedsStyles.rtcviewRemote}>
          {/* <Grid item md={12}> */}
            {/* <Typography variant="h5" gutterBottom>{name || 'Name'}</Typography> */}
            <ReactPlayer 
              url={remoteStream} // Set the appropriate URL for localStream
              style={videoFeedsStyles.reactPlayer}
              // width="100%"
              // height="100%"
              playing={true}
              controls={true}
              muted={true}
            />
          {/* </Grid> */}
        </Paper>
      ) : null }
      {localStream ? (
        <Paper sx={videoFeedsStyles.rtcviewLocal}>
          {/* <Grid item  md={12}> */}
            {/* <Typography variant="h5" gutterBottom>{call.name || 'Name'}</Typography> */}
            <ReactPlayer 
              url={localStream} // Set the appropriate URL for localStream
              style={videoFeedsStyles.reactPlayer}
              // width="100%"
              // height="100%"
              playing={true}
              controls={true}
              muted={true}
            />
          {/* </Grid> */}
        </Paper>
      ) : null}
    </Grid>
  );
};

export default VideoFeeds;
