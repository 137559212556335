import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  useMediaQuery
} from "@mui/material";
import { getLocalPCandGetOffer } from "../utilities/getLocalPCandGetOffer";
import { getLocalStream } from "../utilities/getLocalStream";
import ToggleButtons from "../components/CallComponents/ToggleButtons";
import VideoFeeds from "../components/CallComponents/VideoFeeds";
import { firestore } from "../firebase";
import { SelectAreaContext } from "../context/SelectAreaProvider";
import { AuthContext } from "../context/AuthProvider";
import GettingCall from "../components/CallComponents/GettingCall";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const ReceiveCallScreen = (/*{ navigation, route }*/) => {
  // const { matchDetails } = route.params;
  const navigate = useNavigate();
  const location = useLocation();
  const { matchDetails } = location.state;
  const { connecting } = useContext(SelectAreaContext);
  const {
    user: { id, displayName, photoURL }, t
  } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [timeoutId, setTimeoutId] = useState();
  const [gettingCall, setGettingCall] = useState(true);
  const [localStream, setLocalStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const [cachedLocalPC, setCachedLocalPC] = useState();
  const [startCleanUp, setStartCleanUp] = useState(false);
  // const [loading, setLoading] = useState(true);

  const videoFeedsStyles = {
    rtcContainer: {
      justifyContent: 'center',
      alignItems: 'center', 
      [theme.breakpoints.up('lg')]: {
        paddingTop: 20
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      height: '100%',  // Occupy the entire available height
      width: '100%', 
    },
    rtcviewRemote: {
      padding: '10px',
      border: '2px solid black',
      margin: '10px',
      width: 'auto', // isMobile ? '100%' : 'auto',
      height: 'auto', // isMobile ? 'auto' : '100%',
      overflow: 'hidden', // Hide content that overflows the container
    },
    rtcviewLocal: {
      padding: '10px',
      border: '2px solid black',
      margin: '10px',
      width: 'auto', // isMobile ? '100%' : 'auto',
      height: 'auto', // isMobile ? 'auto' : '100%',
      overflow: 'hidden', // Hide content that overflows the container
    },
    reactPlayer: {
      width: '100%',
      height: '100%',
    },
  };
  
  const gettingCallStyles = {
    container: {
      minHeight: '100vh',
      width: "100%",
      alignItems: "center",
      backgroundColor: "#1e90ff",
      justifyContent: "space-evenly",
      borderWidth: 1,
    },
    callerContainer: {
      // alignItems: "center",
      paddingTop: 10,
      height: "100%",
      width: "100%",
      justifyContent: 'center', 
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    callerPhoto: {
      backgroundColor: "white",
    },
    callerName: {
      fontSize: 20,
      color: "white",
      paddingTop: 5,
      fontWeight: "500",
    },
    buttonsContainer: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      paddingTop: 20,
      width: "100%",
      display: 'flex'
    },
    callButton: {},
    cancelButton: {},
  };

  const callButtonsStyles = {
    container: {
      padding: 20,
      paddingBottom: 40,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "auto",
      alignItems: "center",
    },
    buttons: {
      backgroundColor: "#4a4a4a",
      padding: 15,
      borderRadius: 50,
    },
    buttonText: {
      color: "white",
    },
  };
  
  const toggleButtonStyles = {
    container: {
      // container for the buttons to start/join a call
      padding: 10,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      justifyContent: 'space-evenly',
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      
    },
    buttons: {
      backgroundColor: '#4a4a4a',
      padding: 15,
      borderRadius: 50,
    },
  };

  const onBackPress = async () => {
    // await firestoreCleanUp();
    // await streamCleanUp();
    await Promise.all([streamCleanUp(), firestoreCleanUp()]);
    connecting.current = false;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // navigation.goBack();
    // navigate('/chat')
    // window.location.reload();
  };

  const streamCleanUp = async () => {
    console.log(`[+] Start clear cache local PC: ${cachedLocalPC}`)
    if (cachedLocalPC) {
      console.log(`[+] Cache local PC Ice Connection State: ${cachedLocalPC?.iceConnectionState}`)
      cachedLocalPC.getSenders().forEach((sender) => {
        cachedLocalPC.removeTrack(sender);
      });
      cachedLocalPC.close();
      console.log(`[+] Cache local PC Ice Connection State: ${cachedLocalPC?.iceConnectionState}`)
      console.log(`[+] Finish clear cache local PC: ${cachedLocalPC}`)
    }
    console.log(`[+] Start clear local stream: ${localStream}`)
    if (localStream) {
      // console.log(`[+] Start clear local stream: ${localStream}`)
      localStream.getAudioTracks().forEach((track) => track.stop());
      localStream.getVideoTracks().forEach((track) => track.stop());
      localStream.getTracks().forEach((track) => track.stop());
      console.log(`[+] Finish clear local stream: ${localStream}`)
    }
    setLocalStream();
    setRemoteStream();
    setCachedLocalPC();
  };

  const firestoreCleanUp = async () => {
    try {
      const roomRef = firestore().doc(`rooms/${matchDetails?.id}`);
      if (roomRef) {
        const calleeCandidates = await roomRef
          .collection("calleeCandidates")
          .get();
        calleeCandidates.forEach(async (candidate) => {
          await candidate.ref.delete();
        });
        const callerCandidates = await roomRef
          .collection("callerCandidates")
          .get();
        callerCandidates.forEach(async (candidate) => {
          await candidate.ref.delete();
        });
        await roomRef.delete();
      }
    } catch (error) {
      console.log(error);
    }
  };


  const startLocalStream = async () => {
    const newStream = await getLocalStream();
    setLocalStream(newStream);
  };

  useEffect(() => {
    startLocalStream();
    const timeOut = setTimeout(async () => {
      await onBackPress();
    }, 30000);
    setTimeoutId(timeOut);
  }, []);

  const joinCall = async () => {
    connecting.current = true;
    const localPC = await getLocalPCandGetOffer(
      matchDetails?.id,
      id,
      displayName,
      photoURL,
      localStream,
      remoteStream,
      "rooms",
      "calleeCandidates",
      "callerCandidates",
      setRemoteStream
    );
    // console.log(localPC);
    setCachedLocalPC(localPC);
    setGettingCall(!gettingCall);
    clearTimeout(timeoutId);
  };

  useEffect(() => {
    const unsubscribe = firestore()
      .collection(`rooms/${matchDetails?.id}/callerCandidates`).onSnapshot(async (snapshot) => {
        snapshot?.docChanges()?.forEach(async (change) => {
          if (change.type === "removed") {
            // console.log(change.type)
            setStartCleanUp(true);
            // await onBackPress()
            // window.location.reload();
          }
        });
      });

    return unsubscribe;
  }, []);


  useEffect(() => {
    if (startCleanUp) {
      onBackPress().then(() => /*navigate('/chat')*/ window.location.reload()).catch(error => console.error(error))
    }

  }, [startCleanUp])
  


  console.log(cachedLocalPC, localStream);


  return (
    <Grid 
      container 
      alignItems="center" 
      sx={{
        backgroundColor: 'whitesmoke',
      }}
    >
      {gettingCall ? (
        <GettingCall
          displayName={matchDetails?.callerName}
          photoURL={matchDetails?.callerPhotoURL}
          joinCall={joinCall}
          cancel={onBackPress}
          gettingCallStyles={gettingCallStyles}
        />
      ) : (
        <>
          <VideoFeeds
            remoteStream={remoteStream}
            localStream={localStream}
            videoFeedsStyles={videoFeedsStyles}
          />
          {localStream ? (
            <ToggleButtons
              remoteStream={remoteStream}
              localStream={localStream}
              cancel={onBackPress}
              toggleButtonStyles={toggleButtonStyles}
            />
          ) : null}
        </>
      )}
      {/* <Modal open={loading} onClose={() => setLoading(false)}>
        <Box
          style={{
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
          <Typography
            variant="h6"
            style={{ marginTop: 16, fontWeight: "bold" }}
          >
            {/*i18n.t("OTP-Loading") 'Loading'}
          </Typography>
        </Box>
      </Modal> */}
    </Grid>
  );
};

export default ReceiveCallScreen;

// export const gettingCallStyles = {
//   container: {
//     minHeight: '100vh',
//     width: "100%",
//     alignItems: "center",
//     backgroundColor: "#1e90ff",
//     justifyContent: "space-evenly",
//     borderWidth: 1,
//   },
//   callerContainer: {
//     // alignItems: "center",
//     paddingTop: 10,
//     height: "100%",
//     width: "100%",
//     justifyContent: 'center', 
//     alignItems: 'center',
//     display: 'flex',
//     flexDirection: 'column'
//   },
//   callerPhoto: {
//     backgroundColor: "white",
//   },
//   callerName: {
//     fontSize: 20,
//     color: "white",
//     paddingTop: 5,
//     fontWeight: "500",
//   },
//   buttonsContainer: {
//     flexDirection: "row",
//     justifyContent: "space-evenly",
//     paddingTop: 20,
//     width: "100%",
//     display: 'flex'
//   },
//   callButton: {},
//   cancelButton: {},
// };

// export const videoFeedsStyles = {
//   rtcContainer: {
//     height: "100%",
//     width: "100%",
//     alignItems: "center",
//     backgroundColor: "#333333",
//   },
//   rtcviewRemote: {
//     height: "50%",
//     width: "100%",
//     top: 0,
//   },
//   rtcviewLocal: {
//     height: "50%",
//     width: "100%",
//     bottom: 0,
//   },
//   rtcRemote: {
//     height: "100%",
//     width: "100%",
//   },
//   rtcLocal: {
//     height: "100%",
//     width: "100%",
//   },
// };

// export const callButtonsStyles = {
//   container: {
//     padding: 20,
//     paddingBottom: 40,
//     borderTopLeftRadius: 15,
//     borderTopRightRadius: 15,
//     flexDirection: "row",
//     justifyContent: "space-between",
//     marginTop: "auto",
//     alignItems: "center",
//   },
//   buttons: {
//     backgroundColor: "#4a4a4a",
//     padding: 15,
//     borderRadius: 50,
//   },
//   buttonText: {
//     color: "white",
//   },
// };

// export const toggleButtonStyles = {
//   container: {
//     padding: 20,
//     paddingBottom: 40,
//     borderTopLeftRadius: 15,
//     borderTopRightRadius: 15,
//     flexDirection: "row",
//     justifyContent: "space-between",
//     marginTop: "auto",
//     width: "100%",
//   },
//   buttons: {
//     backgroundColor: "#4a4a4a",
//     padding: 15,
//     borderRadius: 50,
//   },
// };
