import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { FaVenusMars, FaMarsDouble, FaVenusDouble, FaTransgenderAlt, FaGenderless, FaMarsStroke, FaGlassCheers } from 'react-icons/fa';
import { GiAquarius, GiAries, GiCancer, GiCapricorn, GiGemini, GiLeo, GiLibra, GiPisces, GiSagittarius, GiScorpio, GiTaurus, GiVirgo, GiFireworkRocket } from "react-icons/gi";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";
import { TbHeartHandshake } from "react-icons/tb";
import { MdWavingHand } from "react-icons/md";

const InfoModal = ({
  visible,
  changeVisible,
  renderdata,
  updateData,
  setUpdateData,
  t,
}) => {
  const classes = {
    dialogContent: {
      padding: '16px', //theme.spacing(2),
    },
    button: {
      backgroundColor: '#2176de',
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30,
      marginTop: '16px', //theme.spacing(2),
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(90, 154, 230, 0.8)',
      },
      width: '60%'
    },
    cancelButton: {
      backgroundColor: 'white',
      borderWidth: 1,
      borderRadius: 30,
      // marginTop: '16px', //theme.spacing(2),
      color: 'black',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      width: '60%'
    },
  };

  const renderIcon = (iconName, color) => {
    switch (iconName) {
      case 'FaVenusMars':
        return <FaVenusMars size={40} color={color} />;
      case 'FaMarsDouble':
        return <FaMarsDouble size={40} color={color} />;
      case 'FaVenusDouble':
        return <FaVenusDouble size={40} color={color} />;
      case 'FaTransgenderAlt':
        return <FaTransgenderAlt size={40} color={color} />;
      case 'FaGenderless':
        return <FaGenderless size={40} color={color} />;
      case 'FaMarsStroke':
        return <FaMarsStroke size={40} color={color} />;
      case 'GiAquarius':
        return <GiAquarius size={40} color={color} />;
      case 'GiAries':
        return <GiAries size={40} color={color} />;
      case 'GiCancer':
        return <GiCancer size={40} color={color} />;
      case 'GiCapricorn':
        return <GiCapricorn size={40} color={color} />;
      case 'GiGemini':
        return <GiGemini size={40} color={color} />;
      case 'GiLeo':
        return <GiLeo size={40} color={color} />;
      case 'GiLibra':
        return <GiLibra size={40} color={color} />;
      case 'GiPisces':
        return <GiPisces size={40} color={color} />;
      case 'GiSagittarius':
        return <GiSagittarius size={40} color={color} />;
      case 'GiScorpio':
        return <GiScorpio size={40} color={color} />;
      case 'GiTaurus':
        return <GiTaurus size={40} color={color} />;
      case 'GiVirgo':
        return <GiVirgo size={40} color={color} />;
      case 'IoMdMale':
        return <IoMdMale size={40} color={color} />;
      case 'IoMdFemale':
        return <IoMdFemale size={40} color={color} />;
      case 'IoMaleFemale':
        return <IoMaleFemale size={40} color={color} />;
      case 'TbHeartHandshake':
        return <TbHeartHandshake size={40} color={color} />;
      case 'FaGlassCheers':
        return <FaGlassCheers size={40} color={color} />;
      case 'MdWavingHand':
        return <MdWavingHand size={40} color={color} />;
      case 'GiFireworkRocket':
        return <GiFireworkRocket size={40} color={color} />;
      default:
        return null; // Handle unknown icons 
    }
  };

  return (
    <Dialog open={visible} onClose={() => changeVisible(false)} style={{flexDirection: 'column', justifyContent: 'center'}}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography variant="h5" fontWeight="bold">
          {t(renderdata?.title) /*renderdata?.title*/}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          {renderdata?.list.map((item, index) => {
            const color = updateData[renderdata?.dataType] === item?.value ? '#ff9933' : null;
            const IconComponent = item.name ? renderIcon(item.name, color) : null;
            return (
              <Paper
                key={item.value}
                elevation={updateData[renderdata?.dataType] === item?.value ? 2 : 0}
                style={{
                  width: renderdata?.list.length < 4 ? 100 : 120,
                  height: renderdata?.list.length < 4 ? 100 : 120,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 10,
                  margin: 10,
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                onClick={() => {
                  setUpdateData({
                    ...updateData,
                    [`${renderdata?.dataType}`]: item?.value,
                  });
                }}
              >
                {item?.name /*&& item?.type*/ ? (
                  // <Icon
                  //   name={item?.name}
                  //   type={item?.type}
                  //   size={30}
                  //   style={{
                  //     color:
                  //       updateData[renderdata?.dataType] === item?.value
                  //         ? '#ff9933'
                  //         : null,
                  //   }}
                  // >{'add_circle'}</Icon>
                  IconComponent
                  // <FaVenusMars />
                ) : null}
                {item?.uri ? (
                  <img
                    src={item?.uri}
                    alt={item?.value}
                    style={{
                      width: 51,
                      height: 51,
                      objectFit: 'contain',
                      backgroundColor: 'transparent',
                    }}
                  />
                ) : null}
                <Typography
                  sx={{
                    color:
                      updateData[renderdata?.dataType] === item?.value
                        ? '#ff9933'
                        : null,
                    textAlign: 'center',
                    pt: 2
                  }}
                >
                  {t(item?.value) /*item?.value*/}
                </Typography>
              </Paper>
            )}
          )}
        </div>
      </DialogContent>
      {updateData[renderdata?.dataType] ? (
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="text"
            style={classes.button}
            onClick={() => {
              setUpdateData({
                ...updateData,
                [`${renderdata?.dataType}`]: undefined,
              });
            }}
          >
            {t('CancelButton') /*'Cancel'*/}
          </Button>
        </DialogActions>
      ) : null}
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          style={classes.cancelButton}
          onClick={() => changeVisible(false)}
        >
          {t('DoneButton') /*'Done'*/}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
