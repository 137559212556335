import React, { useState, useContext } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import { 
  Paper, 
  Box, 
  Grid,
  BottomNavigation, 
  BottomNavigationAction, 
  useMediaQuery, 
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
} from '@mui/material';
import ChatScreen from '../screens/ChatScreen'
import MessageScreen from '../screens/MessageScreen';
import MatchInfoScreen from '../screens/MatchInfoScreen';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
// import AdbIcon from '@mui/icons-material/Adb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { AuthContext } from '../context/AuthProvider';
import { GiLightningBow } from "react-icons/gi";



  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  const pages = [
    // 'home', 'event', 'chat'
    'Home-TabTitle', 'Event-TabTitle', 'Chat-TabTitle'
  ];

function ChatRoute() {
  const [screen, setScreen] = useState('chat')
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('Chat-TabTitle');

  const handleNavBarChange = (newValue) => {
    setSelectedTab(newValue);
    if (newValue === 'Chat-TabTitle') {
      navigate('/chat');
    } else if (newValue === 'Event-TabTitle') {
      navigate('/event');
    } else {
      navigate('/home');
    }
    // navigate(`/${newValue}`);
  }

  const handleTabChange = (event, newValue) => {
    // console.log(event, newValue)
    setSelectedTab(newValue);
    if (newValue === 'Chat-TabTitle') {
      navigate('/chat')
    } else if (newValue === 'Event-TabTitle') {
      navigate('/event')
    } else {
      navigate('/home');
    }
    // navigate(`/${newValue}`);
    // setSelectedTab(event.currentTarget);
  };

  const renderGrid = () => {
    switch (screen) {
      case 'message':
        return <MessageScreen navigation={setScreen} />;
      case 'info':
        return <MatchInfoScreen navigation={setScreen} />;
      default:
        return <ChatScreen navigation={setScreen} />;
    }
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{display: { xs: 'none', md: 'flex' }}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <GiLightningBow style={{ fontSize: '30px', marginRight: 10, transform: 'rotate(270deg)' }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              // href="#app-bar-with-responsive-menu"
              onClick={() => navigate('/home')}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                cursor: 'pointer',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {'HUNTER'}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleNavBarChange(page)}
                  sx={{ 
                    my: 2, 
                    color: 'white', 
                    display: 'block', 
                    borderBottom: `2px solid ${selectedTab /*screen*/ === page ? 'white' : 'transparent'}`, 
                    borderRadius: 0,
                    // writingMode: 'horizontal-tb'
                  }}
                >
                  {t(page)}
                </Button>
              ))}
            </Box>

            {/* <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Profile settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
      <Grid container /*spacing={3}*/>
        <Grid item xs lg={3}>
          {/* <Item> */}
            {/* <ChatScreen navigation={setScreen} /> */}
            {isMobile ? renderGrid() : <ChatScreen navigation={setScreen} />}
          {/* </Item> */}
        </Grid>
        <Grid item lg={6}>
          {/* <Item> */}
          {!isMobile ? (
            <MessageScreen navigation={setScreen} />
            ) : null}
          {/* </Item> */}
        </Grid>
        <Grid item lg>
          {/* <Item> */}
          {!isMobile ? (
            <MatchInfoScreen navigation={setScreen}/>
            ) : null}
          {/* </Item> */}
        </Grid>
      </Grid>
      <Paper 
        sx={{ 
          position: 'fixed', 
          bottom: 0, 
          left: 0, 
          right: 0,
          justifyContent: 'center',
          // width: isMobile ? '100%' : '50%', // Adjust the width based on the screen size
          // alignSelf: isMobile ? 'center' : 'flex-end',
          display: { xs: screen === 'chat' ? '100%' : 'none', md: 'none' },
          zIndex: 1000,
        }} 
        elevation={3}>
        <BottomNavigation
          showLabels 
          value={selectedTab /*screen*/} 
          onChange={handleTabChange}
        >
          <BottomNavigationAction label={t('Home-TabTitle')} value={'Home-TabTitle'} icon={<HomeIcon />} />
          <BottomNavigationAction label={t('Event-TabTitle')} value={'Event-TabTitle'} icon={<MapIcon />} />
          <BottomNavigationAction label={t('Chat-TabTitle')} value={'Chat-TabTitle'} icon={<QuestionAnswerIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  )
}

export default ChatRoute