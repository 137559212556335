import React, { useContext, useState, useRef } from 'react';
import {
  Button,
  Container,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import AddImageModal from '../components/PhotoComponents/AddImageModal';
import DeleteImageModal from '../components/PhotoComponents/DeleteImageModal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import loginImage from '../assets/login2.jpg'
import { auth } from '../firebase';

const defaultTheme = createTheme();

const AddPhotoScreen = ({ navigation }) => {
  const { t, info, setInfo } = useContext(AuthContext);
  // const navigate = useNavigate();
  const index = useRef();
  const [deleteImageVisible, setDeleteImageVisible] = useState(false);
  const [addImageVisible, setAddImageVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const styles = {
    gridContainerStyle: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)', // Three columns of equal width
      gridTemplateRows: 'repeat(3, 1fr)', // Three rows of equal width
      gap: '5px', // Adjust the gap as needed
      justifyContent: 'center', // Center the grid horizontally
      width: '100%',
      height: '100%',
      padding: 10
    },
    gridItemStyle: {
      width: '100%',     // Set the width to 100% to fill the grid cell
      height: '100%',    // Set the height to 100% to fill the grid cell
      padding: 8,
      backgroundColor: 'transparent',
      border: 'none',
      overflow: 'hidden',
    },
  };
  const blurhash =
    '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

  const getNavigation = (data) => {
    if (!data.email) {
      // navigate('add_email');
      navigation('email');
      return;
    } else if (!data.phoneNumber) {
      // navigate('phone_number');
      navigation('phone');
      return;
    } else if (!data.displayName) {
      // navigate('add_name');
      navigation('name');
      return;
    } else if (!data.birthday) {
      // navigate('add_birthday');
      navigation('birthday');
      return;
    } else if (!data?.gender) {
      // navigate('add_sexual');
      navigation('sexual');
      return;
    } else if (!data.photoURL || data?.photoURL?.length === 0) {
      // navigate('add_photo');
      navigation('photo');
      return;
    } else {
      // navigate('add_location');
      navigation('location');
      return;
    }
  };

  const navigateToHome = async () => {
    const user = auth().currentUser;
    if (user) {
      await auth().signOut();
    }
    navigation('frontpage')
  };

  const handleButton = () => {
    setInfo({ ...info, photoURL: images });
    getNavigation(info);
  };

  const handleDeleteImageModal = (i) => {
    setDeleteImageVisible(true); 
    // setImageNum(index)
    index.current = i
  }

  const handleAddImageModal = () => {
    // if (images.length === 1) {
    //   alert(t('Profile-Alert1'));
    //   return;
    // }
    if (loading) {
      return;
    }
    setAddImageVisible(true);
  }

  return (
    <div 
      style={{ 
        flex: 1, 
        // padding: 20, https://source.unsplash.com/random?wallpapers
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <ThemeProvider theme={defaultTheme} >
        <Container
          maxWidth="xs"
          style={{
            padding: 20,
            flex: 1,
            // justifyContent: 'space-between',
            // alignItems: 'center',
            // paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
            backgroundColor: 'white',
            height: '100vh',
            // display: 'flex',
            // flexDirection: 'column',
            width: '100%',
          }}
        >
          <IconButton sx={{ position: 'relative' }} onClick={navigateToHome}>
            <CloseIcon />
          </IconButton>
          <Box 
            sx={{ 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '90vh', 
              display: 'flex', 
              flexDirection: 'column',
            }}>
            <Box 
              sx={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', display: 'flex'}}
            >
              <Typography variant="h4" style={{ fontSize: 30 }}>
                {t('AddPhotos-Title') /*'AddPhotos-Title'*/}
              </Typography>
              <Typography>{t('AddPhotos-Instruction') /*'AddPhotos-Instruction'*/}</Typography>
            </Box>
            <AddImageModal
              visible={addImageVisible}
              changeVisible={setAddImageVisible}
              data={images}
              updateData={setImages}
              updateProgress={setLoading}
              userId={info?.id}
              t={t}
              // i18n={i18n}
            />
            <DeleteImageModal
              visible={deleteImageVisible}
              changeVisible={setDeleteImageVisible}
              index={index.current}
              data={images}
              updateData={setImages}
              userId={info?.id}
              t={t}
              // i18n={i18n}
            />
            <div style={styles.gridContainerStyle}>
              {Array(9).fill().map((_, index) => {
                const source = index < images.length ? images[index] : undefined;
                const isImagePresent = source !== undefined;

                return (
                  <Button
                    key={index}
                    style={{
                      ...styles.gridItemStyle,
                      cursor: isImagePresent ? 'pointer' : 'default',
                    }}
                    disabled={loading}
                    onClick={() => {
                      if (isImagePresent) {
                        handleDeleteImageModal(index);
                      } else {
                        handleAddImageModal();
                      }
                    }}
                  >
                    {isImagePresent ? (
                      // <img
                      //   style={{ width: '100%', height: '100%', borderRadius: 10 }}
                      //   src={source.uri}
                      //   alt={`Image ${index}`}
                      // />
                      <div 
                        style={{ 
                          flex: 1, 
                          // padding: 20,
                          backgroundImage: `url(${source.uri})`,
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '150px',
                          borderRadius: 2,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'lightgray', // Placeholder background color
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 10
                        }}
                      >
                        {loading && index === images?.length ? (
                          <CircularProgress color="secondary"/>
                        ) : null}
                      </div>
                    )}
                  </Button>
                );
              })}
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 10 }}
              onClick={handleButton}
              disabled={images.length < 3 || loading}
            >
              {t('ContinueButton') /*'ContinueButton'*/}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default AddPhotoScreen;
