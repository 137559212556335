import { Typography } from '@mui/material'
import React from 'react'
import errorImage from '../assets/404.jpg'

function NotFoundScreen() {
  return (
    <div 
    style={{ 
      flex: 1, 
      // padding: 20, https://source.unsplash.com/random?wallpapers
      backgroundImage: `url(${errorImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: (t) =>
        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      height: '100vh'
    }}>
    </div>
  )
}

export default NotFoundScreen