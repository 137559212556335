import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { firestore } from '../../firebase';

const ReportModal = ({ visible, changeVisible, t, id, displayName, reportedId, reportedName }) => {
  const [message, setMessage] = useState('');
  const [content, changeContent] = useState(0);
  const [textInputVisible, setTextInputVisible] = useState(false);

  const behaviors = [
    {
      title: 'Inappropriate Messages',
      // iconName: 'chatbubbles',
      // iconType: 'ionicon',
      // iconColor: '#3399ff'
    },
    {
      title: 'Inappropriate Photos',
      // iconName: 'camera',
      // iconType: 'font-awesome',
      // iconColor: '#cc66ff'
    },
    {
      title: 'Bad Offline Behavior',
      // iconName: 'emoticon-angry',
      // iconType: 'material-community',
      // iconColor: '#ff6600'
    },
    {
      title: 'Feel Like Spam',
      // iconName: 'robot',
      // iconType: 'material-community',
      // iconColor: '#ffcc00'
    },
    {
      title: 'Underage User',
      // iconName: 'child',
      // iconType: 'font-awesome',
      // iconColor: '#ff0066'
    },
    {
      title: 'Other',
      // iconName: 'warning',
      // iconType: 'ionicon',
      // iconColor: '#33cc33'
    },
  ];

  const reportUser = () => {
    // Handle reporting logic here
    // You can use the `message`, `id`, `displayName`, `reportedId`, and `reportedName` in your logic
    // Once the report is handled, you can change the state and close the dialog
    firestore().collection('reports').add({
      id, displayName, reportedId, reportedName, message: message, type: 'Report User'
    })
    .then(() => {
      changeContent(0); 
      setTextInputVisible(false); 
      changeVisible(!visible)
    })
  };

  return (
    <div>
      <Dialog open={visible} onClose={() => changeVisible(false)}>
        {content === 0 && (
          <DialogTitle>{t('Report-Title') /*'Report User'*/}</DialogTitle>
        )}
        {content === 0 && (
          <DialogContent>
            <p>{`${t('Report-Intro1') /*'Do you want to report'*/} ${reportedName}`}</p>
          </DialogContent>
        )}
        {content === 0 && (
          <DialogActions>
            <Button onClick={() => changeContent(content + 1)} variant="contained">
              {t('YesButton') /*'Yes'*/}
            </Button>
            <Button onClick={() => changeVisible(false)} variant="outlined">
              {t('NoButton') /*'No'*/}
            </Button>
          </DialogActions>
        )}

        {content === 1 && (
          <DialogTitle>{t('Report-Intro2') /*'Tell us what they did'*/}</DialogTitle>
        )}
        {content === 1 && (
          <DialogContent style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {behaviors.map((behavior) => (
              <Button
                key={behavior.title}
                variant="outlined"
                style={{ margin: '8px', width: '100%' }}
                onClick={() => {
                  if (behavior.title === 'Other') {
                    setTextInputVisible(true);
                    setMessage(behavior.title);
                  } else {
                    setTextInputVisible(false);
                    setMessage(behavior.title);
                  }
                }}
                disabled={message && message?.includes(behavior.title)}
              >
                {/* <Icon
                  name={behavior.iconName}
                  type={behavior.iconType}
                  color={behavior.iconColor}
                /> */}
                {t(behavior.title) /*behavior.title*/}
              </Button>
            ))}
          </DialogContent>
        )}
        {content === 1 && (
          <DialogContent>
            {textInputVisible && (
              <TextField
                label={t('Report-PlaceHolder') /*'Please tell us here'*/}
                variant="outlined"
                autoFocus
                fullWidth
                onChange={(e) => setMessage(`Other, ${e.target.value}`)}
              />
            )}
          </DialogContent>
        )}
        {content === 1 && (
          <DialogActions>
            {message && (
              <Button
                onClick={reportUser}
                variant="contained"
                color="error"
              >
                {t('ReportButton') /*'Report'*/}
              </Button>
            )}
            <Button onClick={() => { changeVisible(false); changeContent(0) }} variant="outlined">
              {t('CancelButton') /*'Cancel'*/}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

export default ReportModal;
