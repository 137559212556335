import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { AuthContext } from '../../context/AuthProvider';
import { SelectAreaContext } from '../../context/SelectAreaProvider';
import { firestore } from '../../firebase';

const ExtendTimeModal = ({ visible, changeVisible, t }) => {
  const {
    user: { id, ticket, huntingTime }
  } = useContext(AuthContext);
  const { setSeenMessage } = useContext(SelectAreaContext);
  const classes = {
    dialogContent: {
      padding: '16px', //theme.spacing(2),
    },
    button: {
      backgroundColor: 'rgba(90, 154, 230, 1)',
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30,
      marginTop: '16px', //theme.spacing(2),
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(90, 154, 230, 0.8)',
      },
    },
    cancelButton: {
      backgroundColor: 'white',
      borderWidth: 1,
      borderRadius: 30,
      marginTop: '16px', //theme.spacing(2),
      color: 'black',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  };

  const handleUseTicket = async () => {
    try {
      const updatedHuntingTime =
        firestore.Timestamp.now().valueOf() > huntingTime.valueOf()
          ? firestore.Timestamp.fromMillis(firestore.Timestamp.now().toMillis() + 24 * 60 * 60 * 1000)
          : firestore.Timestamp.fromMillis(huntingTime.toMillis() + 24 * 60 * 60 * 1000);

      await firestore().doc(`users/${id}`).update({
        ticket: ticket - 1,
        huntingTime: updatedHuntingTime,
      });

      setSeenMessage(false);
      changeVisible(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={visible} onClose={() => changeVisible(false)}>
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          {t('Profile-TimeIntro') /*'Profile-TimeIntro'*/}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">{t('Profile-TimeIntro') /*'Profile-TimeIntro'*/}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleUseTicket}
        >
          {`${t('TimeLimitButton') /*'Time Limit'*/} (${ticket})`}
        </Button>
        <Button
          variant="contained"
          className={classes.cancelButton}
          onClick={() => changeVisible(false)}
        >
          {t('CancelButton') /*'Cancel'*/}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExtendTimeModal;
