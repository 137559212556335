import React, { useState, useEffect } from 'react';

const ImageList = ({ photolist, style, mode, content, changeContent }) => {
  const [numPic, setNumPic] = useState(0);
  
  useEffect(() => {
    setNumPic(0);
  }, [photolist])

  const handlePrevClick = () => {
    setNumPic((prevNumPic) => (prevNumPic > 0 ? prevNumPic - 1 : prevNumPic));
    if (changeContent) {
      changeContent(!content);
    }
  };

  const handleNextClick = () => {
    setNumPic((prevNumPic) =>
      prevNumPic < photolist.length - 1 ? prevNumPic + 1 : prevNumPic
    );
    if (changeContent) {
      changeContent(!content);
    }
  };

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={styles.pagination}>
        {photolist
          ? photolist.map((photo, index) => (
              <div
                key={photo?.uri}
                style={{
                  ...styles.dot,
                  backgroundColor: index === numPic ? 'black' : 'white',
                }}
              />
            ))
          : null}
        <div style={styles.dotIndicator} />
      </div>
      {photolist
        ? photolist.map((photo, index) => (
            <img
              key={photo.uri}
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                top: 0,
                height: '100%',
                width: '100%',
                zIndex: index === numPic ? 1 : 0,
                ...style,
              }}
              src={photo.uri}
              alt={photo.uri}
            />
          )).reverse()
        : null}
      <div
        onClick={handlePrevClick}
        style={mode === 'swiper' ? styles.backwardImage : styles.forwardImage}
      />
      <div
        onClick={handleNextClick}
        style={mode === 'swiper' ? styles.forwardImage : styles.backwardImage}
      />
    </div>
  );
};

const DOT_SIZE = 8;
const DOT_SPACING = 8;
// const DOT_INDICATOR_SIZE = DOT_SIZE + DOT_SPACING;

export default ImageList;

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  pagination: {
    position: 'absolute',
    alignSelf: 'center',
    top: 10,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
  },
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE,
    marginLeft: DOT_SPACING,
  },
  backwardImage: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    height: '100%',
    borderRadius: 10,
    width: '50%',
    cursor: 'pointer',
  },
  forwardImage: {
    position: 'absolute',
    zIndex: 2,
    right: 0,
    height: '100%',
    borderRadius: 10,
    width: '50%',
    cursor: 'pointer',
  },
};
