import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const VerifyModal = ({ visible, changeVisible, id, t }) => {
  const navigate = useNavigate();
  const classes = {
    dialogContent: {
      padding: '16px', //theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    button: {
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30,
      marginTop: '16px', //theme.spacing(2),
      backgroundColor: '#007bff', //theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: '#007bff', //theme.palette.primary.dark,
      },
    },
    cancelbutton: {
      backgroundColor: 'white',
      borderWidth: 1,
      borderRadius: 30,
      marginTop: '16px', //theme.spacing(2),
    },
  };
  const [content, switchContent] = useState(true);

  const getVerifiedText = t('Profile-GetVerifiedText');
  const howItWorksText = t('Profile-HowItWorksText');

  const startVerifying = async () => {
    switchContent(true);
    changeVisible(!visible);
    // Use your logic to update the Firestore document
    // await firestore().doc(`users/${id}`).update({ verifiedStatus: "verifying" })
		navigate('/verify');
  };

  return (
    <Dialog open={visible} onClose={() => changeVisible(false)}>
      <DialogTitle>
        <CheckCircleIcon fontSize="large" color="primary" />
        <Typography variant="h5" fontWeight="bold">
          {content ? /*'Profile-GetVerified'*/ t('Profile-GetVerified') : /*'Profile-HowItWorks'*/ t('Profile-HowItWorks')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" fontSize="15" paragraph>
          {content ? getVerifiedText : howItWorksText}
        </Typography>
        <Button
          variant="contained"
          sx={classes.button}
          onClick={() => {
            if (content) {
              switchContent(!content);
            } else {
              startVerifying();
            }
          }}
        >
          {content ? /*'ContinueButton'*/ t('ContinueButton') : /*'VerifyMeButton'*/ t('VerifyMeButton')}
        </Button>
        <Button
          variant="outlined"
          sx={classes.cancelbutton}
          onClick={() => {
            switchContent(true);
            changeVisible(!visible);
          }}
        >
          {t('MaybeLaterButton') /*'MaybeLaterButton'*/}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyModal;
