import React, { useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Header from '../components/FrontPageComponents/Header';
import MainFeaturedPost from '../components/FrontPageComponents/MainFeaturedPost';
import Footer from '../components/FrontPageComponents/Footer';
import FeaturesOne from '../components/FrontPageComponents/FeaturesOne';
import Pricing from '../components/FrontPageComponents/Pricing';
import GetStarted from '../components/FrontPageComponents/GetStarted';
import { AuthContext } from '../context/AuthProvider';
import questionImage2 from '../assets/question2.jpg'
import mainPostImage3 from '../assets/mainpost3.jpg'
import Link from '@mui/material/Link';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const FrontPageScreen = ({ navigation }) => {
  const { t, loginRef } = useContext(AuthContext);
  const sections = [
    // 'home', 'products', 'pricing', 'policy'
    // 'Frontpage-TabTitle', 'Products-TabTitle', 'Pricing-TabTitle', 'Policy-TabTitle'
    { title: 'Frontpage-TabTitle', url: ''},
    { title: 'Products-TabTitle', url: 'products'},
    { title: 'Pricing-TabTitle', url: 'pricing'},
    { title: 'Policy-TabTitle', url: 'policy'},
  ];
  
  const footers = [
    {
      title: t('Frontpage-TabTitle'),
      description: [
        //'Team', 'History', 'Contact us', 'Locations'
        { name: t('Frontpage-Intro2Title'), page: '/'}, 
        { name: t('Frontpage-Intro3Title'), page: '/'},
        { name: t('Frontpage-Intro4Title'), page: '/'},
      ],
    },
    {
      title: t('Products-TabTitle'),
      description: [
        // 'Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one',
        { name: t('Products-Intro1Title'), page: '/products'}, 
        { name: t('Products-Intro2Title'), page: '/products'},
        { name: t('Products-Intro3Title'), page: '/products'},
      ],
    },
    {
      title: t('Pricing-TabTitle'),
      description: [
        // 'Resource', 'Resource name', 'Another resource', 'Final resource'
        { name: t('Pricing-Intro1Title'), page: '/pricing'}, 
        { name: t('Pricing-Intro2Title'), page: '/pricing'},
      ],
    },
    {
      title: t('Policy-TabTitle'),
      description: [
        // 'Privacy policy', 'Terms of use'
        { name: t('Policy-Intro1Title'), page: '/termandconditions'}, 
        { name: t('Policy-Intro2Title'), page: '/privacypolicy'},
      ],
    },
    {
      title: t('Language'),
      description: [
        // 'English', 'Spain'
        { name: 'English', code: 'en'}, 
        { name: 'Spainish', code: 'es'},
        { name: 'French', code: 'fr'},
        { name: 'German', code: 'de'},
        { name: 'Chinese', code: 'zh'},
        { name: 'Vietnamese', code: 'vi'},
        { name: 'Italian', code: 'it'},
        { name: 'Japanese', code: 'ja'},
        { name: 'Korean', code: 'ko'},
        // { name: 'Arabic', code: 'ar'},
        // { name: 'Bengali', code: 'bn'},
        { name: 'Czech', code: 'cs'},
        // { name: 'Greek', code: 'el'},
        { name: 'Finnish', code: 'fi'},
        // { name: 'Hindi', code: 'hi'},
        { name: 'Hungarian', code: 'hu'},
        { name: 'Dutch', code: 'nl'},
        { name: 'Norwegian', code: 'no'},
        { name: 'Polish', code: 'pl'},
        { name: 'Portuguese', code: 'pt'},
        // { name: 'Roumanian', code: 'ro'},
        // { name: 'Russian', code: 'ru'},
        { name: 'Swedish', code: 'sv'},
        // { name: 'Turkish', code: 'tr'},
        // { name: 'Urdu', code: 'ur'},
      ],
    },
    {
      title: t('Country'),
      description: ['US', 'GB', 'JP', 'KR', 'AU', 'DE', 'FR', 'ES', 'VN', 'IT', 'CA', 'CN'],
    },
  ];
  
  const mainFeaturedPost = {
    title: t('Frontpage-Intro1Title'),
    description: t('Frontpage-Intro1'),
    image: /*'https://source.unsplash.com/random?wallpapers'*/ mainPostImage3,
    imageText: 'main image description',
    // linkText: 'Continue reading…',
    containedButton: t('CreateAccountButton'),  
    outlinedButton: t('LogInButton')
  };
  
  
  
  const featuresOne = {
    title: t('Frontpage-Intro2Title'),
    content: t('Frontpage-Intro2'),
    mode: 'row',
  };
  
  const navigate = useNavigate();
  const handleFeatureButton = () => {
    navigate('/products')
  };
  const handlePricingButton = () => {
    navigate('/pricing')
  };
  const handleLogin = () => {
    navigation('login')
  };
  const handleCreateAccount = () => {
    loginRef.current = false;
    navigation('login');
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div>
        <Header 
          title="Hunter" 
          sections={sections}
          currentSection={'Frontpage-TabTitle'}
          onButtonClick={handleLogin}
        />
        <main>
          <MainFeaturedPost 
            post={mainFeaturedPost} 
            titleStyle={{ mb: { xs: 30, md: 5}, textAlign: {xs: 'center', md: 'start'}}}
            descriptionStyle={{ display: { xs: 'none', md: 'flex'} }}
            onContainedButtonClick={handleCreateAccount}
            onOutlinedButtonClick={handleLogin}
          />
          <Grid container spacing={4}>
            {/* {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))} */}
            {/* <FeaturesOne /> */}
            <FeaturesOne 
              title={featuresOne.title}
              content={featuresOne.content}
              mode={featuresOne.mode}
              image={questionImage2}
              onClick={handleFeatureButton}
              link={'why-hunter'}
            />
            <Pricing 
              onButtonClick={handlePricingButton}
              link={'pricing'}
            />
            <GetStarted 
              onButtonClick={handleLogin}
              link={'getting-start'}
            />
          </Grid>
        </main>
      </div>
      <Typography
        component="footer"
        sx={{ display: 'flex', bgcolor: 'whitesmoke' }}
      >
        <Container
          maxWidth="lg"
          component="footer"
          sx={{
            mt: 8,
            py: [3, 6]
          }}
        >
          <Grid 
            container 
            spacing={5} 
          >
            {footers.map((footer) => (
              <Footer
                title={footer.title}
                description={footer.description}
              />
            ))}
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Typography>
    </ThemeProvider>
  )
}

export default FrontPageScreen;