import React, { useContext } from 'react'
import { Button, styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { AuthContext } from '../../context/AuthProvider';

function GetStarted(props) {
// : "Get Started",
// : "Let's get started using Hunter",
// : "Get Started Now",
  const { t } = useContext(AuthContext);
  const { onButtonClick, link } = props;
  const CustomContainer = styled(Container)(({ theme }) => ({
    backgroundColor: "#17275F",
    height: "416px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3, 3, 0, 3),
      width: "90%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10, 0, 10, 0),
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  }));

  // const CustomButton = ({
  //   backgroundColor,
  //   color,
  //   buttonText,
  //   heroBtn,
  //   guideBtn,
  //   getStartedBtn,
  // }) => {
    const CustomButton = styled(Button)(({ theme }) => ({
      backgroundColor: "#fff",
      color: "#17275F",
      fontWeight: "700",
      fontSize: "14px",
      cursor: "pointer",
      padding: "0.5rem 1.25rem",
      borderRadius: "7px",
      textTransform: "none",
      display: "block",
      border: "2px solid transparent",
      "&:hover": {
        backgroundColor: "#17275F",
        color: "#fff",
        borderColor: "#fff",
      },
      [theme.breakpoints.down("md")]: {
        margin: /*(heroBtn || getStartedBtn) &&*/ theme.spacing(0, "auto", 3, "auto"),
        width: /*(heroBtn || getStartedBtn) &&*/ "90%",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: /*guideBtn &&*/ theme.spacing(3),
        width: /*guideBtn &&*/ "90%",
      },
    }));
  
  //   return <CustomButton>{buttonText}</CustomButton>;
  // };

  return (
    <CustomBox sx={{ width: '100%'}}>
      <div id={link}>
        <CustomContainer>
          <Box>
            <Typography
              sx={{ fontSize: "35px", color: "white", fontWeight: "700" }}
            >
              {t("Frontpage-Intro4Title")}
            </Typography>
            <Typography
              sx={{ fontSize: "16px", color: "#ccc", fontWeight: "500", my: 3 }}
            >
              {t("Frontpage-Intro4")}
            </Typography>

            {/* <CustomButton
              backgroundColor="#fff"
              color="#17275F"
              buttonText="Get Started Now"
              getStartedBtn={true}
            /> */}
            <CustomButton
              onClick={onButtonClick}
            >
              {t("GetStartedButton")}
            </CustomButton>
          </Box>

          <PhoneIphoneIcon sx={{ fontSize: '300px', color: 'white' }} />

          {/* <img
            src={homeIllustration}
            alt="illustration"
            style={{ maxWidth: "100%" }}
          /> */}
        </CustomContainer>
      </div>
    </CustomBox>
  )
}

export default GetStarted