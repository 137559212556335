import React, { useContext } from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/FrontPageComponents/Header';
import MainFeaturedPost from '../components/FrontPageComponents/MainFeaturedPost';
import Footer from '../components/FrontPageComponents/Footer';
import FeaturesOne from '../components/FrontPageComponents/FeaturesOne';
import { AuthContext } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import policyImage from '../assets/policy.jpg'
import termImage from '../assets/tems.jpg'
import privacyImage from '../assets/privacy.jpg'

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


function PolicyScreen() {
  const { setScreen, t } = useContext(AuthContext);
  const sections = [
    // 'home', 'products', 'pricing', 'policy'
    // 'Frontpage-TabTitle', 'Products-TabTitle', 'Pricing-TabTitle', 'Policy-TabTitle'
    { title: 'Frontpage-TabTitle', url: ''},
    { title: 'Products-TabTitle', url: 'products'},
    { title: 'Pricing-TabTitle', url: 'pricing'},
    { title: 'Policy-TabTitle', url: 'policy'},
  ];
  
  const mainFeaturedPost = {
    title: t('Policy-TabTitle').charAt(0).toUpperCase() + t('Policy-TabTitle').slice(1),
    // description: "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
    image: policyImage,
    imageText: 'main image description',
    // linkText: 'Continue reading…',
  };
  
  const featuredPosts = [
    {
      title: t('Policy-Intro1Title'),  
      content: t('Policy-Intro1'),
      image: termImage,
      mode: 'row',
      link: 'termandconditions'
    },
    {
      title: t('Policy-Intro2Title'),
      content: t('Policy-Intro2'),
      image: privacyImage,
      mode: 'row-reverse',
      link: 'privacypolicy'
    },
  ];
  
  
  const footers = [
    {
      title: t('Frontpage-TabTitle'),
      description: [
        //'Team', 'History', 'Contact us', 'Locations'
        { name: t('Frontpage-Intro2Title'), page: '/'}, 
        { name: t('Frontpage-Intro3Title'), page: '/'},
        { name: t('Frontpage-Intro4Title'), page: '/'},
      ],
    },
    {
      title: t('Products-TabTitle'),
      description: [
        // 'Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one',
        { name: t('Products-Intro1Title'), page: '/products'}, 
        { name: t('Products-Intro2Title'), page: '/products'},
        { name: t('Products-Intro3Title'), page: '/products'},
      ],
    },
    {
      title: t('Pricing-TabTitle'),
      description: [
        // 'Resource', 'Resource name', 'Another resource', 'Final resource'
        { name: t('Pricing-Intro1Title'), page: '/pricing'}, 
        { name: t('Pricing-Intro2Title'), page: '/pricing'},
      ],
    },
    {
      title: t('Policy-TabTitle'),
      description: [
        // 'Privacy policy', 'Terms of use'
        { name: t('Policy-Intro1Title'), page: '/termandconditions'}, 
        { name: t('Policy-Intro2Title'), page: '/privacypolicy'},
      ],
    },
    {
      title: t('Language'),
      description: [
        // 'English', 'Spain'
        { name: 'English', code: 'en'}, 
        { name: 'Spainish', code: 'es'},
        { name: 'French', code: 'fr'},
        { name: 'German', code: 'de'},
        { name: 'Chinese', code: 'zh'},
        { name: 'Vietnamese', code: 'vi'},
        { name: 'Italian', code: 'it'},
        { name: 'Japanese', code: 'ja'},
        { name: 'Korean', code: 'ko'},
        // { name: 'Arabic', code: 'ar'},
        // { name: 'Bengali', code: 'bn'},
        { name: 'Czech', code: 'cs'},
        // { name: 'Greek', code: 'el'},
        { name: 'Finnish', code: 'fi'},
        // { name: 'Hindi', code: 'hi'},
        { name: 'Hungarian', code: 'hu'},
        { name: 'Dutch', code: 'nl'},
        { name: 'Norwegian', code: 'no'},
        { name: 'Polish', code: 'pl'},
        { name: 'Portuguese', code: 'pt'},
        // { name: 'Roumanian', code: 'ro'},
        // { name: 'Russian', code: 'ru'},
        { name: 'Swedish', code: 'sv'},
        // { name: 'Turkish', code: 'tr'},
        // { name: 'Urdu', code: 'ur'},
      ],
    },
    {
      title: t('Country'),
      description: ['US', 'GB', 'JP', 'KR', 'AU', 'DE', 'FR', 'ES', 'VN', 'IT', 'CA', 'CN'],
    },
  ];

  const navigate = useNavigate();
  const handleLogin = () => {
    setScreen('login');
    navigate('/')
  };

  const handleFeatureButton = (link) => {
    navigate(`/${link}`);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {/* <Container maxWidth="xl"> */}
      <div>
        <Header 
          title="Hunter" 
          sections={sections}
          currentSection={'Policy-TabTitle'}
          onButtonClick={handleLogin}
        />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            <Container maxWidth="lg"> 
              {featuredPosts.map((post) => (
                <FeaturesOne 
                  key={post.title} 
                  title={post.title} 
                  image={post.image}
                  content={post.content} 
                  mode={post.mode}
                  onClick={() => handleFeatureButton(post.link)}
                />
              ))}
            </Container>
          </Grid>
        </main>
      </div>
      <Typography
        component="footer"
        sx={{ display: 'flex', bgcolor: 'whitesmoke' }}
      >
        <Container
          maxWidth="lg"
          component="footer"
          sx={{
            // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
          }}
        >
          <Grid container spacing={5}>
            {footers.map((footer) => (
              <Footer
                title={footer.title}
                description={footer.description}
              />
            ))}
          </Grid>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Typography>
    </ThemeProvider>
  )
}

export default PolicyScreen