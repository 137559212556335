import React, { useMemo, useState, useEffect, useRef } from 'react'
import { filterId} from '../lib/filters';

function useLikeFilter(isLiked, /*selectedUsersId, ageRange, showGender, verifiedStatus, onlySeeVerified, selectedArea, sortInterests, sortPersonality, interests, personality, isProMember,*/ swipeUsers ) {
    const [isLikedProfiles, setIsLikedProfiles] = useState([]);
    const [likedIndex, setLikedIndex] = useState();
    // used for outOfFrame closure
    const likedIndexRef = useRef(likedIndex)

    useEffect(() => {
        const hanndleFilter = () => {
          let swiped = Array.isArray(swipeUsers) ? swipeUsers.map(user => user.id) : []
          let filterMembers;
          filterMembers = filterId(isLiked, swiped);
        //   filterMembers = filterGender(filterMembers, showGender);
        //   filterMembers = filterAgeRange(filterMembers, ageRange);
        //   if (selectedArea?.type === 'polygon' || !isProMember) {
        //     filterMembers = filterOnlySeeVerified(filterMembers, onlySeeVerified);
        //     filterMembers = filterVerifiedSeeOnly(filterMembers, verifiedStatus);
        //     if (sortInterests) {
        //       filterMembers = sortByInterests(filterMembers, interests)
        //     }
        //     if (sortPersonality) {
        //       filterMembers = sortByPersonality(filterMembers, personality)
        //     }
        //   }
        //   filterMembers = sortByAttractiveness(filterMembers);
          if (Array.isArray(filterMembers) && filterMembers.length < 10) {
            filterMembers = [...filterMembers, ...Array(10 - filterMembers.length).fill({})]
          }
          filterMembers = Array.isArray(filterMembers) ? filterMembers.slice(0, 10).reverse() : [];
          setIsLikedProfiles(filterMembers);
          setLikedIndex(filterMembers.length - 1)
          // console.log(filterMembers);
        }
    
        hanndleFilter();
    
      }, [
        isLiked/*, ageRange, showGender, verifiedStatus, onlySeeVerified, selectedArea, sortInterests, sortPersonality, interests, personality*/, swipeUsers
      ])

      const likedChildRefs = useMemo(
        () =>
          Array(isLikedProfiles.length)
            .fill(0)
            .map((i) => React.createRef()),
        [isLikedProfiles]
      )
  return { isLikedProfiles, likedIndex, setLikedIndex, likedChildRefs, likedIndexRef }
}

export default useLikeFilter